import { ReactElement } from 'react';
import { TableDef } from '../Table';
import styles from '../Table.module.css';

interface TableLargeProps {
    tableDef: TableDef;
}

const TableLarge = ({ tableDef }: TableLargeProps): ReactElement => (
    <div className={styles['table-container']}>
        <table className={styles['table-lg']}>
            <thead>
                <tr className={styles['header-row-lg']}>
                    {tableDef.headers.map((header) => {
                        const headerKey = `header-${header.id}-key`;
                        return (
                            <th
                                id={header.id}
                                scope="col"
                                className={styles['header-lg']}
                                key={headerKey}
                            >
                                {header.name}
                            </th>
                        );
                    })}
                </tr>
            </thead>
            <tbody className={styles['tbody-lg']}>
                {tableDef.rows.map((row, rIndex) => {
                    const rowKey = `row-${rIndex}-key`;
                    return (
                        <tr className={styles['row-lg']} key={rowKey}>
                            {row.cells.map((cell, cIndex) => {
                                const cellKey = `row-${rIndex}-cell-${cIndex}-key`;
                                return (
                                    <td
                                        headers={cell.headerId}
                                        className={styles['cell-lg']}
                                        key={cellKey}
                                    >
                                        {cell.content}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    </div>
);
export default TableLarge;

import {
    ReactElement,
    ReactNode, useCallback, useEffect, useRef, useState
} from 'react';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapsible from '../../../common-components/Collapsible';
import Code from '../../../common-components/Code';
import {
    asyncCode, disabledCode, internallyControlledCode, parentControlledCode
} from './collapsibleDetails';
import Switch from '../../../common-components/Switch';
import Button from '../../../common-components/Button';
import { useMountEffect } from '../../../common-components/commonHooks';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';

const CollapsibleUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [EXAMPLE HELPERS]
    // --------------------------------------------------

    const getCollapsibleContent = (): ReactNode => (
        <>
            <p>Content can be anything in the form of a React child node.</p>
            <img src="/galaxy.jpg" alt="Galaxy" />
            <p>...and the Collapsible will expand to show all of the content.</p>
        </>
    );

    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getInternallyControlledSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('internally-controlled');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Internally Controlled'),
            builder.getComponentSectionDescription((
                <p>
                    The Collapsible is controlled internally by the component.
                </p>
            )),
            builder.getExampleSection([
                <Collapsible
                    collapsibleId="internally-controlled-example"
                    title="Internally Controlled"
                    toggleLabel="Internally Controlled"
                >
                    {getCollapsibleContent()}
                </Collapsible>
            ]),
            builder.getCodeSection(internallyControlledCode)
        ], true);
    };

    const [isParentControlled, setIsParentControlled] = useState(true);
    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

    const getParentControlledSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('parent-controlled');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Parent Controlled'),
            builder.getComponentSectionDescription((
                <p>
                    Parent controls the Collapsible. In this case, the Collapsible
                    toggle button is disabled and the component may only be controlled by
                    the parent. Notice that the <Code>isOpen</Code> prop is only used when
                    being parent controlled.
                </p>
            )),
            builder.getExampleTool([
                <Switch
                    switchTitle="Parent Controlled"
                    ariaLabeledBy="parent-controlled-label"
                    ariaControls="parent-controlled-example"
                    isDefaultToggled
                    onToggledOn={() => setIsParentControlled(true)}
                    onToggledOff={() => setIsParentControlled(false)}
                />,
                <Code id="parent-controlled-label">isParentControlled</Code>
            ], 'parent-switch'),
            builder.getExampleTool([
                <Switch
                    switchTitle="Is Open"
                    ariaLabeledBy="is-open-label"
                    ariaControls="parent-controlled-example"
                    onToggledOn={() => setIsCollapsibleOpen(true)}
                    onToggledOff={() => setIsCollapsibleOpen(false)}
                />,
                <Code id="is-open-label">isOpen</Code>
            ], 'open-switch'),
            builder.getExampleSection([
                <Collapsible
                    collapsibleId="parent-controlled-example"
                    title={`${isParentControlled ? 'Parent' : 'Internally'} Controlled`}
                    toggleLabel={`${isParentControlled ? 'Parent' : 'Internally'} Controlled`}
                    isParentControlled={isParentControlled}
                    isOpen={isCollapsibleOpen}
                >
                    {getCollapsibleContent()}
                </Collapsible>
            ]),
            builder.getCodeSection(parentControlledCode)
        ]);
    };

    const getDisabledSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('disabled');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Disabled'),
            builder.getComponentSectionDescription((
                <p>
                    Disabled toggle button for the Collapsible. It may still be opened
                    by <Code>isDefaultOpen</Code> or by <Code>isParentControlled</Code>.
                </p>
            )),
            builder.getExampleSection([
                <Collapsible
                    collapsibleId="disabled-example"
                    title="Disabled Example"
                    toggleLabel={<span><FontAwesomeIcon icon={faBan} /> Disabled Example</span>}
                    isDisabled
                >
                    Nothing to see here!
                </Collapsible>
            ]),
            builder.getCodeSection(disabledCode)
        ]);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const timeoutRef = useRef<number|null>(null);

    const getImage = (): ReactElement|null => {
        if (isLoaded) {
            return <img src="/galaxy.jpg" alt="Galaxy" />;
        }
        return null;
    };

    const cleanupTimeout = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
    }, []);

    useMountEffect(() => () => { cleanupTimeout(); });

    const handleReset = (): void => {
        cleanupTimeout();
        setIsLoading(false);
        setIsLoaded(false);
    };

    useEffect(() => {
        if (isLoading) {
            cleanupTimeout();
            timeoutRef.current = window.setTimeout(() => {
                setIsLoading(false);
                setIsLoaded(true);
            }, 5000);
        }
    }, [isLoading, cleanupTimeout]);

    const getAsyncContentSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('async-content');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Async Content'),
            builder.getComponentSectionDescription((
                <p>
                    The Collapsible should be able to handle async content.
                    The <Code>scrollHeight</Code> should be recalculated when
                    the <Code>children</Code> changes. If there are any issues, suggest
                    waiting or preventing expansion of the Collapsible until fully loaded.
                </p>
            )),
            builder.getExampleTool([
                <Button
                    isLoading={isLoading}
                    onClick={() => setIsLoading(true)}
                    isDisabled={isLoaded}
                >
                    Start Loading
                </Button>,
                <Button
                    variant="minimal"
                    onClick={handleReset}
                    isDisabled={!(isLoading || isLoaded)}
                >
                    Reset
                </Button>
            ], 'async-buttons'),
            builder.getExampleSection([
                <Collapsible
                    collapsibleId="async-example"
                    title="Not Fully Loaded"
                    toggleLabel="Not Fully Loaded"
                    isParentControlled
                    isOpen={isLoading || isLoaded}
                >
                    <>
                        <div key="test1">Image is loaded under here</div>
                        {getImage()}
                        <div key="test2">Image is loaded before here</div>
                    </>
                </Collapsible>,
                <Collapsible
                    collapsibleId="async-loaded-example"
                    title="Fully Loaded"
                    toggleLabel="Fully Loaded"
                    isParentControlled
                    isOpen={isLoaded}
                    isStacked
                >
                    <>
                        <div key="test3">Image is loaded under here</div>
                        {getImage()}
                        <div key="test4">Image is loaded before here</div>
                    </>
                </Collapsible>
            ]),
            builder.getCodeSection(asyncCode)
        ]);
    };

    return (
        <>
            {getInternallyControlledSection()}
            {getParentControlledSection()}
            {getDisabledSection()}
            {getAsyncContentSection()}
        </>
    );
};
export default CollapsibleUsageExamples;

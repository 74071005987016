import { memo, ReactElement } from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import { blockCode, inlineCode } from './codeDetails';
import Code from '../../../common-components/Code';

const CodeUsageExamples = (): ReactElement => {
    const blockExampleCode = `<div>
    <span>Some awesome code goes here!</span>
    <button type="button">OK!</button>
</div>
`;

    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const inlineSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('inline');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Inline'),
            builder.getComponentSectionDescription((
                <p>
                    Displays highlighted code inline.
                </p>
            )),
            builder.getExampleSection([
                <>
                    The <Code>isCodeBlock</Code> prop controls whether the code is inline or not.
                    Don&apos;t forget to escape special characters when using
                    the <Code>&lt;Code&gt;</Code> component.
                </>
            ]),
            builder.getCodeSection(inlineCode)
        ], true);
    };

    const blockSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('block');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Block'),
            builder.getComponentSectionDescription((
                <p>
                    Displays a highlighted code block. One way to avoid needing to escape
                    characters in the content is to pass it in as a template string.
                </p>
            )),
            builder.getExampleSection([
                <Code isCodeBlock>{blockExampleCode}</Code>
            ]),
            builder.getCodeSection(blockCode)
        ]);
    };

    return (
        <>
            {inlineSection()}
            {blockSection()}
        </>
    );
};
export default memo(CodeUsageExamples);

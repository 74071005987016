import { memo, ReactElement } from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import {
    tableCode, tableDefCode, tableHeaderDescription, tablePropDetails,
    tableDefDetails, tableHeaderDetails, tableCellDetails, tableRowDetails
} from './tableDetails';
import ContentHeader from '../../../common-components/ContentHeader';
import Tabs, { Tab } from '../../../common-components/Tabs';
import ComponentPropsTable from '../ComponentPropsTable';
import Table, { TableDef } from '../../../common-components/Table';
import Badge from '../../../common-components/Badge';
import Code from '../../../common-components/Code';

const TableUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const tableDef: TableDef = {
        headers: [
            {
                id: 'make',
                name: 'Make'
            },
            {
                id: 'model',
                name: 'Model'
            },
            {
                id: 'year',
                name: 'Year'
            },
            {
                id: 'mileage',
                name: 'Mileage'
            },
            {
                id: 'price',
                name: 'Price'
            }
        ],
        rows: [
            {
                cells: [
                    {
                        headerId: 'make',
                        content: 'Honda'
                    },
                    {
                        headerId: 'model',
                        content: 'Accord'
                    },
                    {
                        headerId: 'year',
                        content: '2009'
                    },
                    {
                        headerId: 'mileage',
                        content: '100,000'
                    },
                    {
                        headerId: 'price',
                        content: (
                            <>
                                <Badge color="neutral">Used</Badge> $8,000
                            </>
                        )
                    }
                ]
            },
            {
                cells: [
                    {
                        headerId: 'make',
                        content: 'Toyota'
                    },
                    {
                        headerId: 'model',
                        content: 'Camry'
                    },
                    {
                        headerId: 'year',
                        content: '2012'
                    },
                    {
                        headerId: 'mileage',
                        content: '75,000'
                    },
                    {
                        headerId: 'price',
                        content: (
                            <>
                                <Badge color="neutral">Used</Badge> $15,000
                            </>
                        )
                    }
                ]
            },
            {
                cells: [
                    {
                        headerId: 'make',
                        content: 'Ford'
                    },
                    {
                        headerId: 'model',
                        content: 'Fusion'
                    },
                    {
                        headerId: 'year',
                        content: '2022'
                    },
                    {
                        headerId: 'mileage',
                        content: '5,000'
                    },
                    {
                        headerId: 'price',
                        content: '$25,000'
                    }
                ]
            },
            {
                cells: [
                    {
                        headerId: 'make',
                        content: 'Hyundai'
                    },
                    {
                        headerId: 'model',
                        content: 'Elantra'
                    },
                    {
                        headerId: 'year',
                        content: '2020'
                    },
                    {
                        headerId: 'mileage',
                        content: '10,000'
                    },
                    {
                        headerId: 'price',
                        content: '$19,000'
                    }
                ]
            }
        ]
    };

    const getExampleSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('example');
        return builder.getComponentSection([
            builder.getComponentSectionDescription((
                <p>
                    The Table component is a very basic table with only a single row of headers and
                    rows of data. Footers are not implemented at this time. Try changing browser
                    width and notice how this example changes. Thresholds are at 768px for medium
                    table and 1024px for large table. Any size below 768px is the small table.
                </p>
            )),
            builder.getExampleSection([
                <Table tableDef={tableDef} />
            ]),
            builder.getCodeSection(tableCode),
            builder.getCollapsibleCodeSection(
                tableDefCode,
                'tableDef Definition',
                <span><Code>tableDef</Code> Definition</span>
            )
        ], true);
    };

    return (
        <>
            <ContentHeader
                headerText="Table"
                breadcrumb="Components"
                description={tableHeaderDescription}
            />
            <div className="content-clamp">
                <Tabs>
                    <Tab name="Example">
                        {getExampleSection()}
                    </Tab>
                    <Tab name="Props">
                        <ComponentPropsTable componentProps={tablePropDetails} />
                    </Tab>
                    <Tab name="TableDef">
                        <p>
                            The <Code>TableDef</Code> interface which is defined by other
                            interfaces <Code>TableHeader</Code>, <Code>TableRow</Code>,
                            and <Code>TableCell</Code>.
                        </p>

                        <section>
                            <h2>TableDef</h2>
                            <ComponentPropsTable componentProps={tableDefDetails} />
                        </section>

                        <section>
                            <h2>TableHeader</h2>
                            <ComponentPropsTable componentProps={tableHeaderDetails} />
                        </section>

                        <section>
                            <h2>TableRow</h2>
                            <ComponentPropsTable componentProps={tableRowDetails} />
                        </section>

                        <section>
                            <h2>TableCell</h2>
                            <ComponentPropsTable componentProps={tableCellDetails} />
                        </section>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};
export default memo(TableUsageExamples);

import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const selectHeaderDescription = `Custom select component which emulates the native HTML
select component as closely as possible with added functionality such as grouping elements and
showing icons within the dropdown.`;

export const selectPropDetails: ComponentPropDetail[] = [
    {
        name: 'title',
        types: ['string'],
        isOptional: false,
        description: 'Title text of the select toggle button.',
        defaultValue: 'N/A'
    },
    {
        name: 'id',
        types: ['string'],
        isOptional: false,
        description: `ID of the select toggle button. Also used to create IDs for the other internal
        elements such as the option dropdown.`,
        defaultValue: 'N/A'
    },
    {
        name: 'selectOptions',
        types: ['SelectOption[]'],
        isOptional: false,
        description: 'List of Options.',
        defaultValue: 'N/A'
    },
    {
        name: 'ariaLabeledBy',
        types: ['string'],
        isOptional: true,
        description: 'ID of the element labeling this interactive component.',
        defaultValue: 'undefined'
    },
    {
        name: 'inputId',
        types: ['string'],
        isOptional: true,
        description: `If provided, a hidden input element will be added to the page with this ID.
        Provide this when the Select is part of a form.`,
        defaultValue: 'undefined'
    },
    {
        name: 'inputName',
        types: ['string'],
        isOptional: true,
        description: `Required if inputId is provided. This is the "name" attribute on the hidden
        input.`,
        defaultValue: 'undefined'
    },
    {
        name: 'isDisabled',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the select toggle button is disabled.',
        defaultValue: 'false'
    },
    {
        name: 'onChange',
        types: ['(newValue: string) => void'],
        isOptional: true,
        description: (
            <>
                Emits an event when the value changes providing that new value. This should be
                created using <Code>useCallback()</Code> to prevent unnecessary side effects.
            </>
        ),
        defaultValue: '() => undefined'
    },
    {
        name: 'isHidden',
        types: ['boolean'],
        isOptional: true,
        description: 'Wether the component is currently hidden from the screen.',
        defaultValue: 'false'
    },
    {
        name: 'placeholderText',
        types: ['string'],
        isOptional: true,
        description: 'Text displayed when no option is selected.',
        defaultValue: '\'Choose an option\''
    },
    {
        name: 'defaultValue',
        types: ['string'],
        isOptional: true,
        description: (
            <>
                Option with this <Code>value</Code> will be selected by default.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'optionsContainerRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: true,
        description: (
            <>
                This prop is necessary if the Select component&apos;s default parent container is
                not large enough to contain the full height for dropdown options and is set
                to <Code lang="css">overflow: hidden</Code>. If provided, this container must
                have <Code lang="css">position: relative</Code>.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'size',
        types: ['\'regular\'', '\'large\'', '\'full\''],
        isOptional: true,
        description: 'Size of the select toggle button and dropdown.',
        defaultValue: '\'regular\''
    }
];

export const optionGroupDataDetails: ComponentPropDetail[] = [
    {
        name: 'type',
        types: ['\'group\''],
        isOptional: false,
        description: (
            <>
                Required to differentiate this from <Code>OptionData</Code>. Should always be
                set to <Code>&apos;group&apos;</Code>.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'label',
        types: ['string'],
        isOptional: false,
        description: 'Text shown for the group.',
        defaultValue: 'N/A'
    },
    {
        name: 'groupId',
        types: ['string'],
        isOptional: false,
        description: 'ID of the group.',
        defaultValue: 'N/A'
    },
    {
        name: 'childOptions',
        types: ['OptionData[]'],
        isOptional: false,
        description: 'Child options for this group.',
        defaultValue: 'N/A'
    },
    {
        name: 'preIcon',
        types: ['ReactElement'],
        isOptional: true,
        description: 'Icon that will appear before the label.',
        defaultValue: 'N/A'
    },
    {
        name: 'postIcon',
        types: ['ReactElement'],
        isOptional: true,
        description: 'Icon that will appear after the label.',
        defaultValue: 'N/A'
    }
];

export const optionGroupDetails: ComponentPropDetail[] = [
    {
        name: 'type',
        types: ['\'option\''],
        isOptional: false,
        description: (
            <>
                Required to differentiate this from <Code>OptionGroupData</Code>. Should always be
                set to <Code>&apos;option&apos;</Code>.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'label',
        types: ['string'],
        isOptional: false,
        description: 'Text shown for this option.',
        defaultValue: 'N/A'
    },
    {
        name: 'value',
        types: ['string'],
        isOptional: false,
        description: 'Value for the option. This treated as a standard HTML select option value.',
        defaultValue: 'N/A'
    },
    {
        name: 'preIcon',
        types: ['ReactElement'],
        isOptional: true,
        description: 'Icon that will appear before the label.',
        defaultValue: 'N/A'
    },
    {
        name: 'postIcon',
        types: ['ReactElement'],
        isOptional: true,
        description: 'Icon that will appear after the label.',
        defaultValue: 'N/A'
    }
];

export const standardCode = `<Select
    title="Standard Select"
    id="standard-select"
    placeholderText="Choose a fruit"
    selectOptions={standardOptions}
/>
`;

export const standardOptionsCode = `const standardOptions: SelectOption[] = [
    {
        type: 'option',
        label: 'Banana',
        value: 'banana'
    },
    {
        type: 'option',
        label: 'Apple',
        value: 'apple',
        icon: <FontAwesomeIcon icon={faAppleWhole} />
    },
    {
        type: 'option',
        label: 'Blueberry',
        value: 'blueberry'
    },
    {
        type: 'option',
        label: 'Cherry',
        value: 'cherry'
    }
];
`;

export const groupingCode = `<Select
    title="Grouped Select"
    id="grouped-select"
    selectOptions={groupOptions}
/>
`;

export const groupingOptionsCode = `const groupOptions: SelectOption[] = [
    {
        type: 'group',
        label: 'Fruits',
        groupId: 'fruits-group',
        childOptions: [
            {
                type: 'option',
                label: 'Banana',
                value: 'banana'
            },
            {
                type: 'option',
                label: 'Apple',
                value: 'apple',
                preIcon: <FontAwesomeIcon icon={faAppleWhole} />
            },
            {
                type: 'option',
                label: 'Blueberry',
                value: 'blueberry'
            },
            {
                type: 'option',
                label: 'Cherry',
                value: 'cherry'
            }
        ]
    },
    {
        type: 'group',
        label: 'Vegetables',
        groupId: 'vegetables-group',
        preIcon: <FontAwesomeIcon icon={faLeaf} />,
        postIcon: <FontAwesomeIcon icon={faCartShopping} />,
        childOptions: [
            {
                type: 'option',
                label: 'Cabbage',
                value: 'cabbage'
            },
            {
                type: 'option',
                label: 'Carrot',
                value: 'carrot',
                preIcon: <FontAwesomeIcon icon={faCarrot} />
            }
        ]
    },
    {
        type: 'group',
        label: 'Bulk',
        groupId: 'bulk-group',
        preIcon: <FontAwesomeIcon icon={faBucket} />,
        childOptions: [
            {
                type: 'option',
                label: 'Oatmeal',
                value: 'oatmeal'
            }
        ]
    },
    {
        type: 'option',
        label: 'Mustard',
        value: 'mustard'
    }
];
`;

export const popoverContainerCode = `const pageRef = useRef<HTMLDivElement>(null);

<div ref={pageRef} style={{ position: 'relative' }}>
    <div className={styles['small-container']}>
        <div className={styles['container-description']}>
            <Code>optionsContainerRef</Code> not provided.
        </div>
        <Select
            title="Small Container Select"
            id="small-container-select-no-container"
            selectOptions={optionsContainerRef}
            defaultValue="banana"
        />
    </div>
    <div className={styles['small-container']}>
        <div className={styles['container-description']}>
            <Code>optionsContainerRef</Code> is provided.
        </div>
        <Select
            title="Small Container Select"
            id="small-container-select-with-container"
            selectOptions={optionsContainerRef}
            defaultValue="banana"
            optionsContainerRef={pageRef}
        />
    </div>
</div>
`;

export const popoverContainerOptionsCode = `const popoverContainerOptions: SelectOption[] = [
    {
        type: 'option',
        label: 'Apple',
        value: 'apple'
    },
    {
        type: 'option',
        label: 'Pear',
        value: 'pear'
    },
    {
        type: 'option',
        label: 'Orange',
        value: 'orange'
    },
    {
        type: 'option',
        label: 'Grapefruit',
        value: 'grapefruit'
    },
    {
        type: 'option',
        label: 'Mandarin',
        value: 'mandarin'
    },
    {
        type: 'option',
        label: 'Lime',
        value: 'lime'
    },
    {
        type: 'option',
        label: 'Lemon',
        value: 'lemon'
    },
    {
        type: 'option',
        label: 'Nectarines',
        value: 'nectarines'
    },
    {
        type: 'option',
        label: 'Apricots',
        value: 'apricots'
    },
    {
        type: 'option',
        label: 'Peaches',
        value: 'peaches'
    },
    {
        type: 'option',
        label: 'Plums',
        value: 'plums'
    },
    {
        type: 'option',
        label: 'Banana',
        value: 'banana'
    },
    {
        type: 'option',
        label: 'Mango',
        value: 'mango'
    },
    {
        type: 'option',
        label: 'Pineapple',
        value: 'pineapple'
    },
    {
        type: 'option',
        label: 'Strawberry',
        value: 'strawberry'
    },
    {
        type: 'option',
        label: 'Raspberry',
        value: 'raspberry'
    },
    {
        type: 'option',
        label: 'Blueberry',
        value: 'blueberry'
    },
    {
        type: 'option',
        label: 'Kiwi',
        value: 'kiwi'
    },
    {
        type: 'option',
        label: 'Passionfruit',
        value: 'passionfruit'
    },
    {
        type: 'option',
        label: 'Watermelon',
        value: 'watermelon'
    },
    {
        type: 'option',
        label: 'Honeydew',
        value: 'honeydew'
    },
    {
        type: 'option',
        label: 'Cantaloupe',
        value: 'cantaloupe'
    },
    {
        type: 'option',
        label: 'Tomato',
        value: 'tomato'
    }
];
`;

export const sizesCode = `<Select
    title="Regular Example"
    id="regular-example"
    placeholderText="Regular Example"
    selectOptions={[]}
/>
<Select
    title="Large Example"
    id="large-example"
    placeholderText="Large Example"
    selectOptions={[]}
    size="large"
/>
<Select
    title="Full Example"
    id="full-example"
    placeholderText="Full Example"
    selectOptions={[]}
    size="full"
/>
`;

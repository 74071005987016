export interface ColorDef {
    id: string;
    name: string;
    hex: string;
    cssVar: string;
    defaultTxtColor: 'cultured'|'raisin-black';
}

export const COLOR_DEFS: { [key: string]: ColorDef[] } = {
    // PRIMARY COLORS
    Primary: [
        {
            id: 'Primary.indigo',
            name: 'Indigo',
            hex: '#320D82',
            cssVar: 'indigo',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Primary.raisin-black',
            name: 'Raisin Black',
            hex: '#242124',
            cssVar: 'raisin-black',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Primary.cultured',
            name: 'Cultured',
            hex: '#F9F9F9',
            cssVar: 'cultured',
            defaultTxtColor: 'raisin-black'
        }
    ],
    // INDIGO COLORS
    Indigo: [
        {
            id: 'Indigo.midnight-blue',
            name: 'Midnight Blue',
            hex: '#240A5C',
            cssVar: 'midnight-blue',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Indigo.indigo',
            name: 'Indigo',
            hex: '#390F95',
            cssVar: 'indigo',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Indigo.purple',
            name: 'Purple',
            hex: '#5526BA',
            cssVar: 'purple',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Indigo.plump-purple',
            name: 'Plump Purple',
            hex: '#6035B5',
            cssVar: 'plump-purple',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Indigo.bright-lilac',
            name: 'Bright Lilac',
            hex: '#D093FF',
            cssVar: 'bright-lilac',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Indigo.mauve',
            name: 'Mauve',
            hex: '#E6C8FF',
            cssVar: 'mauve',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Indigo.pale-purple',
            name: 'Pale Purple',
            hex: '#EFDFFE',
            cssVar: 'pale-purple',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Indigo.ghost-white',
            name: 'Ghost White',
            hex: '#F8F6FE',
            cssVar: 'ghost-white',
            defaultTxtColor: 'raisin-black'
        }
    ],
    // GREEN COLORS
    Green: [
        {
            id: 'Green.lincoln-green',
            name: 'Lincoln Green',
            hex: '#005213',
            cssVar: 'lincoln-green',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Green.dark-green',
            name: 'Dark Green',
            hex: '#006200',
            cssVar: 'dark-green',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Green.mantis',
            name: 'Mantis',
            hex: '#61C159',
            cssVar: 'mantis',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Green.nyanza',
            name: 'Nyanza',
            hex: '#DDF9D1',
            cssVar: 'nyanza',
            defaultTxtColor: 'raisin-black'
        }
    ],
    // BLUE COLORS
    Blue: [
        {
            id: 'Blue.denim-blue',
            name: 'Denim Blue',
            hex: '#093EAE',
            cssVar: 'denim-blue',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Blue.absolute-zero',
            name: 'Absolute Zero',
            hex: '#0046C8',
            cssVar: 'absolute-zero',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Blue.sky-blue',
            name: 'Sky Blue',
            hex: '#7BAFFF',
            cssVar: 'sky-blue',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Blue.beau-blue',
            name: 'Beau Blue',
            hex: '#CEE5FD',
            cssVar: 'beau-blue',
            defaultTxtColor: 'raisin-black'
        }
    ],
    // YELLOW COLORS
    Yellow: [
        {
            id: 'Yellow.gamboge',
            name: 'Gamboge',
            hex: '#EC9C1C',
            cssVar: 'gamboge',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Yellow.honey-yellow',
            name: 'Honey Yellow',
            hex: '#FFAE00',
            cssVar: 'honey-yellow',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Yellow.sunglow',
            name: 'Sunglow',
            hex: '#FFC83F',
            cssVar: 'sunglow',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Yellow.lemon-chiffon',
            name: 'Lemon Chiffon',
            hex: '#FFF5CC',
            cssVar: 'lemon-chiffon',
            defaultTxtColor: 'raisin-black'
        }
    ],
    // RED COLORS
    Red: [
        {
            id: 'Red.claret',
            name: 'Claret',
            hex: '#7C1331',
            cssVar: 'claret',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Red.vivid-burgundy',
            name: 'Vivid Burgundy',
            hex: '#A41533',
            cssVar: 'vivid-burgundy',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Red.light-coral',
            name: 'Light Coral',
            hex: '#FF8A91',
            cssVar: 'light-coral',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Red.misty-rose',
            name: 'Misty Rose',
            hex: '#F9D8D0',
            cssVar: 'misty-rose',
            defaultTxtColor: 'raisin-black'
        }
    ],
    // NEUTRAL COLORS
    Neutral: [
        {
            id: 'Neutral.black',
            name: 'Black',
            hex: '#000000',
            cssVar: 'black',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Neutral.eerie-black',
            name: 'Eerie Black',
            hex: '#151515',
            cssVar: 'black',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Neutral.jet',
            name: 'Jet',
            hex: '#2A2A2A',
            cssVar: 'jet',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Neutral.onyx',
            name: 'Onyx',
            hex: '#3E3E3E',
            cssVar: 'onyx',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Neutral.davys-grey',
            name: 'Davys Grey',
            hex: '#535353',
            cssVar: 'davys-grey',
            defaultTxtColor: 'cultured'
        },
        {
            id: 'Neutral.silver-chalice',
            name: 'Silver Chalice',
            hex: '#ADADAD',
            cssVar: 'silver-chalice',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Neutral.silver',
            name: 'Silver',
            hex: '#C2C2C2',
            cssVar: 'silver',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Neutral.light-gray',
            name: 'Light Gray',
            hex: '#D6D6D6',
            cssVar: 'light-gray',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Neutral.platinum',
            name: 'Platinum',
            hex: '#EBEBEB',
            cssVar: 'platinum',
            defaultTxtColor: 'raisin-black'
        },
        {
            id: 'Neutral.white',
            name: 'White',
            hex: '#FFFFFF',
            cssVar: 'white',
            defaultTxtColor: 'raisin-black'
        }
    ]
};

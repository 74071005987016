import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const overlayHeaderDescription = (
    <>
        Darkened area which overlays screen content. This overlay appears
        at <Code lang="css">z-index</Code> 100.
    </>
);

export const overlayPropDetails: ComponentPropDetail[] = [
    {
        name: 'isVisible',
        types: ['boolean'],
        isOptional: false,
        description: 'Whether the overlay is currently visible.',
        defaultValue: 'N/A'
    }
];

export const darkShadesCode = `const [isVisible, setIsVisible] = useState(false);

useKeyDown(() => setIsVisible(false), ['Escape'], isVisible);

<Button onClick={() => setIsVisible(true)}>
    Open Overlay
</Button>

<Overlay isVisible={isVisible} />
`;

import { Fragment, ReactElement, ReactNode } from 'react';
import Code from '../../common-components/Code';
import styles from './ComponentPropsTable.module.css';
import Badge from '../../common-components/Badge';
import Table, { TableDef, TableHeader, TableRow } from '../../common-components/Table';

export interface ComponentPropDetail {
    name: string;
    types: string[];
    isOptional: boolean;
    description: ReactNode;
    defaultValue: string;
}

interface ComponentPropsTableProps {
    componentProps: ComponentPropDetail[];
}

const ComponentPropsTable = ({
    componentProps
}: ComponentPropsTableProps): ReactElement => {
    const getPropTypes = (types: string[]): ReactNode => (
        <div className={styles.types}>
            {types.map((type, index) => {
                const orSymbol = (index < types.length - 1) ? <> | </> : '';
                const key = `type-${index}-key`;
                return (
                    <Fragment key={key}>
                        <Code>{type}</Code>{orSymbol}
                    </Fragment>
                );
            })}
        </div>
    );

    const getDefaultValue = (defaultValue: string): ReactNode => (
        (defaultValue === 'N/A') ? 'N/A' : <Code>{defaultValue}</Code>
    );

    const headers: TableHeader[] = [
        {
            id: 'name',
            name: 'NAME'
        },
        {
            id: 'type',
            name: 'TYPE'
        },
        {
            id: 'description',
            name: 'DESCRIPTION'
        },
        {
            id: 'default',
            name: 'DEFAULT'
        }
    ];

    const rows: TableRow[] = componentProps.map((prop) => (
        {
            cells: [
                {
                    headerId: 'name',
                    content: prop.name
                },
                {
                    headerId: 'type',
                    content: getPropTypes(prop.types)
                },
                {
                    headerId: 'description',
                    content: (
                        <>
                            {prop.isOptional && (
                                <Badge
                                    color="blue"
                                    shade="light"
                                >
                                    Optional
                                </Badge>
                            )} {prop.description}
                        </>
                    )
                },
                {
                    headerId: 'default',
                    content: getDefaultValue(prop.defaultValue)
                }
            ]
        }
    ));

    const tableDef: TableDef = { headers, rows };

    return (
        <Table tableDef={tableDef} />
    );
};
export default ComponentPropsTable;

import {
    cloneElement, ReactElement, Children, ReactNode
} from 'react';
import styles from './ComponentInfoSectionBuilder.module.css';
import Code from '../../common-components/Code';
import Collapsible from '../../common-components/Collapsible';

interface ComponentInfoSectionBuilderDef {
    getExampleSection: (content: ReactElement[]) => ReactElement;
    getExampleTool: (content: ReactElement[], toolId?: string) => ReactElement;
    getComponentSectionHeader: (text: string, includeTopSpacing?: boolean) => ReactElement;
    getComponentSectionDescription: (description: ReactElement) => ReactElement;
    getCodeSection: (code: string, lang?: 'tsx'|'css') => ReactElement;
    getCollapsibleCodeSection: (
        code: string,
        title: string,
        toggleLabel: ReactNode,
        lang?: 'tsx'|'css'
    ) => ReactElement;
    getComponentSection: (content: ReactElement[], isFirstSection?: boolean) => ReactElement
}

const ComponentInfoSectionBuilder = (id: string): ComponentInfoSectionBuilderDef => (
    {
        getComponentSection: (content: ReactElement[], isFirstSection = false): ReactElement => (
            <>
                {!isFirstSection && <hr />}
                <section>{content}</section>
            </>
        ),

        getComponentSectionHeader: (text: string, includeTopSpacing = false): ReactElement => {
            let cssClass = '';
            if (!includeTopSpacing) {
                cssClass += 'margin-top-0 ';
            }
            cssClass += styles['section-header'];

            return (
                <h2
                    className={cssClass}
                    key={`${id}-header`}
                >
                    {text}
                </h2>
            );
        },

        getComponentSectionDescription: (description: ReactElement): ReactElement => (
            cloneElement(description, { key: `${id}-description` })
        ),

        getExampleTool: (content: ReactElement[], toolId = 'key'): ReactElement => (
            <div className={styles['example-tool']} key={`${toolId}-tool-section`}>
                {Children.map(content, (child, index) => {
                    const key = `${id}-tool-${toolId}-i${index}`;
                    return cloneElement(child, { key });
                })}
            </div>
        ),

        getExampleSection: (content: ReactElement[]): ReactElement => (
            <div key={`${id}-example-area`}>
                <div className={styles['example-label']}>
                    <strong>
                        Example
                    </strong>
                </div>
                <div className={styles.example}>
                    {Children.map(content, (child, index) => {
                        const key = `${id}-example-i${index}`;
                        return cloneElement(child, { key });
                    })}
                </div>
            </div>
        ),

        getCodeSection: (code: string, lang: 'tsx'|'css' = 'tsx'): ReactElement => (
            <div key={`${id}-${lang}-code-area`}>
                <div className={styles['example-label']}>
                    <strong>
                        {`Code (${lang})`}
                    </strong>
                </div>
                <Code lang={lang} isCodeBlock>{code}</Code>
            </div>
        ),

        getCollapsibleCodeSection: (
            code: string,
            title: string,
            toggleLabel: ReactNode,
            lang: 'tsx'|'css' = 'tsx'
        ): ReactElement => (
            <div key={`${id}-${title}-collapsible-code-area`}>
                <Collapsible
                    title={title}
                    toggleLabel={toggleLabel}
                    collapsibleId={`${id}-${title}-collapsible-code`}
                >
                    <Code lang={lang} isCodeBlock>{code}</Code>
                </Collapsible>
            </div>
        )
    }
);
export default ComponentInfoSectionBuilder;

import { ComponentPropDetail } from '../ComponentPropsTable';

export const badgeHeaderDescription = `Provides the ability to have highlighted inline text in a
variety of colors and shades depending on intent.`;

export const badgePropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the Badge.',
        defaultValue: 'N/A'
    },
    {
        name: 'color',
        types: ['\'indigo\'', '\'green\'', '\'blue\'', '\'yellow\'', '\'red\'', '\'neutral\''],
        isOptional: true,
        description: 'Color for the Badge\'s background.',
        defaultValue: '\'indigo\''
    },
    {
        name: 'shade',
        types: ['\'dark\'', '\'light\''],
        isOptional: true,
        description: 'Determines whether the Badge\'s background is the darker or lighter variety.',
        defaultValue: '\'dark\''
    }
];

export const darkShadesCode = `<Badge>Indigo</Badge>
<Badge color="green">Green</Badge>
<Badge color="blue">Blue</Badge>
<Badge color="yellow">Yellow</Badge>
<Badge color="red">Red</Badge>
<Badge color="neutral">Neutral</Badge>
`;

export const lightShadesCode = `<Badge shade="light">Indigo</Badge>
<Badge shade="light" color="green">Green</Badge>
<Badge shade="light" color="blue">Blue</Badge>
<Badge shade="light" color="yellow">Yellow</Badge>
<Badge shade="light" color="red">Red</Badge>
<Badge shade="light" color="neutral">Neutral</Badge>
`;

export const badgeWithIconsCode = `<Badge color="neutral" shade="light">
    <FontAwesomeIcon icon={faReact} />
    &nbsp;React
</Badge>
<Badge color="blue">
    <FontAwesomeIcon icon={faComment} />
    &nbsp;6
</Badge>
`;

import {
    memo, ReactElement, RefObject, useCallback, useEffect, useRef, useState
} from 'react';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Select.module.css';
import DelayedPortal from './DelayedPortal';
import {
    useClickOutside, useCompare, useKeyDown, useMountEffect, useResize
} from './commonHooks';
import Option, { OptionData } from './internal/Option';
import OptionGroup, { OptionGroupData } from './internal/OptionGroup';

export type SelectOption = OptionData | OptionGroupData;

interface SelectProps {
    title: string;
    id: string;
    selectOptions: SelectOption[]
    ariaLabeledBy?: string;
    inputId?: string;
    inputName?: string;
    isDisabled?: boolean;
    onChange?: (newValue: string) => void;
    isHidden?: boolean;
    placeholderText?: string,
    defaultValue?: string;
    optionsContainerRef?: RefObject<HTMLElement>;
    size?: 'regular'|'large'|'full';
}

const Select = ({
    title,
    id,
    selectOptions,
    ariaLabeledBy = undefined,
    inputId = undefined,
    inputName = undefined,
    isDisabled = false,
    onChange = () => undefined,
    isHidden = false,
    placeholderText = 'Choose an option',
    defaultValue = '',
    optionsContainerRef,
    size = 'regular'
}: SelectProps): ReactElement => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOnDom, setIsOnDom] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState<OptionData>({
        type: 'option',
        label: '',
        value: ''
    });
    const [focusedIndex, setFocusedIndex] = useState(-1);

    const containerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const optionsRef = useRef<HTMLUListElement>(null);
    const optionsFlattened = useRef<OptionData[]>([]);

    // --------------------------------------------------
    //    [GENERAL HELPER FUNCTIONS]
    // --------------------------------------------------

    const getActualContainer = useCallback((): RefObject<HTMLElement> => (
        // If not providing a different container, use the built-in container
        optionsContainerRef ?? containerRef
    ), [optionsContainerRef]);

    const findOptionIndex = (option: OptionData): number => {
        const optionMatch = (opt: OptionData): boolean => option.value === opt.value;
        return optionsFlattened.current.findIndex(optionMatch);
    };

    // --------------------------------------------------
    //    [EXTRACT OPTION VALUES TO THE REF FOR FUTURE USE]
    // --------------------------------------------------

    const getOptionsFlattened = useCallback((): OptionData[] => (
        selectOptions.flatMap((selectOption) => {
            if (selectOption.type === 'group') {
                return selectOption.childOptions.map((childOption) => childOption);
            }
            return selectOption;
        })
    ), [selectOptions]);

    useEffect(() => {
        // Flatten out the options/groups for easy access by index later
        optionsFlattened.current = getOptionsFlattened();
    }, [selectOptions, getOptionsFlattened]);

    // --------------------------------------------------
    //    [SET POSITION OF OPTIONS POPOVER]
    // --------------------------------------------------

    const setOptionsPosition = useCallback(() => {
        const actualContainer = getActualContainer();
        if (actualContainer
            && actualContainer.current
            && optionsRef.current
            && buttonRef.current) {
            const buttonContainerRect = buttonRef.current.getBoundingClientRect();
            const popoverContainerRect = actualContainer.current.getBoundingClientRect();
            // Figure out the position relative to the target container
            const left = buttonContainerRect.left - popoverContainerRect.left;
            const top = buttonContainerRect.top - popoverContainerRect.top;
            const right = popoverContainerRect.right - buttonContainerRect.right;

            // Set the position
            optionsRef.current.style.left = `${left}px`;
            optionsRef.current.style.right = `${right}px`;

            const optionsRect = optionsRef.current.getBoundingClientRect();
            // Calculate if any part of the popover will be outside the bounds of the window
            if ((buttonContainerRect.y + optionsRect.height) > window.innerHeight) {
                // Outside the bounds, display the options above the button trigger
                optionsRef.current.style.top = `calc(${top - optionsRect.height}px - 0.5rem)`;
            } else {
                // Inside the bounds, display normally just below the button trigger
                optionsRef.current.style.top = `calc(${top + buttonContainerRect.height}px + 0.5rem)`;
            }
        }
    }, [getActualContainer]);

    useEffect(() => {
        if (isOnDom) {
            setOptionsPosition();
        }
    }, [isOnDom, setOptionsPosition]);

    // When the browser resizes, reposition the list
    useResize(setOptionsPosition, isOnDom);

    // --------------------------------------------------
    //    [GENERAL OPEN/CLOSE FUNCTIONALITY]
    // --------------------------------------------------

    const openOptions = (): void => {
        setFocusedIndex(findOptionIndex(selectedOption));
        setIsOpen(true);
        buttonRef.current?.blur();
    };

    const closeOptions = (shouldFocus = true): void => {
        setIsOpen(false);
        if (shouldFocus) {
            buttonRef.current?.focus();
        }
    };

    const handleToggle = (): void => {
        if (isOpen) {
            closeOptions();
        } else {
            openOptions();
        }
    };

    // Close on escape
    useKeyDown(closeOptions, ['Escape'], isOnDom);

    // Simulate tabbing
    useKeyDown(closeOptions, ['Tab'], isOnDom);

    // Simulate clicking outside the list
    useClickOutside(() => closeOptions(false), [optionsRef, buttonRef], isOnDom);

    // --------------------------------------------------
    //    [TRAVERSING OPTIONS VIA ARROW KEYS]
    // --------------------------------------------------

    const traverseOptions = (prevIndex: number, direction: number): number => {
        let newIndex = prevIndex + direction;
        // Handle out of lower bounds
        if (newIndex < 0) {
            newIndex = optionsFlattened.current.length - 1;
        }
        // Handle out of upper bounds
        if (newIndex >= optionsFlattened.current.length) {
            newIndex = 0;
        }
        return newIndex;
    };

    const handleArrowKeyDown = (direction: number): void => {
        if (!isOpen
            && buttonRef.current
            && (buttonRef.current === document.activeElement)) {
            // Handle opening of the options on arrow key
            setIsOpen(true);
            const selectedIndex = findOptionIndex(selectedOption);
            setFocusedIndex(traverseOptions(selectedIndex, direction));
        } else {
            // Options already open, so just traverse
            setFocusedIndex((prevState) => traverseOptions(prevState, direction));
        }

        if (!isOpen) {
            buttonRef.current?.blur();
        }
    };

    const handleDownArrow = (): void => handleArrowKeyDown(1);
    const handleUpArrow = (): void => handleArrowKeyDown(-1);

    // Traverse down the list
    useKeyDown(handleDownArrow, ['ArrowDown'], isVisible);

    // Open and start at first item (traverse down from top)
    useKeyDown(handleDownArrow, ['ArrowDown'], true, buttonRef);

    // Traverse up the list
    useKeyDown(handleUpArrow, ['ArrowUp'], isVisible);

    // Open and start at last item (traverse up from top)
    useKeyDown(handleUpArrow, ['ArrowUp'], true, buttonRef);

    // --------------------------------------------------
    //    [SELECTING AN OPTION]
    // --------------------------------------------------

    const handleEnterKey = (): void => {
        // Set selected index based on current focused item then close
        setSelectedOption(optionsFlattened.current[focusedIndex]);
        setIsOpen(false);
        buttonRef.current?.focus();
    };

    const handleOptionClick = (option: OptionData): void => {
        // Set selected index then close
        setSelectedOption(option);
        setIsOpen(false);
    };

    // Select focused option on enter key
    useKeyDown(handleEnterKey, ['Enter', 'NumpadEnter'], isOnDom);

    const selectedOptionChanged = useCompare(selectedOption);
    // Let parent know a change happened
    useEffect(() => {
        if (selectedOptionChanged) {
            onChange(selectedOption.value);
        }
    }, [selectedOption, onChange, selectedOptionChanged]);

    // --------------------------------------------------
    //    [BUILD OUT THE ACTUAL REACT ELEMENTS]
    // --------------------------------------------------

    const getListItem = (option: OptionData, index: number): ReactElement => (
        <Option
            option={option}
            isFocused={focusedIndex === index}
            isSelected={selectedOption.value === option.value}
            isVisible={isVisible}
            onClick={handleOptionClick}
            key={`${id}-option-${option.value}`}
        />
    );

    const getListGroup = (optionGroup: OptionGroupData, groupIndex: number): ReactElement => (
        <OptionGroup
            optionGroup={optionGroup}
            groupIndex={groupIndex}
            focusedIndex={focusedIndex}
            selectedOption={selectedOption}
            isVisible={isVisible}
            onClick={handleOptionClick}
            id={`${id}-group`}
            key={`${id}-group-${optionGroup.groupId}`}
        />
    );

    const getOptionElements = (): ReactElement[] => {
        const elements: ReactElement[] = [];

        if (selectOptions.length > 0) {
            let index = 0;
            selectOptions.forEach((option) => {
                if (option.type === 'group') {
                    // Handle Grouped Elements
                    elements.push(getListGroup(option, index));
                    index += option.childOptions.length;
                } else {
                    // Handle Single Element
                    elements.push(getListItem(option, index));
                    index += 1;
                }
            });
        } else {
            // Handle no options
            const element = (
                <li
                    className={styles['no-options-item']}
                    key={`${id}-no-options-item`}
                >
                    No Options
                </li>
            );
            elements.push(element);
        }

        return elements;
    };

    // --------------------------------------------------
    //    [BUILD THE OVERALL SELECT ELEMENT]
    // --------------------------------------------------

    const getButtonClass = (): string => {
        let cssClass = `${styles['select-input']} `;
        cssClass += styles[`select-${size}`];
        return cssClass;
    };

    const getOptionsClass = (): string => {
        let cssClass = `${styles['select-options']}`;
        if (isOnDom) {
            cssClass += ` ${styles['select-options-on-dom']}`;
        }
        if (isVisible) {
            cssClass += ` ${styles['select-options-visible']}`;
        }
        return cssClass;
    };

    const getButtonText = (): string => (
        selectedOption.label === '' ? placeholderText : selectedOption.label
    );

    const getInput = (): ReactElement|null => {
        if (inputId) {
            return (
                <input
                    type="hidden"
                    id={inputId}
                    name={inputName}
                    value={selectedOption.value}
                />
            );
        }
        return null;
    };

    useMountEffect(() => {
        // Select default option on initial load
        const defaultOption = getOptionsFlattened().find((option) => option.value === defaultValue);
        if (defaultOption) {
            setSelectedOption(defaultOption);
        }
    });

    const handleIsOnDom = useCallback(() => (setIsOnDom(true)), []);

    const handleNotIsOnDom = useCallback(() => (setIsOnDom(false)), []);

    const handleIsVisible = useCallback(() => (setIsVisible(true)), []);

    const handleNotIsVisible = useCallback(() => (setIsVisible(false)), []);

    return (
        <div
            className={styles['select-container']}
            ref={containerRef}
        >
            <button
                type="button"
                id={id}
                title={title}
                aria-labelledby={ariaLabeledBy}
                aria-expanded={isOpen}
                aria-controls={`${id}-options`}
                aria-haspopup="listbox"
                tabIndex={isHidden ? -1 : undefined}
                className={getButtonClass()}
                disabled={isDisabled}
                ref={buttonRef}
                onClick={handleToggle}
            >
                {selectedOption.preIcon && (
                    <span className={styles['select-label-content']}>
                        {selectedOption.preIcon}
                    </span>
                )}
                <span className={styles['select-label-content']}>{getButtonText()}</span>
                {selectedOption.postIcon && (
                    <span className={styles['select-label-content']}>
                        {selectedOption.postIcon}
                    </span>
                )}
                <span className={styles['select-caret-icon']}>
                    <FontAwesomeIcon icon={faCaretDown} />
                </span>
            </button>
            <DelayedPortal
                isActive={isOpen}
                containerRef={getActualContainer()}
                onIsOnDom={handleIsOnDom}
                onNotIsOnDom={handleNotIsOnDom}
                onIsVisible={handleIsVisible}
                onNotIsVisible={handleNotIsVisible}
                closeDelay={300}
            >
                <ul
                    className={getOptionsClass()}
                    role="listbox"
                    aria-labelledby={ariaLabeledBy}
                    id={`${id}-options`}
                    ref={optionsRef}
                >
                    {getOptionElements()}
                </ul>
            </DelayedPortal>
            {getInput()}
        </div>
    );
};

export default memo(Select);

import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const codeHeaderDescription = (
    <>
        Displays either inline code or a code block. Uses the
        library&nbsp;
        <a
            href="https://prismjs.com/index.html"
            target="_blank"
            rel="noreferrer"
        >
            Prismjs
        </a> for style and code
        highlighting.
    </>
);

export const codePropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['string'],
        isOptional: false,
        description: `Code which will be highlighted / rendered. Special characters will need to
        escaped unless passing the code as a template string.`,
        defaultValue: 'N/A'
    },
    {
        name: 'language',
        types: ['string'],
        isOptional: true,
        description: `The language to highlight / render. Available languages: markup, html, xml,
        svg, mathml, ssml, atom, rss, css, clike, javascript, js, jsx, tsx, typescript, ts.`,
        defaultValue: 'ts'
    },
    {
        name: 'isCodeBlock',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Whether this is a code block. If <Code>true</Code>, displays a full block of code.
                Otherwise, inline code will be used.
            </>
        ),
        defaultValue: 'false'
    },
    {
        name: 'id',
        types: ['string'],
        isOptional: true,
        description: <>ID of the internal <Code>code</Code> element.</>,
        defaultValue: 'N/A'
    }
];

export const inlineCode = `<>
    The <Code>isCodeBlock</Code> prop controls whether the code is inline or not.
    Don&apos;t forget to escape special characters when using
    the <Code>&lt;Code&gt;</Code> component.
</>
`;

export const blockCode = `const blockExampleCode = \`<div>
    <span>Some awesome code goes here!</span>
    <button type="button">OK!</button>
</div>
\`;

<Code isCodeBlock>{blockExampleCode}</Code>
`;

import {
    ReactNode, memo, useState, RefObject, useRef, useCallback, ReactElement
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './Drawer.module.css';
import Overlay from './Overlay';
import FocusGuard from './FocusGuard';
import DelayedPortal from './DelayedPortal';
import { useClickOutside, useKeyDown } from './commonHooks';

interface DrawerProps {
    children: ReactNode;
    isOpen: boolean;
    closeButtonLabel: string;
    returnFocusableRef: RefObject<HTMLElement>;
    finalFocusableRef?: RefObject<HTMLElement>;
    containerRef?: RefObject<HTMLElement>;
    onClose?: () => void;
    onIsOnDom?: () => void;
    onNotIsOnDom?: () => void;
}

const Drawer = ({
    children,
    isOpen,
    closeButtonLabel,
    returnFocusableRef,
    finalFocusableRef = undefined,
    containerRef,
    onClose = () => undefined,
    onIsOnDom = () => undefined,
    onNotIsOnDom = () => undefined
}: DrawerProps): ReactElement => {
    const [isVisible, setIsVisible] = useState(false);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const drawerRef = useRef<HTMLDivElement>(null);

    useKeyDown(onClose, ['Escape'], isOpen);
    useClickOutside(onClose, [drawerRef], isOpen);

    const handleIsVisible = useCallback(() => (
        setIsVisible(true)
    ), []);

    const handleNotIsVisible = useCallback(() => (
        setIsVisible(false)
    ), []);

    const getDrawerStyle = (): string => (
        `${styles.drawer} ${isVisible ? styles['drawer-visible'] : ''}`
    );

    const getFinalFocusableRef = (): RefObject<HTMLElement> => (
        finalFocusableRef ?? closeButtonRef
    );

    return (
        <DelayedPortal
            isActive={isOpen}
            containerRef={containerRef}
            closeDelay={250}
            onIsOnDom={onIsOnDom}
            onNotIsOnDom={onNotIsOnDom}
            onIsVisible={handleIsVisible}
            onNotIsVisible={handleNotIsVisible}
        >
            <FocusGuard
                isActive={isOpen}
                returnFocusableRef={returnFocusableRef}
                firstFocusableRef={closeButtonRef}
                finalFocusableRef={getFinalFocusableRef()}
            >
                <div
                    className={getDrawerStyle()}
                    role="dialog"
                    aria-modal
                    ref={drawerRef}
                >
                    <button
                        type="button"
                        className={styles['drawer-close-toggle']}
                        onClick={onClose}
                        title={closeButtonLabel}
                        aria-label={closeButtonLabel}
                        tabIndex={!isVisible ? -1 : undefined}
                        ref={closeButtonRef}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </button>

                    <div
                        className={styles['drawer-content']}
                    >
                        {children}
                    </div>
                </div>
                <Overlay isVisible={isVisible} />
            </FocusGuard>
        </DelayedPortal>
    );
};

export default memo(Drawer);

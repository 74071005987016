import { memo, ReactElement, ReactNode } from 'react';
import styles from './Badge.module.css';

interface BadgeProps {
    children: ReactNode;
    color?: 'indigo'|'green'|'blue'|'yellow'|'red'|'neutral';
    shade?: 'dark'|'light';
}

const Badge = ({ children, color = 'indigo', shade = 'dark' }: BadgeProps): ReactElement => {
    const getStyleClass = (): string => {
        let cssClass = `${styles.badge} `;
        cssClass += styles[`badge-${color}-${shade}`];
        return cssClass;
    };

    return (
        <div className={getStyleClass()}>
            {children}
        </div>
    );
};
export default memo(Badge);

import { Link } from 'react-router-dom';
import { ComponentPropDetail } from '../ComponentPropsTable';

export const focusGuardHeaderDescription = (
    <>
        Holds the keyboard focus of a user within the bounds of this guard. Useful for things
        like a <Link to="/components/drawer">Drawer</Link> or a Modal.
    </>
);

export const focusGuardPropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content to be captured within the guard.',
        defaultValue: 'N/A'
    },
    {
        name: 'isActive',
        types: ['boolean'],
        isOptional: false,
        description: 'Whether the guard is active.',
        defaultValue: 'N/A'
    },
    {
        name: 'returnFocusableRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: false,
        description: 'Ref to the element which will be focused once the guard is deactivated.',
        defaultValue: 'N/A'
    },
    {
        name: 'firstFocusableRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: false,
        description: 'Ref to the first element in the focus chain of elements within the guard.',
        defaultValue: 'N/A'
    },
    {
        name: 'finalFocusableRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: false,
        description: 'Ref to the final element in the focus chain of elements within the guard.',
        defaultValue: 'N/A'
    }
];

export const exampleCode = `const [isActive, setIsActive] = useState(false);
const returnFocusableRef = useRef<HTMLButtonElement>(null);
const firstFocusableRef = useRef<HTMLButtonElement>(null);
const finalFocusableRef = useRef<HTMLButtonElement>(null);

<Button
    buttonRef={returnFocusableRef}
    onClick={() => setIsActive(true)}
>
    Activate Guard
</Button>

<FocusGuard
    isActive={isActive}
    returnFocusableRef={returnFocusableRef}
    firstFocusableRef={firstFocusableRef}
    finalFocusableRef={finalFocusableRef}
>
    <div className={styles['example-guard-area']}>
        <h4 className={styles['guarded-area-header']}>Guarded Area</h4>
        <div>Guard Active: {\`\${isActive}\`}</div>
        <Button variant="info" buttonRef={firstFocusableRef}>First Button</Button>
        <Button variant="minimal">Second Button</Button>
        <Collapsible
            collapsibleId="some-collapsible"
            title="Some Collapsible"
            toggleLabel="Some Collapsible"
        >
            Content of a collapsible within a focus guard.
        </Collapsible>
        <Button
            variant="danger"
            onClick={() => setIsActive(false)}
        >
            Deactivate Guard
        </Button>
        <div className={styles['guarded-switch-area']}>
            <label
                htmlFor="a-switch"
                className={styles['guarded-area-label']}
            >
                Final Switch
            </label>
            <Switch
                switchTitle="A Switch"
                id="a-switch"
                toggleRef={finalFocusableRef}
            />
        </div>
    </div>
</FocusGuard>
`;

import { useRef, memo, ReactElement } from 'react';
import styles from './ColorCard.module.css';
import { ColorDef } from './ColorDef';
import { useMountEffect } from '../../../common-components/commonHooks';

interface ColorCardProps {
    colorDef: ColorDef;
}

const ColorCard = ({ colorDef }: ColorCardProps): ReactElement => {
    const colorCardRef = useRef<HTMLDivElement>(null);

    useMountEffect(() => {
        // Set background colors and main card's text color after initial render
        if (colorCardRef.current) {
            colorCardRef.current.style.backgroundColor = `var(--${colorDef.cssVar})`;
            colorCardRef.current.style.color = `var(--${colorDef.defaultTxtColor})`;
        }
    });

    return (
        <div className={styles['color-card-container']}>
            <div
                className={styles['color-card']}
                ref={colorCardRef}
            >
                <span className={styles['color-name']}>{colorDef.name}</span>
            </div>

            <h4 className={styles['color-card-header']}>
                HEX
            </h4>
            <div>
                {colorDef.hex}
            </div>

            <h4 className={styles['color-card-header']}>
                CSS Var
            </h4>
            <div className={styles['css-var-text']}>
                --{colorDef.cssVar}
            </div>
        </div>
    );
};

export default memo(ColorCard);

import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const portalHeaderDescription = (
    <>
        Enhanced version of React&apos;s&nbsp;
        <a
            href="https://reactjs.org/docs/portals.html"
            target="_blank"
            rel="noreferrer"
        >
            Portal
        </a>
        &nbsp; which will place the content (<Code>children</Code>) at the
        desired <Code>containerRef</Code>. If no container is not provided, the content will be
        placed at the end of <Code lang="html">&lt;body&gt;</Code>.
    </>
);

export const portalPropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the Portal.',
        defaultValue: 'N/A'
    },
    {
        name: 'containerRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: true,
        description: 'Portal\'s target container.',
        defaultValue: 'N/A'
    }
];

export const darkShadesCode = `const [isVisible, setIsVisible] = useState(false);

<Overlay isVisible={isVisible} />
`;

import { memo, ReactElement } from 'react';
import { portalHeaderDescription, portalPropDetails } from './portalDetails';
import ContentHeader from '../../../common-components/ContentHeader';
import ComponentPropsTable from '../ComponentPropsTable';
import Tabs, { Tab } from '../../../common-components/Tabs';

const PortalUsageExamples = (): ReactElement => (
    <>
        <ContentHeader
            headerText="Portal"
            breadcrumb="Components"
            description={portalHeaderDescription}
        />
        <div className="content-clamp">
            <Tabs>
                {([
                    <Tab name="Props" key="props">
                        <ComponentPropsTable componentProps={portalPropDetails} />
                    </Tab>
                ])}
            </Tabs>
        </div>
    </>
);

export default memo(PortalUsageExamples);

import { memo, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact } from '@fortawesome/free-brands-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import Badge from '../../../common-components/Badge';
import { badgeWithIconsCode, darkShadesCode, lightShadesCode } from './badgeDetails';
import Code from '../../../common-components/Code';

const BadgeUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getDarkShadesSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('dark-shades');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Dark Shades'),
            builder.getComponentSectionDescription((
                <p>
                    Dark shaded Badges. Note: <Code>&apos;indigo&apos;</Code> is the
                    default <Code>color</Code> prop value,
                    and <Code>&apos;dark&apos;</Code> is
                    the default <Code>shade</Code> prop value.
                </p>
            )),
            builder.getExampleSection([
                <Badge>Indigo</Badge>,
                <Badge color="green">Green</Badge>,
                <Badge color="blue">Blue</Badge>,
                <Badge color="yellow">Yellow</Badge>,
                <Badge color="red">Red</Badge>,
                <Badge color="neutral">Neutral</Badge>
            ]),
            builder.getCodeSection(darkShadesCode)
        ], true);
    };

    const getLightColorSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('light-shades');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Light Shades'),
            builder.getComponentSectionDescription((
                <p>
                    Light shaded Badges.
                </p>
            )),
            builder.getExampleSection([
                <Badge shade="light">Indigo</Badge>,
                <Badge shade="light" color="green">Green</Badge>,
                <Badge shade="light" color="blue">Blue</Badge>,
                <Badge shade="light" color="yellow">Yellow</Badge>,
                <Badge shade="light" color="red">Red</Badge>,
                <Badge shade="light" color="neutral">Neutral</Badge>
            ]),
            builder.getCodeSection(lightShadesCode)
        ]);
    };

    const getBadgeWithIconSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('badge-with-icon');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Badge with Icon'),
            builder.getComponentSectionDescription((
                <p>
                    Since the content of a Badge is a <Code>ReactNode</Code>, it can be a string or
                    a JSX element that may contain icons.
                </p>
            )),
            builder.getExampleSection([
                <Badge color="neutral" shade="light">
                    <FontAwesomeIcon icon={faReact} />&nbsp;React
                </Badge>,
                <Badge color="blue">
                    <FontAwesomeIcon icon={faComment} />
                    &nbsp;6
                </Badge>
            ]),
            builder.getCodeSection(badgeWithIconsCode)
        ]);
    };

    return (
        <>
            {getDarkShadesSection()}
            {getLightColorSection()}
            {getBadgeWithIconSection()}
        </>
    );
};
export default memo(BadgeUsageExamples);

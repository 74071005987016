export const commonHooksHeaderDescription = `While not technically "components", these hooks are
included here since they provide some useful functionality that can be used within any React
components.`;

export const useMountEffectCode = `useMountEffect(() => {
    doSomethingOnMount();
});

useMountEffect(() => () => {
    doSomethingOnUnmount();
});
`;

export const useFirstRenderCode = 'const isFirstRender = useFirstRender();';

export const usePreviousCode = 'const previousValue = usePrevious(value);';

export const useCompareCode = 'const hasValueChanged = useCompare(value);';

export const useDeepCompareCode = 'const hasValueChanged = useDeepCompare(value);';

export const useKeyDownCode = `const buttonRef = useRef<HTMLButtonElement>();
// Enter key when button is focused
useKeyDown(() => handleUpArrow(), ['Enter', 'NumpadEnter'], true, buttonRef);

// Escape key when state.isOnDom is true
useKeyDown(() => closeOptions(), ['Escape'], state.isOnDom);
`;

export const useClickOutsideCode = `const drawerRef = useRef<HTMLDivElement>();
useClickOutside(() => state.setIsOpen(false), [drawerRef], state.isOpen);
`;

export const useResizeCode = 'useResize(() => resetPosition(), true);';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCommentDots, faLayerGroup, faPuzzlePiece, faClock
} from '@fortawesome/free-solid-svg-icons';
import { SideMenuOption } from '../common-components/SideMenu';

const SIDE_MENU_OPTIONS: SideMenuOption[] = [
    {
        type: 'option',
        id: 'introduction',
        icon: <FontAwesomeIcon icon={faCommentDots} />,
        label: 'Introduction',
        linkTo: '/'
    },
    {
        type: 'group',
        id: 'foundations',
        icon: <FontAwesomeIcon icon={faLayerGroup} />,
        label: 'Foundations',
        subOptions: [
            {
                type: 'option',
                id: 'foundations-colors',
                label: 'Colors',
                linkTo: '/foundations/colors'
            }
        ]
    },
    {
        type: 'group',
        id: 'components',
        icon: <FontAwesomeIcon icon={faPuzzlePiece} />,
        label: 'Components',
        subOptions: [
            {
                type: 'option',
                id: 'badge',
                label: 'Badge',
                linkTo: './components/badge'
            },
            {
                type: 'option',
                id: 'button',
                label: 'Button',
                linkTo: './components/button'
            },
            {
                type: 'option',
                id: 'code',
                label: 'Code',
                linkTo: './components/code'
            },
            {
                type: 'option',
                id: 'components-collapsible',
                label: 'Collapsible',
                linkTo: '/components/collapsible'
            },
            {
                type: 'option',
                id: 'components-hooks',
                label: 'Common Hooks',
                linkTo: '/components/common-hooks'
            },
            {
                type: 'option',
                id: 'components-content-header',
                label: 'Content Header',
                linkTo: '/components/content-header'
            },
            {
                type: 'option',
                id: 'components-delayed-portal',
                label: 'Delayed Portal',
                linkTo: '/components/delayed-portal'
            },
            {
                type: 'option',
                id: 'components-drawer',
                label: 'Drawer',
                linkTo: '/components/drawer'
            },
            {
                type: 'option',
                id: 'components-focus-guard',
                label: 'Focus Guard',
                linkTo: '/components/focus-guard'
            },
            {
                type: 'option',
                id: 'components-overlay',
                label: 'Overlay',
                linkTo: '/components/overlay'
            },
            {
                type: 'option',
                id: 'components-portal',
                label: 'Portal',
                linkTo: '/components/portal'
            },
            {
                type: 'option',
                id: 'components-select',
                label: 'Select',
                linkTo: '/components/select'
            },
            {
                type: 'option',
                id: 'components-side-menu',
                label: 'Side Menu',
                linkTo: '/components/side-menu'
            },
            {
                type: 'option',
                id: 'components-switch',
                label: 'Switch',
                linkTo: '/components/switch'
            },
            {
                type: 'option',
                id: 'components-table',
                label: 'Table',
                linkTo: '/components/table'
            },
            {
                type: 'option',
                id: 'components-tabs',
                label: 'Tabs',
                linkTo: '/components/tabs'
            }
        ]
    },
    {
        type: 'option',
        id: 'future-features',
        icon: <FontAwesomeIcon icon={faClock} />,
        label: 'Future Features',
        linkTo: '/future-features'
    }
];

export default SIDE_MENU_OPTIONS;

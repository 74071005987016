import { memo, ReactElement, useState } from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import { darkShadesCode } from './overlayDetails';
import { useKeyDown } from '../../../common-components/commonHooks';
import Button from '../../../common-components/Button';
import Overlay from '../../../common-components/Overlay';

const OverlayUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const [isVisible, setIsVisible] = useState(false);

    useKeyDown(() => setIsVisible(false), ['Escape'], isVisible);

    const getExampleSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('example');
        return builder.getComponentSection([
            builder.getComponentSectionDescription((
                <p>
                    Example of the Overlay. The visible state is stored in a parent component and
                    passed down to the Overlay. Use the &quot;Escape&quot; key in this example to
                    close the Overlay.
                </p>
            )),
            builder.getExampleSection([
                builder.getExampleTool([
                    <Button onClick={() => setIsVisible(true)}>
                        Open Overlay
                    </Button>
                ]),
                <Overlay isVisible={isVisible} />
            ]),
            builder.getCodeSection(darkShadesCode)
        ], true);
    };

    return (
        <>
            {getExampleSection()}
        </>
    );
};
export default memo(OverlayUsageExamples);

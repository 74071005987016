import { Link } from 'react-router-dom';
import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const sideMenuHeaderDescription = (
    <>
        Sliding left-side menu. Created using
        the <Link to="/components/drawer">Drawer</Link> component.
    </>
);

export const sideMenuPropDetails: ComponentPropDetail[] = [
    {
        name: 'menuOptions',
        types: ['SideMenuOption[]'],
        isOptional: false,
        description: 'List of menu options to be displayed.',
        defaultValue: 'N/A'
    },
    {
        name: 'isOpen',
        types: ['boolean'],
        isOptional: false,
        description: 'Whether the menu should currently be open.',
        defaultValue: 'N/A'
    },
    {
        name: 'menuButtonRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: false,
        description: `Reference to the element which toggles open the menu. Focus will be returned
        to this element when the menu is closed via keyboard event.`,
        defaultValue: 'N/A'
    },
    {
        name: 'containerRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: true,
        description: (
            <>
                The container which holds the menu&apos;s drawer. Since this component is within
                a <Link to="/components/delayed-portal">DelayedPortal</Link>, if this is not
                provided the content will be placed at the end
                of <Code lang="html">&lt;body&gt;</Code> (but it will still appear fixed on the
                page).
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'onClose',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Emits when the side menu closes. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects.
            </>
        ),
        defaultValue: 'N/A'
    }
];

export const menuOptionGroupDetails: ComponentPropDetail[] = [
    {
        name: 'type',
        types: ['\'group\''],
        isOptional: false,
        description: (
            <>
                Required to differentiate this from <Code>MenuOption</Code>. Should always be
                set to <Code>&apos;group&apos;</Code>.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'label',
        types: ['string'],
        isOptional: false,
        description: 'Text shown for this group.',
        defaultValue: 'N/A'
    },
    {
        name: 'id',
        types: ['string'],
        isOptional: false,
        description: 'ID of the group. Used for unique keys and button IDs.',
        defaultValue: 'N/A'
    },
    {
        name: 'subOptions',
        types: ['MenuOption[]'],
        isOptional: false,
        description: 'Collection of child menu options for this group.',
        defaultValue: 'N/A'
    },
    {
        name: 'icon',
        types: ['ReactElement'],
        isOptional: true,
        description: 'Icon that will appear before the label.',
        defaultValue: 'N/A'
    }
];

export const menuOptionDetails: ComponentPropDetail[] = [
    {
        name: 'type',
        types: ['\'option\''],
        isOptional: false,
        description: (
            <>
                Required to differentiate this from <Code>MenuOptionGroup</Code>. Should always be
                set to <Code>&apos;option&apos;</Code>.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'label',
        types: ['string'],
        isOptional: false,
        description: 'Text shown for this option.',
        defaultValue: 'N/A'
    },
    {
        name: 'id',
        types: ['string'],
        isOptional: false,
        description: 'ID of the option. Used for unique keys and button IDs.',
        defaultValue: 'N/A'
    },
    {
        name: 'linkTo',
        types: ['string'],
        isOptional: false,
        description: 'React Router path to take when this option is clicked.',
        defaultValue: 'N/A'
    },
    {
        name: 'icon',
        types: ['ReactElement'],
        isOptional: true,
        description: 'Icon that will appear before the label.',
        defaultValue: 'N/A'
    }
];

export const exampleMenuOptionsCode = `const menuOptions: SideMenuOption[] = [
    {
        type: 'option',
        label: 'Home',
        id: 'home-option',
        linkTo: '/home'
    },
    {
        type: 'group',
        label: 'Contact',
        id: 'contact-group',
        subOptions: [
            {
                type: 'option',
                label: 'Email',
                id: 'email-option',
                linkTo: '/contact/email'
            },
            {
                type: 'option',
                label: 'Phone',
                id: 'phone-option',
                linkTo: '/contact/email'
            },
            {
                type: 'option',
                label: 'Chat',
                id: 'chat-option',
                linkTo: '/contact/chat'
            }
        ]
    },
    {
        type: 'option',
        label: 'Blog',
        id: 'blog-option',
        linkTo: '/blog'
    },
    {
        type: 'option',
        label: 'About',
        id: 'about-option',
        linkTo: '/about'
    }
];
`;

export const exampleCode = `const [isOpen, setIsOpen] = useState(false);
const menuButtonRef = useRef<HTMLButtonElement>(null);

const handleClose = useCallback(() => setIsOpen(false), []);

<Button
    onClick={() => setIsOpen(true)}
    buttonRef={menuButtonRef}
>
    Open Menu
</Button>

<SideMenu
    menuOptions={menuOptions}
    isOpen={isOpen}
    menuButtonRef={menuButtonRef}
    onClose={handleClose}
/>

`;

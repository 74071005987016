import {
    ReactElement,
    RefObject, useCallback, useRef, useState
} from 'react';
import NavDrawer from './NavDrawer';
import SiteBanner from './SiteBanner';

interface HeaderProps {
    navDrawerContainerRef: RefObject<HTMLDivElement>;
}

const Header = ({ navDrawerContainerRef }: HeaderProps): ReactElement => {
    const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);

    const handleNavDrawerToggle = useCallback(() => (
        setIsNavDrawerOpen((prevState) => !prevState)
    ), []);

    return (
        <header>
            <NavDrawer
                isOpen={isNavDrawerOpen}
                onDrawerToggle={handleNavDrawerToggle}
                containerRef={navDrawerContainerRef}
                menuButtonRef={menuButtonRef}
            />
            <SiteBanner
                onNavDrawerToggle={handleNavDrawerToggle}
                isNavDrawerOpen={isNavDrawerOpen}
                menuButtonRef={menuButtonRef}
            />
        </header>
    );
};

export default Header;

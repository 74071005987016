import { memo, ReactElement } from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import Switch from '../../../common-components/Switch';
import { disabledCode } from './switchDetails';

const SwitchUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getRegularExample = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('regular-example');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Regular'),
            builder.getComponentSectionDescription((
                <p>
                    Standard look for the toggle.
                </p>
            )),
            builder.getExampleSection([
                <Switch switchTitle="Regular Example" />
            ]),
            builder.getCodeSection('<Switch switchTitle="Regular Example" />')
        ], true);
    };

    const getDisabledExample = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('disabled-example');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Disabled'),
            builder.getComponentSectionDescription((
                <p>
                    Disabled look for the toggle when it is default toggled and not toggled.
                </p>
            )),
            builder.getExampleSection([
                <Switch switchTitle="Disabled Example" isDisabled />,
                <Switch switchTitle="Disabled Example" isDefaultToggled isDisabled />
            ]),
            builder.getCodeSection(disabledCode)
        ]);
    };

    return (
        <>
            {getRegularExample()}
            {getDisabledExample()}
        </>
    );
};
export default memo(SwitchUsageExamples);

import { memo, ReactElement } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import { basicHeaderCode, detailedHeaderCode } from './contentHeaderDetails';
import ContentHeader from '../../../common-components/ContentHeader';
import Code from '../../../common-components/Code';

const ContentHeaderUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getBasicHeaderSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('basic-header');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Basic Header'),
            builder.getComponentSectionDescription((
                <p>
                    Basic header without a breadcrumb or description.
                </p>
            )),
            builder.getExampleSection([
                <ContentHeader headerText="Basic Header" />
            ]),
            builder.getCodeSection(basicHeaderCode)
        ], true);
    };

    const getDetailedHeaderSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('detailed-header');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Detailed Header'),
            builder.getComponentSectionDescription((
                <p>
                    Header with breadcrumb and description.
                </p>
            )),
            builder.getExampleSection([
                <ContentHeader
                    headerText="Detailed Header"
                    description={(
                        <>
                            This header has more details along with a breadcrumb and description.
                            Notice that description is a <Code>ReactNode</Code>, which means that
                            it can be a <Code>string</Code> or JSX
                            node <FontAwesomeIcon icon={faCheck} />.
                        </>
                    )}
                    breadcrumb="Header Types"
                />
            ]),
            builder.getCodeSection(detailedHeaderCode)
        ]);
    };

    return (
        <>
            {getBasicHeaderSection()}
            {getDetailedHeaderSection()}
        </>
    );
};
export default memo(ContentHeaderUsageExamples);

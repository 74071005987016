import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const tableHeaderDescription = `Very simple responsive table which changes based on screen
size. Currently, it is used for all of the component info props lists.`;

export const tablePropDetails: ComponentPropDetail[] = [
    {
        name: 'tableDef',
        types: ['TableDef'],
        isOptional: false,
        description: 'The table definition.',
        defaultValue: 'N/A'
    }
];

export const tableDefDetails: ComponentPropDetail[] = [
    {
        name: 'headers',
        types: ['TableHeader[]'],
        isOptional: false,
        description: 'List of table headers.',
        defaultValue: 'N/A'
    },
    {
        name: 'rows',
        types: ['TableRow[]'],
        isOptional: false,
        description: 'List of table rows.',
        defaultValue: 'N/A'
    }
];

export const tableHeaderDetails: ComponentPropDetail[] = [
    {
        name: 'id',
        types: ['string'],
        isOptional: false,
        description: 'Unique ID of this header. Used for relating table cells to headers.',
        defaultValue: 'N/A'
    },
    {
        name: 'name',
        types: ['string'],
        isOptional: false,
        description: 'Display name of the header.',
        defaultValue: 'N/A'
    }
];

export const tableRowDetails: ComponentPropDetail[] = [
    {
        name: 'cells',
        types: ['TableCell[]'],
        isOptional: false,
        description: 'List of cells within this row.',
        defaultValue: 'N/A'
    }
];

export const tableCellDetails: ComponentPropDetail[] = [
    {
        name: 'headerId',
        types: ['string'],
        isOptional: false,
        description: 'ID of the header related to this cell.',
        defaultValue: 'N/A'
    },
    {
        name: 'content',
        types: ['ReactNode'],
        isOptional: false,
        description: (
            <>
                Cell content. Since this is a <Code>ReactNode</Code>, it can be just
                a <Code>stirng</Code> or a JSX element.
            </>
        ),
        defaultValue: 'N/A'
    }
];

export const tableCode = `<Table tableDef={tableDef} />
`;

export const tableDefCode = `const tableDef: TableDef = {
    headers: [
        {
            id: 'make',
            name: 'Make'
        },
        {
            id: 'model',
            name: 'Model'
        },
        {
            id: 'year',
            name: 'Year'
        },
        {
            id: 'mileage',
            name: 'Mileage'
        },
        {
            id: 'price',
            name: 'Price'
        }
    ],
    rows: [
        {
            cells: [
                {
                    headerId: 'make',
                    content: 'Honda'
                },
                {
                    headerId: 'model',
                    content: 'Accord'
                },
                {
                    headerId: 'year',
                    content: '2009'
                },
                {
                    headerId: 'mileage',
                    content: '100,000'
                },
                {
                    headerId: 'price',
                    content: (
                        <>
                            <Badge color="neutral">Used</Badge> $8,000
                        </>
                    )
                }
            ]
        },
        {
            cells: [
                {
                    headerId: 'make',
                    content: 'Toyota'
                },
                {
                    headerId: 'model',
                    content: 'Camry'
                },
                {
                    headerId: 'year',
                    content: '2012'
                },
                {
                    headerId: 'mileage',
                    content: '75,000'
                },
                {
                    headerId: 'price',
                    content: (
                        <>
                            <Badge color="neutral">Used</Badge> $15,000
                        </>
                    )
                }
            ]
        },
        {
            cells: [
                {
                    headerId: 'make',
                    content: 'Ford'
                },
                {
                    headerId: 'model',
                    content: 'Fusion'
                },
                {
                    headerId: 'year',
                    content: '2022'
                },
                {
                    headerId: 'mileage',
                    content: '5,000'
                },
                {
                    headerId: 'price',
                    content: '$25,000'
                }
            ]
        },
        {
            cells: [
                {
                    headerId: 'make',
                    content: 'Hyundai'
                },
                {
                    headerId: 'model',
                    content: 'Elantra'
                },
                {
                    headerId: 'year',
                    content: '2020'
                },
                {
                    headerId: 'mileage',
                    content: '10,000'
                },
                {
                    headerId: 'price',
                    content: '$19,000'
                }
            ]
        }
    ]
};
`;

import { ReactElement, ReactNode, useState } from 'react';
import { useMountEffect, useResize } from './commonHooks';
import TableSmall from './internal/TableSmall';
import TableMedium from './internal/TableMedium';
import TableLarge from './internal/TableLarge';

export interface TableHeader {
    id: string;
    name: string;
}

export interface TableCell {
    headerId: string;
    content: ReactNode;
}

export interface TableRow {
    cells: TableCell[];
}

export interface TableDef {
    headers: TableHeader[];
    rows: TableRow[];
}

interface TableProps {
    tableDef: TableDef;
}

const Table = ({ tableDef }: TableProps): ReactElement => {
    const [size, setSize] = useState<'small'|'medium'|'large'>('small');

    const determineTableSize = (): void => {
        const screenSize = window.innerWidth;
        if (screenSize >= 1024) {
            setSize('large');
        } else if (screenSize >= 768) {
            setSize('medium');
        } else {
            setSize('small');
        }
    };

    useMountEffect(() => determineTableSize());

    useResize(() => determineTableSize(), true);

    if (size === 'large') {
        return <TableLarge tableDef={tableDef} />;
    }
    if (size === 'medium') {
        return <TableMedium tableDef={tableDef} />;
    }
    return <TableSmall tableDef={tableDef} />;
};
export default Table;

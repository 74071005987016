import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const buttonHeaderDescription = `Stylized buttons with a variety of color variants based on
intent.`;

export const buttonPropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the Button.',
        defaultValue: 'N/A'
    },
    {
        name: 'type',
        types: ['\'submit\'', '\'reset\'', '\'button\''],
        isOptional: true,
        description: 'Type of the button HTML element.',
        defaultValue: '\'button\''
    },
    {
        name: 'variant',
        types: [
            '\'primary\'',
            '\'success\'',
            '\'info\'',
            '\'warning\'',
            '\'danger\'',
            '\'secondary\'',
            '\'minimal\''
        ],
        isOptional: true,
        description: (
            <>
                Determines the color of the button. <Code>&apos;primary&apos;</Code>,
                &nbsp;<Code>&apos;success&apos;</Code>, <Code>&apos;info&apos;</Code>,
                &nbsp;<Code>&apos;warning&apos;</Code>, and <Code>&apos;danger&apos;</Code> variants
                are filled in and colored appropriately. <Code>&apos;secondary&apos;</Code> is just
                an outlined button. Finally, <Code>&apos;minimal&apos;</Code> has no border or
                background.
            </>
        ),
        defaultValue: '\'primary\''
    },
    {
        name: 'shade',
        types: ['\'dark\'', '\'light\''],
        isOptional: true,
        description: (
            <>
                Determines the shade of the button. Only used when <Code>variant</Code> is one
                of <Code>&apos;primary&apos;</Code>, <Code>&apos;success&apos;</Code>,
                &nbsp;<Code>&apos;info&apos;</Code>, <Code>&apos;warning&apos;</Code>,
                or <Code>&apos;danger&apos;</Code>.
            </>
        ),
        defaultValue: '\'dark\''
    },
    {
        name: 'isLoading',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Determines whether the button is in a loading state. When <Code>true</Code>, the
                button becomes disabled and the content is overlayed with a loading spinner.
            </>
        ),
        defaultValue: 'false'
    },
    {
        name: 'isDisabled',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the button is disabled.',
        defaultValue: 'false'
    },
    {
        name: 'isHidden',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Whether the button is hidden from the user&apos;s view. When <Code>true</Code>, the
                button&apos;s <Code>tabIndex</Code> is set to <Code>-1</Code> to prevent keyboard
                navigation.
            </>
        ),
        defaultValue: 'false'
    },
    {
        name: 'onClick',
        types: ['() => void'],
        isOptional: true,
        description: 'Event fired when the button is clicked.',
        defaultValue: '() => undefined'
    },
    {
        name: 'buttonRef',
        types: ['RefObject<HTMLButtonElement>'],
        isOptional: true,
        description: 'Ref to the button element.',
        defaultValue: 'undefined'
    }
];

export const darkVariantsCode = `<Button>Primary</Button>
<Button variant="success">Success</Button>
<Button variant="info">Info</Button>
<Button variant="warning">Warning</Button>
<Button variant="danger">Danger</Button>
<Button variant="secondary">Secondary</Button>
<Button variant="minimal">Minimal</Button>
`;

export const lightVariantsCode = `<Button shade="light">Primary</Button>
<Button variant="success" shade="light">Success</Button>
<Button variant="info" shade="light">Info</Button>
<Button variant="warning" shade="light">Warning</Button>
<Button variant="danger" shade="light">Danger</Button>
<Button variant="secondary" shade="light">Secondary</Button>
<Button variant="minimal" shade="light">Minimal</Button>
`;

export const disabledCode = `<Button isDisabled>Primary</Button>
<Button variant="success" shade="light" isDisabled>Success</Button>
<Button variant="info" isDisabled>Info</Button>
<Button variant="warning" shade="light" isDisabled>Warning</Button>
<Button variant="danger" isDisabled>Danger</Button>
<Button variant="secondary" shade="light" isDisabled>Secondary</Button>
<Button variant="minimal" isDisabled>Minimal</Button>
`;

export const loadingCode = `<Button isLoading>Primary</Button>
<Button variant="success" shade="light" isLoading>Success</Button>
<Button variant="warning" isLoading>Warning</Button>
<Button variant="danger" shade="light" isLoading>Danger</Button>
<Button variant="secondary" isLoading>Secondary</Button>
<Button variant="minimal" shade="light" isLoading>Minimal</Button>
`;

export const buttonWithIconCode = `<Button>
    <FontAwesomeIcon icon={faComment} />
    &nbsp;Comment
</Button>
<Button variant="success">
    <FontAwesomeIcon icon={faThumbsUp} />
</Button>
<Button variant="danger">
    <FontAwesomeIcon icon={faThumbsDown} />
</Button>
`;

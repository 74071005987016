import {
    EffectCallback, MutableRefObject,
    RefObject, useEffect, useRef
} from 'react';

/**
 * Shorthand for useEffect(() => { effectCallback() }, []). This effect function is called only once
 * on mount. Just like the original effect, the effectCallback can return a cleanup function which
 * will be called on unmount of the component.
 *
 * @param effectCallback Callback which runs on mount.
 */
export const useMountEffect = (effectCallback: EffectCallback): void => {
    // Suppressing the linting rule for mount effect only
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effectCallback, []);
};

/**
 * Determines whether this is the first render.
 *
 * @return *true* if this is the first render.
 */
export const useFirstRender = (): boolean => {
    const firstRenderRef = useRef(true);
    const isFirstRender = firstRenderRef.current;
    firstRenderRef.current = false;
    return isFirstRender;
};

/**
 * Stores a ref of the value as it was in the previous render, updates that value
 * on change, and returns a ref of the previous value.
 *
 * @param value Value to be stored.
 * @return Ref of the previous value.
 */
export const usePrevious = <T>(value: T): MutableRefObject<T | undefined> => {
    const prevValueRef = useRef<T>();
    useEffect(() => { prevValueRef.current = value; }, [value]);
    return prevValueRef;
};

/**
 * Shallowly compares the value as it is in the current render to the value as it was in the
 * previous render. Utilizes the useFirstRender hook for preventing the compare on the initial
 * render and usePrevious hook for actual comparing of values. Typically used for comparing
 * primitives.
 *
 * @param value The current value.
 * @return *true* if current and previous values are not equal and this is not the first render.
 */
export const useCompare = <T>(value: T): boolean => {
    const prevValue = usePrevious(value).current;
    if (!useFirstRender()) {
        return prevValue !== value;
    }
    return false;
};

/**
 * Deeply compares the value as it is in the current render to the value as it was in the previous
 * render. Utilizes the useFirstRender hook for preventing the compare on the initial
 * render and usePrevious hook for actual comparing of values. Typically used for comparing Objects.
 *
 * @param value The current value.
 * @return *true* if current and previous values are not equal and this is not the first render.
 */
export const useDeepCompare = <T>(value: T): boolean => {
    const prevValue = usePrevious(value).current;
    if (!useFirstRender()) {
        return JSON.stringify(prevValue) !== JSON.stringify(value);
    }
    return false;
};

/**
 * Adds a keydown event listener.
 *
 * @param callback Called when the event fires.
 * @param keyCodes List of key codes (e.g. "Escape") which will be listened for.
 * @param isActive Whether or not the listener is active. If false, the event listener will be
 * removed.
 * @param targetRef *Optional*. Target which the event listener is attached to. If this is not
 * provided, then the event listener will be attached to window.
 */
export const useKeyDown = (
    callback: () => void,
    keyCodes: string[],
    isActive: boolean,
    targetRef?: RefObject<HTMLElement>
): void => (
    useEffect(() => {
        const handler = (event: Event): void => {
            if (keyCodes.includes((event as KeyboardEvent).code)) {
                callback();
                event.preventDefault();
            }
        };

        let target: HTMLElement|Window = window;
        if (targetRef && targetRef.current) {
            target = targetRef.current;
        }

        if (isActive) {
            target.addEventListener('keydown', handler);
        }
        // Cleanup event
        return () => target.removeEventListener('keydown', handler);
    }, [isActive, callback, targetRef, keyCodes])
);

/**
 * Determines if a user clicks outside a given list of targets, then calls the callback if so.
 *
 * @param callback Called when the user clicks outside.
 * @param insideRefs List of refs which are considered "inside" and will not trigger the callback.
 * @param isActive Whether or not the listener is active. If false, the event listener will be
 * removed.
 */
export const useClickOutside = (
    callback: () => void,
    insideRefs: RefObject<HTMLElement>[],
    isActive: boolean
): void => (
    useEffect(() => {
        const handler = (event: Event): void => {
            const isInside = insideRefs.some((ref: RefObject<HTMLElement>) => (
                ref.current && ref.current.contains((event as MouseEvent).target as Node)
            ));
            if (!isInside) {
                callback();
            }
        };

        if (isActive) {
            window.addEventListener('mousedown', handler);
        }

        // Cleanup event
        return () => window.removeEventListener('mousedown', handler);
    }, [isActive, callback, insideRefs])
);

/**
 * Adds a listener to handle window resize.
 *
 * @param callback Called when the window is resized.
 * @param isActive Whether or not the listener is active. If false, the event listener will be
 * removed.
 */
export const useResize = (callback: () => void, isActive: boolean): void => (
    useEffect(() => {
        if (isActive) {
            window.addEventListener('resize', callback);
        }

        // Cleanup event
        return () => window.removeEventListener('resize', callback);
    }, [isActive, callback])
);

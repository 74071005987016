import {
    Children, cloneElement,
    memo,
    ReactElement,
    ReactNode,
    useEffect,
    useState
} from 'react';
import styles from './Tabs.module.css';
import { useMountEffect } from './commonHooks';

interface TabProps {
    name: string;
    children: ReactNode;
    isDisabled?: boolean;
    isActive?: boolean;
}

export const Tab = memo(({
    name,
    children,
    isDisabled = false,
    isActive = false
}: TabProps) => {
    const isShown = (): boolean => isActive && !isDisabled;

    return (
        <div
            id={`${name}-tab-panel`}
            className={isShown() ? undefined : styles['tab-panel-inactive']}
            aria-hidden={isShown() ? undefined : true}
            key={`${name}-tab-panel`}
        >
            {children}
        </div>
    );
});

interface TabsProps {
    children: ReactElement[];
    selected?: string;
    defaultSelected?: string;
    onChange?: (newTab: string) => void;
}

const Tabs = ({
    children,
    defaultSelected = '',
    selected = '',
    onChange = () => undefined
}: TabsProps): ReactElement => {
    const [selectedState, setSelectedState] = useState(defaultSelected);

    useMountEffect(() => {
        if (defaultSelected === '') {
            setSelectedState(children[0].props.name);
        }
    });

    useEffect(() => {
        if (selected !== '') {
            setSelectedState(selected);
        }
    }, [selected]);

    const handleTabButtonClick = (name: string): void => {
        if (selectedState !== name) {
            setSelectedState(name);
            onChange(name);
        }
    };

    const getTabBodies = (): ReactElement[] => (
        Children.map(children, (child) => (
            cloneElement(child, { isActive: selectedState === child.props.name })
        ))
    );

    const getButtonClass = (name: string): string => {
        let cssClass = styles['tab-button'];
        if (selectedState === name) {
            cssClass += ` ${styles['tab-button-active']}`;
        }
        return cssClass;
    };

    return (
        <>
            <div role="tablist" className={styles['tab-list']}>
                {children.map((child) => {
                    const { name } = child.props;
                    const { isDisabled } = child.props;
                    return (
                        <button
                            type="button"
                            className={getButtonClass(name)}
                            onClick={() => handleTabButtonClick(name)}
                            key={`${name}-tab-button`}
                            role="tab"
                            aria-controls={`${name}-tab-panel`}
                            aria-selected={selectedState === name}
                            tabIndex={selectedState === name ? -1 : undefined}
                            disabled={isDisabled}
                        >
                            {name}
                        </button>
                    );
                })}
            </div>
            {getTabBodies()}
        </>
    );
};
export default memo(Tabs);

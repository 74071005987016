import { Link } from 'react-router-dom';
import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';
import { TableDef } from '../../../common-components/Table';

export const delayedPortalHeaderDescription = (
    <>
        Functions similar to the <Link to="/components/portal">Portal</Link> component but with a
        specified delay. Useful for elements that are animated and are being added to the DOM with a
        portal such as the <Link to="/components/drawer">Drawer</Link> component.
    </>
);

export const delayedPortalEventsTableDef: TableDef = {
    headers: [
        {
            id: 'event',
            name: 'Event'
        },
        {
            id: 'description',
            name: 'Description'
        },
        {
            id: 'usage',
            name: 'Example Usage'
        }
    ],
    rows: [
        {
            cells: [
                {
                    headerId: 'event',
                    content: <Code>onIsOnDom</Code>
                },
                {
                    headerId: 'description',
                    content: (
                        <>
                            Emitted immediately when <Code>isActive = true</Code>. Tells the parent
                            when content is placed on the DOM.
                        </>
                    )
                },
                {
                    headerId: 'usage',
                    content: 'When emits, set the position for a popover div.'
                }
            ]
        },
        {
            cells: [
                {
                    headerId: 'event',
                    content: <Code>onNotIsOnDom</Code>
                },
                {
                    headerId: 'description',
                    content: (
                        <>
                            Emitted when <Code>isActive = false</Code> then
                            after <Code>closeDelay</Code> has elapsed. Tells the parent content is
                            taken off the DOM.
                        </>
                    )
                },
                {
                    headerId: 'usage',
                    content: 'When emits, do some cleanup, if necessary.'
                }
            ]
        },
        {
            cells: [
                {
                    headerId: 'event',
                    content: <Code>onIsVisible</Code>
                },
                {
                    headerId: 'description',
                    content: (
                        <>
                            Emitted when <Code>isActive = true</Code> then
                            after <Code>openDelay</Code> has elapsed. Tells the parent when the
                            content can be made visible.
                        </>
                    )
                },
                {
                    headerId: 'usage',
                    content: (
                        <>
                            When emits, set the css class for
                            the <Code lang="css">opacity: 1</Code> animation/transition.
                        </>
                    )
                }
            ]
        },
        {
            cells: [
                {
                    headerId: 'event',
                    content: <Code>onNotIsVisible</Code>
                },
                {
                    headerId: 'description',
                    content: (
                        <>
                            Emitted immediately when <Code>isActive = false</Code>.  Tells the
                            parent when the content should be no longer visible.
                        </>
                    )
                },
                {
                    headerId: 'usage',
                    content: (
                        <>
                            When emits, set the css class for
                            the <Code lang="css">opacity: 0</Code> animation/transition.
                        </>
                    )
                }
            ]
        }
    ]
};

export const delayedPortalPropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the portal.',
        defaultValue: 'N/A'
    },
    {
        name: 'isActive',
        types: ['boolean'],
        isOptional: false,
        description: 'Whether the portal is currently active.',
        defaultValue: 'N/A'
    },
    {
        name: 'containerRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: true,
        description: (
            <>
                Target container for the portal. If not provided, the content will be placed at the
                end of <Code lang="html">&lt;body&gt;</Code>.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'openDelay',
        types: ['number'],
        isOptional: true,
        description: 'Delay for opening the portal (in milliseconds).',
        defaultValue: '10'
    },
    {
        name: 'closeDelay',
        types: ['number'],
        isOptional: true,
        description: 'Delay for closing the portal (in milliseconds).',
        defaultValue: '10'
    },
    {
        name: 'onIsOnDom',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Emits when content is placed on the DOM. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects.
            </>
        ),
        defaultValue: '() => undefined'
    },
    {
        name: 'onNotIsOnDom',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Emits when content is taken off the DOM. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects.
            </>
        ),
        defaultValue: '() => undefined'
    },
    {
        name: 'onIsVisible',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Emits an event when the content can be made visible. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects.
            </>
        ),
        defaultValue: '() => undefined'
    },
    {
        name: 'onNotIsVisible',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Emits an event when the content should be no longer visible. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects.
            </>
        ),
        defaultValue: '() => undefined'
    }
];

export const delayedPortalTsxCode = `const [isOnDom, setIsOnDom] = useState(false);
const [isVisible, setIsVisible] = useState(false);
const [isActive, setIsActive] = useState(false);
const targetRef = useRef<HTMLDivElement>(null);

const getPortalClass = (): string => (
    \`\${styles['portal-content']} \${isVisible ? styles['portal-content-visible'] : ''}\`
);

const handleIsOnDom = useCallback(() => (setIsOnDom(true)), []);
const handleNotIsOnDom = useCallback(() => (setIsOnDom(false)), []);
const handleIsVisible = useCallback(() => (setIsVisible(true)), []);
const handleNotIsVisible = useCallback(() => (setIsVisible(false)), []);

// The target div can be anywhere
<div id="example-target-div" ref={targetRef} />

<DelayedPortal
    isActive={isActive}
    containerRef={targetRef}
    onIsOnDom={handleIsOnDom}
    onNotIsOnDom={handleNotIsOnDom}
    onIsVisible={handleIsVisible}
    onNotIsVisible={handleNotIsVisible}
    openDelay={5000}
    closeDelay={5000}
>
    <div className={getPortalClass()}>
        <FontAwesomeIcon icon={faCircleInfo} className={styles['portal-icon']} />
        This is the content of the Delayed Portal.
    </div>
</DelayedPortal>
`;

export const delayedPortalCssCode = `.portal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    color: transparent;
    background-color: transparent;
    opacity: 0;
    transition-property: color, background-color, opacity;
    transition-duration: 5s;
    border-radius: 5px;
}

.portal-content-visible {
    color: var(--raisin-black);
    background-color: var(--beau-blue);
    opacity: 1;
}

.portal-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
}
`;

import {
    useRef, memo, useEffect, ReactElement
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './Option.module.css';

export interface OptionData {
    type: 'option';
    label: string;
    value: string;
    preIcon?: ReactElement;
    postIcon?: ReactElement;
}

interface OptionProps {
    option: OptionData;
    isFocused: boolean;
    isSelected: boolean;
    isVisible: boolean;
    onClick: (option: OptionData) => void;
    isInGroup?: boolean;
}

const Option = ({
    option,
    isFocused,
    isSelected,
    isVisible,
    onClick,
    isInGroup = false
}: OptionProps): ReactElement => {
    const optionRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        if (isVisible && isFocused) {
            optionRef.current?.scrollIntoView({ block: 'nearest', inline: 'start' });
        }
    }, [isVisible, isFocused]);

    const getSelectedIcon = (): ReactElement|null => {
        if (isSelected) {
            return <FontAwesomeIcon className={styles['selected-icon']} icon={faCheck} />;
        }
        return null;
    };

    const getOptionClass = (): string => {
        let cssClass = styles.option;

        if (isSelected) {
            cssClass += ` ${styles['option-selected']}`;
        }
        if (isFocused) {
            cssClass += ` ${styles['option-focused']}`;
        }
        if (isInGroup) {
            cssClass += ` ${styles['option-grouped']}`;
        }

        return cssClass;
    };

    return (
        <li
            className={getOptionClass()}
            onClick={() => onClick(option)}
            onKeyDown={undefined}
            role="option"
            aria-selected={isSelected ? true : undefined}
            ref={optionRef}
        >
            {option.preIcon && (
                <span className={styles['option-label-content']}>{option.preIcon}</span>
            )}
            <span className={styles['option-label-content']}>{option.label}</span>
            {option.postIcon && (
                <span className={styles['option-label-content']}>{option.postIcon}</span>
            )}
            {getSelectedIcon()}
        </li>
    );
};

export default memo(Option);

import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const collapsibleHeaderDescription = `Collapsible area component with options to be
controlled internally or by the parent. Width will be set to 100% of the parent width. Content of
the Collapsible is passed as a React node, and can be anything. All styling of the content is left
to the content itself.`;

export const collapsiblePropDetails: ComponentPropDetail[] = [
    {
        name: 'toggleLabel',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Label contents of the toggle button.',
        defaultValue: 'N/A'
    },
    {
        name: 'title',
        types: ['string'],
        isOptional: false,
        description: 'Title of the toggle button.',
        defaultValue: 'N/A'
    },
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the Collapsible.',
        defaultValue: 'N/A'
    },
    {
        name: 'collapsibleId',
        types: ['string'],
        isOptional: false,
        description: `Unique ID of the Collapsible area. Used primarily for aria labeling
        (accessibility).`,
        defaultValue: 'N/A'
    },
    {
        name: 'isDefaultOpen',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the Collapsible is open on initial load.',
        defaultValue: 'false'
    },
    {
        name: 'isParentControlled',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Whether the parent component is controlling the state of the Collapsible. If this
                is <Code>true</Code>, the component will no longer be internally controlled and is
                instead controlled by the <Code>isOpen</Code> prop.
            </>
        ),
        defaultValue: 'false'
    },
    {
        name: 'isOpen',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Whether the Collapsible is open when <Code>isParentControlled = true</Code> This
                prop will do nothing if <Code>isParentControlled = false</Code>.
            </>
        ),
        defaultValue: 'false'
    },
    {
        name: 'isDisabled',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the Collapsible toggle button is disabled.',
        defaultValue: 'false'
    },
    {
        name: 'onOpen',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Called when the Collapsible is toggled open internally. Does nothing
                when <Code>isParentControlled = false</Code>.
            </>
        ),
        defaultValue: 'undefined'
    },
    {
        name: 'onClose',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Called when the Collapsible is toggled closed internally. Does nothing
                when <Code>isParentControlled = false</Code>.
            </>
        ),
        defaultValue: 'undefined'
    },
    {
        name: 'isHidden',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Whether the Collapsible is hidden or obscured from the user in some manner. Used for
                setting <Code>tabIndex</Code> so the hidden element cannot be tabbed.
            </>
        ),
        defaultValue: 'false'
    },
    {
        name: 'isStacked',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                Whether the Collapsible is stacked next to other Collapsibles. If <Code>true</Code>,
                the top margin of the Collapsible toggle button with be pushed up by 1px so that the
                border between this Collapsible and the one above it merge into 1 shared border.
            </>
        ),
        defaultValue: 'false'
    }
];

export const internallyControlledCode = `<Collapsible
    collapsibleId="internally-controlled-example"
    title="Internally Controlled"
    toggleLabel="Internally Controlled"
>
    <>
        <p>Content can be anything in the form of a React child node.</p>
        <img src="/galaxy.jpg" alt="Galaxy" />
        <p>...and the Collapsible will expand to show all of the content.</p>
    </>
</Collapsible>`;

export const parentControlledCode = `const [isParentControlled, setIsParentControlled] = useState(true);
const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

<Collapsible
    collapsibleId="parent-controlled-example"
    title={\`$\{isParentControlled ? 'Parent' : 'Internally'} Controlled\`}
    toggleLabel={\`$\{isParentControlled ? 'Parent' : 'Internally'} Controlled\`}
    isParentControlled={isParentControlled}
    isOpen={isCollapsibleOpen}
>
    ...
</Collapsible>
`;

export const disabledCode = `<Collapsible
    collapsibleId="disabled-example"
    title="Disabled Example"
    toggleLabel={<span><FontAwesomeIcon icon={faBan} /> Disabled Example</span>}
    isDisabled
>
    Nothing to see here!
</Collapsible>`;

export const asyncCode = `const [isLoading, setIsLoading] = useState(false);
const [isLoaded, setIsLoaded] = useState(false);

const getImage = (): ReactElement|null => {
    if (state.isLoaded) {
        return <img src="/galaxy.jpg" alt="Galaxy" />;
    }
    return null;
};

<Collapsible
    collapsibleId="async-example"
    title="Not Fully Loaded Example"
    toggleLabel="Not Fully Loaded"
    isParentControlled
    isOpen={isLoading || isLoaded}
>
    <>
        <div>Image is loaded under here</div>
        {getImage()}
        <div>Image is loaded before here</div>
    </>
</Collapsible>
<Collapsible
    collapsibleId="async-loaded-example"
    title="Fully Loaded Example"
    toggleLabel="Fully Loaded"
    isParentControlled
    isOpen={isLoaded}
    isStacked
>
    <>
        <div>Image is loaded under here</div>
        {getImage()}
        <div>Image is loaded before here</div>
    </>
</Collapsible>`;

import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import ComponentInfo from './components/ComponentInfo';
import { badgeHeaderDescription, badgePropDetails } from './components/Badge/badgeDetails';
import { buttonHeaderDescription, buttonPropDetails } from './components/Button/buttonDetails';
import { collapsibleHeaderDescription, collapsiblePropDetails } from './components/Collpasible/collapsibleDetails';
import BadgeUsageExamples from './components/Badge/BadgeUsageExamples';
import CollapsibleUsageExamples from './components/Collpasible/CollapsibleUsageExamples';
import ButtonUsageExamples from './components/Button/ButtonUsageExamples';
import { codeHeaderDescription, codePropDetails } from './components/Code/codeDetails';
import CodeUsageExamples from './components/Code/CodeUsageExamples';
import CommonHooksUsageExamples from './components/CommonHooks/CommonHooksUsageExamples';
import ContentHeaderUsageExamples from './components/ContentHeader/ContentHeaderUsageExamples';
import { contentHeaderPropDetails, contentHeaderHeaderDescription } from './components/ContentHeader/contentHeaderDetails';
import DelayedPortalUsageExamples from './components/DelayedPortal/DelayedPortalUsageExamples';
import { drawerHeaderDescription, drawerPropDetails } from './components/Drawer/drawerDetails';
import DrawerUsageExamples from './components/Drawer/DrawerUsageExamples';
import { focusGuardHeaderDescription, focusGuardPropDetails } from './components/FocusGuard/focusGuardDetails';
import FocusGuardUsageExamples from './components/FocusGuard/FocusGuardUsageExamples';
import { overlayHeaderDescription, overlayPropDetails } from './components/Overlay/overlayDetails';
import OverlayUsageExamples from './components/Overlay/OverlayUsageExamples';
import PortalUsageExamples from './components/Portal/PortalUsageExamples';
import SelectUsageExamples from './components/Select/SelectUsageExamples';
import SideMenuUsageExamples from './components/SideMenu/SideMenuUsageExamples';
import { switchHeaderDescription, switchPropDetails } from './components/Switch/switchDetails';
import SwitchUsageExamples from './components/Switch/SwitchUsageExamples';
import TabsUsageExamples from './components/Tabs/TabsUsageExamples';
import TableUsageExamples from './components/Table/TableUsageExamples';

const COMPONENT_ROUTES: ReactElement[] = [
    <Route
        path="/components/badge"
        element={(
            <ComponentInfo
                headerText="Badge"
                headerDescription={badgeHeaderDescription}
                usageExamples={<BadgeUsageExamples />}
                propDetails={badgePropDetails}
                key="components-info-badge"
            />
        )}
        key="badge-component-link"
    />,
    <Route
        path="/components/button"
        element={(
            <ComponentInfo
                headerText="Button"
                headerDescription={buttonHeaderDescription}
                usageExamples={<ButtonUsageExamples />}
                propDetails={buttonPropDetails}
                key="components-info-button"
            />
        )}
        key="button-component-link"
    />,
    <Route
        path="/components/code"
        element={(
            <ComponentInfo
                headerText="Code"
                headerDescription={codeHeaderDescription}
                usageExamples={<CodeUsageExamples />}
                propDetails={codePropDetails}
                key="components-info-code"
            />
        )}
        key="code-component-link"
    />,
    <Route
        path="/components/collapsible"
        element={(
            <ComponentInfo
                headerText="Collapsible"
                headerDescription={collapsibleHeaderDescription}
                usageExamples={<CollapsibleUsageExamples />}
                propDetails={collapsiblePropDetails}
                key="components-info-collapsible"
            />
        )}
        key="collapsible-component-link"
    />,
    <Route
        path="/components/common-hooks"
        element={<CommonHooksUsageExamples />}
        key="common-hooks-component-link"
    />,
    <Route
        path="/components/content-header"
        element={(
            <ComponentInfo
                headerText="Content Header"
                headerDescription={contentHeaderHeaderDescription}
                usageExamples={<ContentHeaderUsageExamples />}
                propDetails={contentHeaderPropDetails}
                key="components-info-content-header"
            />
        )}
        key="content-header-component-link"
    />,
    <Route
        path="/components/delayed-portal"
        element={(<DelayedPortalUsageExamples />)}
        key="delayed-portal-component-link"
    />,
    <Route
        path="/components/drawer"
        element={(
            <ComponentInfo
                headerText="Drawer"
                headerDescription={drawerHeaderDescription}
                usageExamples={<DrawerUsageExamples />}
                propDetails={drawerPropDetails}
                key="components-info-drawer"
            />
        )}
        key="drawer-component-link"
    />,
    <Route
        path="/components/focus-guard"
        element={(
            <ComponentInfo
                headerText="Focus Guard"
                headerDescription={focusGuardHeaderDescription}
                usageExamples={<FocusGuardUsageExamples />}
                propDetails={focusGuardPropDetails}
                key="components-info-focus-guard"
            />
        )}
        key="focus-guard-component-link"
    />,
    <Route
        path="/components/overlay"
        element={(
            <ComponentInfo
                headerText="Overlay"
                headerDescription={overlayHeaderDescription}
                usageExamples={<OverlayUsageExamples />}
                propDetails={overlayPropDetails}
                key="components-info-overlay"
            />
        )}
        key="overlay-component-link"
    />,
    <Route
        path="/components/portal"
        element={<PortalUsageExamples />}
        key="portal-component-link"
    />,
    <Route
        path="/components/select"
        element={<SelectUsageExamples />}
        key="select-component-link"
    />,
    <Route
        path="/components/side-menu"
        element={<SideMenuUsageExamples />}
        key="side-menu-component-link"
    />,
    <Route
        path="/components/switch"
        element={(
            <ComponentInfo
                headerText="Switch"
                headerDescription={switchHeaderDescription}
                usageExamples={<SwitchUsageExamples />}
                propDetails={switchPropDetails}
                key="components-info-switch"
            />
        )}
        key="switch-component-link"
    />,
    <Route
        path="/components/table"
        element={<TableUsageExamples />}
        key="table-component-link"
    />,
    <Route
        path="/components/tabs"
        element={<TabsUsageExamples />}
        key="tabs-component-link"
    />
];

export default COMPONENT_ROUTES;

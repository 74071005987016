import {
    memo, ReactElement, useEffect
} from 'react';
import Prism from './3rd-party/prism.js';
import './3rd-party/prism.css';

interface CodeProps {
    children: string;
    lang?: string;
    isCodeBlock?: boolean;
    id?: string;
}

const Code = ({
    children,
    lang = 'ts',
    isCodeBlock = false,
    id
}: CodeProps): ReactElement => {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const getCode = (): ReactElement => (
        <code id={id} className={`language-${lang} rainbow-braces`}>
            {children}
        </code>
    );

    const getCodeBlock = (): ReactElement => (
        <pre>{getCode()}</pre>
    );

    if (isCodeBlock) {
        return getCodeBlock();
    }
    return getCode();
};

export default memo(Code);

import { ReactElement } from 'react';
import { TableDef } from '../Table';
import styles from '../Table.module.css';

interface TableMediumProps {
    tableDef: TableDef;
}

const TableMedium = ({ tableDef }: TableMediumProps): ReactElement => (
    <div className={styles['table-container']}>
        {tableDef.rows.map((row, rIndex) => {
            const tableKey = `table-${rIndex}-key`;
            return (
                <table className={styles['table-med']} key={tableKey}>
                    <tbody>
                        {row.cells.map((cell, cIndex) => {
                            const header = tableDef.headers[cIndex];
                            const headerId = `${header.id}-row-${rIndex}`;
                            const dataHeaderId = `${cell.headerId}-row-${rIndex}`;
                            const rowKey = `row-${cIndex}-key`;
                            return (
                                <tr key={rowKey}>
                                    <th
                                        id={headerId}
                                        scope="row"
                                        className={styles['header-med']}
                                    >
                                        {header.name}
                                    </th>
                                    <td
                                        headers={dataHeaderId}
                                        className={styles['cell-med']}
                                    >
                                        {cell.content}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            );
        })}
    </div>
);
export default TableMedium;

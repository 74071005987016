import {
    Fragment,
    memo, ReactElement, RefObject, useCallback, useEffect, useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SideMenu.module.css';
import Drawer from './Drawer';

interface MenuOption {
    type: 'option';
    label: string;
    id: string;
    linkTo: string;
    icon?: ReactElement;
}

interface MenuOptionGroup {
    type: 'group';
    label: string;
    id: string;
    subOptions: MenuOption[];
    icon?: ReactElement;
}

export type SideMenuOption = MenuOption | MenuOptionGroup;

interface SideMenuProps {
    menuOptions: SideMenuOption[];
    isOpen: boolean;
    menuButtonRef: RefObject<HTMLElement>;
    containerRef?: RefObject<HTMLDivElement>;
    onClose?: () => void;
}

const SideMenu = ({
    menuOptions,
    isOpen,
    menuButtonRef,
    containerRef,
    onClose = () => undefined
}: SideMenuProps): ReactElement => {
    interface MenuGroupState { [groupId: string]: boolean }
    const [nestedMenuOpenState, setNestedMenuOpenState] = useState<MenuGroupState>({});
    const [finalFocusable, setFinalFocusable] = useState<HTMLButtonElement|null>(null);
    const [isOnDom, setIsOnDom] = useState<boolean>(false);
    const navigate = useNavigate();

    const toggleMenuGroup = (groupId: string): void => (
        setNestedMenuOpenState((prevState) => {
            // If the groupID is already in the state object, we'll toggle it
            let newState = true;
            if (prevState[groupId]) {
                newState = !prevState[groupId];
            }
            // If the groupID is not in the state object, just set as true since this
            // means the group is closed
            return { ...prevState, [groupId]: newState };
        })
    );

    useEffect(() => {
        // Initially set the final option ID. No nested options should be open initially
        if (isOnDom && (menuOptions.length > 0)) {
            const { id } = menuOptions[menuOptions.length - 1];
            const button = document.getElementById(`${id}-button`) as HTMLButtonElement;
            setFinalFocusable(button);
        } else {
            // Reset nested menu states when closing the side menu
            setNestedMenuOpenState({});
        }
    }, [isOnDom, menuOptions]);

    useEffect(() => {
        // Set the max height on all the menu groups
        Object.entries(nestedMenuOpenState).forEach((entry) => {
            const groupId = entry[0];
            const isGroupOpen = entry[1];
            const groupElement = document.getElementById(`${groupId}-options`);
            if (groupElement) {
                const maxHeight = `${groupElement.scrollHeight}px`;
                groupElement.style.maxHeight = isGroupOpen ? maxHeight : '';
            }
        });

        if (menuOptions.length > 0) {
            // Update final focusable , if necessary
            const finalMenuOption = menuOptions[menuOptions.length - 1];
            // Only menu groups will require additional processing to determine final focusable
            if (finalMenuOption.type === 'group') {
                let id: string;
                if (nestedMenuOpenState[finalMenuOption.id]) {
                    // Group is expanded, the final focusable will be the last option in the group
                    id = finalMenuOption.subOptions[finalMenuOption.subOptions.length - 1].id;
                } else {
                    // Group is collapsed, the final focusable will be the group itself
                    id = finalMenuOption.id;
                }
                const button = document.getElementById(`${id}-button`) as HTMLButtonElement;
                setFinalFocusable(button);
            }
        }
    }, [nestedMenuOpenState, menuOptions]);

    const isNestedMenuOpen = (id: string): boolean => (nestedMenuOpenState[id] ?? false);

    const onMenuItemClick = (linkTo: string): void => {
        navigate(linkTo);
        if (onClose) {
            onClose();
        }
    };

    const getMenuItem = (
        option: MenuOption,
        isNestedOption = false,
        isExpanded = false
    ): ReactElement => {
        const buttonClass = isNestedOption ? styles['nav-nested-button'] : styles['nav-button'];
        const iconClass = isNestedOption ? styles['nav-nested-icon'] : styles['nav-icon'];
        const labelClass = isNestedOption ? styles['nav-nested-label'] : styles['nav-label'];
        const isVisible = isOpen && (!isNestedOption || (isNestedOption && isExpanded));

        return (
            <li className={styles['nav-list-item']} key={option.id}>
                <button
                    type="button"
                    className={buttonClass}
                    tabIndex={isVisible ? undefined : -1}
                    onClick={() => onMenuItemClick(option.linkTo)}
                    id={`${option.id}-button`}
                >
                    {option.icon && (
                        <span className={iconClass}>{option.icon}</span>
                    )}
                    <span className={labelClass}>{option.label}</span>
                </button>
            </li>
        );
    };

    const getMenuGroup = (optionGroup: MenuOptionGroup): ReactElement => {
        const isExpanded = isNestedMenuOpen(optionGroup.id);

        return (
            <Fragment key={optionGroup.id}>
                <li className={styles['nav-list-item']}>
                    <button
                        type="button"
                        className={styles['nav-button']}
                        aria-controls={optionGroup.id}
                        aria-expanded={isExpanded}
                        tabIndex={isOpen ? undefined : -1}
                        onClick={() => toggleMenuGroup(optionGroup.id)}
                        id={`${optionGroup.id}-button`}
                    >
                        {optionGroup.icon && (
                            <span className={styles['nav-icon']}>
                                {optionGroup.icon}
                            </span>
                        )}
                        <span className={styles['nav-label']}>
                            {optionGroup.label}
                        </span>
                    </button>
                </li>
                <li
                    className={styles['nav-collapsed-links']}
                    aria-hidden={isExpanded ? undefined : true}
                    id={`${optionGroup.id}-options`}
                    key={`${optionGroup.id}-options`}
                >
                    <ul className={styles['nav-list']}>
                        {optionGroup.subOptions.map((subOption) => (
                            getMenuItem(subOption, true, isExpanded)
                        ))}
                    </ul>
                </li>
            </Fragment>
        );
    };

    const getMenuListItems = (): ReactElement[] => {
        if (menuOptions.length > 0) {
            return menuOptions.map((menuOption) => {
                if (menuOption.type === 'option') {
                    return getMenuItem(menuOption);
                }
                return getMenuGroup(menuOption);
            });
        }
        return [];
    };

    const getFinalFocusableRef = (): RefObject<HTMLButtonElement> => (
        { current: finalFocusable }
    );

    const handleIsOnDom = useCallback(() => (setIsOnDom(true)), []);

    const handleNotIsOnDom = useCallback(() => (setIsOnDom(false)), []);

    return (
        <Drawer
            isOpen={isOpen}
            containerRef={containerRef}
            closeButtonLabel="Close Side Menu"
            returnFocusableRef={menuButtonRef}
            finalFocusableRef={getFinalFocusableRef()}
            onClose={onClose}
            onIsOnDom={handleIsOnDom}
            onNotIsOnDom={handleNotIsOnDom}
        >
            <nav>
                <ul className={styles['nav-list']}>
                    {getMenuListItems()}
                </ul>
            </nav>
        </Drawer>
    );
};

export default memo(SideMenu);

import { ReactElement } from 'react';
import styles from './NoPage.module.css';

const NoPage = (): ReactElement => (
    <div className={`${styles.container} content-clamp`}>
        <div className={styles['error-code']}>404</div>
        <div className={styles['error-name']}>PAGE NOT FOUND</div>
        <div className={styles['error-description']}>
            That page doesn&apos;t exist (or hasn&apos;t been created yet). Please check the address
            and try again
        </div>
    </div>
);

export default NoPage;

import {
    useState, memo, ReactElement, RefObject
} from 'react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Switch.module.css';

interface SwitchProps {
    switchTitle: string;
    ariaLabeledBy?: string;
    id?: string;
    inputId?: string;
    inputName?: string;
    isDefaultToggled?: boolean;
    isDisabled?: boolean;
    onToggledOn?: () => void;
    onToggledOff?: () => void;
    ariaControls?: string;
    isHidden?: boolean;
    toggleRef?: RefObject<HTMLButtonElement>;
}

const Switch = ({
    switchTitle,
    ariaLabeledBy = undefined,
    id = undefined,
    inputId = undefined,
    inputName = undefined,
    isDefaultToggled = false,
    isDisabled = false,
    onToggledOn = () => undefined,
    onToggledOff = () => undefined,
    ariaControls = undefined,
    isHidden = false,
    toggleRef = undefined
}: SwitchProps): ReactElement => {
    const [isToggled, setIsToggled] = useState(isDefaultToggled);

    const handleClick = (): void => {
        setIsToggled((prevState) => !prevState);
        if (isToggled) {
            onToggledOff();
        } else {
            onToggledOn();
        }
    };

    const getButtonClass = (): string => {
        let cssClass = `${styles.switch} `;
        cssClass += isToggled ? styles['switch-toggled'] : '';
        return cssClass;
    };

    const getLockIcon = (): ReactElement|null => {
        if (isDisabled) {
            const cssClass = isToggled ? 'lock-icon-toggled' : 'lock-icon';
            return <FontAwesomeIcon className={styles[cssClass]} icon={faLock} />;
        }
        return null;
    };

    const getInput = (): ReactElement|null => {
        if (inputId) {
            return (
                <input
                    type="hidden"
                    id={inputId}
                    name={inputName}
                    value={isToggled ? 'true' : 'false'}
                />
            );
        }
        return null;
    };

    return (
        <>
            <button
                type="button"
                className={getButtonClass()}
                id={id}
                title={switchTitle}
                aria-labelledby={ariaLabeledBy}
                aria-checked={isToggled}
                aria-controls={ariaControls}
                role="switch"
                tabIndex={isHidden ? -1 : undefined}
                onClick={handleClick}
                disabled={isDisabled}
                ref={toggleRef}
            >
                {getLockIcon()}
            </button>
            {getInput()}
        </>
    );
};

export default memo(Switch);

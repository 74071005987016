import { memo, ReactElement } from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import {
    darkShadesCode, tabsHeaderDescription, tabsPropDetails,
    tabPropDetails
} from './tabsDetails';
import ContentHeader from '../../../common-components/ContentHeader';
import Tabs, { Tab } from '../../../common-components/Tabs';
import ComponentPropsTable from '../ComponentPropsTable';

const TabsUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getExampleSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('example');
        return builder.getComponentSection([
            builder.getComponentSectionDescription((
                <p>
                    In this example, the second tab is set as selected by default. If a tab is not
                    selected by default, the left-most tab will be selected by default
                    automatically.
                </p>
            )),
            builder.getExampleSection([
                <Tabs defaultSelected="Second">
                    <Tab name="First">
                        FIRST CONTENT
                    </Tab>
                    <Tab name="Second">
                        SECOND CONTENT
                    </Tab>
                    <Tab name="Third">
                        THIRD CONTENT
                    </Tab>
                </Tabs>
            ]),
            builder.getCodeSection(darkShadesCode)
        ], true);
    };

    return (
        <>
            <ContentHeader
                headerText="Tabs"
                breadcrumb="Components"
                description={tabsHeaderDescription}
            />
            <div className="content-clamp">
                <Tabs>
                    <Tab name="Example">
                        {getExampleSection()}
                    </Tab>
                    <Tab name="Tabs Props">
                        <ComponentPropsTable componentProps={tabsPropDetails} />
                    </Tab>
                    <Tab name="Tab Props">
                        <ComponentPropsTable componentProps={tabPropDetails} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};
export default memo(TabsUsageExamples);

import { memo, ReactElement, ReactNode } from 'react';
import styles from './ContentHeader.module.css';

interface ContentHeaderProps {
    headerText: string
    breadcrumb?: string;
    description?: ReactNode;
}

const ContentHeader = ({
    headerText,
    breadcrumb,
    description
}: ContentHeaderProps): ReactElement => {
    const getHeaderClass = (): string => {
        let cssClass = `${styles['header-text']} `;
        cssClass += breadcrumb ? `${styles['breadcrumb-included']} ` : '';
        cssClass += description ? `${styles['description-included']}` : '';
        return cssClass;
    };

    return (
        <div className={styles['header-bg']}>
            <div className="content-clamp">
                {breadcrumb && (
                    <strong>
                        <div className={styles.breadcrumb}>{breadcrumb} &#47;&#47;</div>
                    </strong>
                )}
                <h1 className={getHeaderClass()}>{headerText}</h1>
                {description && <p className={styles.description}>{description}</p>}
            </div>
        </div>
    );
};

export default memo(ContentHeader);

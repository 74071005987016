import {
    ReactNode, useEffect, useRef, useState, memo, ReactElement
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from './Collapsible.module.css';

interface CollapsibleProps {
    toggleLabel: ReactNode;
    title: string;
    children: ReactNode;
    collapsibleId: string,
    isDefaultOpen?: boolean;
    isParentControlled?: boolean;
    isOpen?: boolean;
    isDisabled?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    isHidden?: boolean;
    isStacked?: boolean;
}

const Collapsible = ({
    title,
    toggleLabel,
    children,
    collapsibleId,
    isParentControlled = false,
    isDefaultOpen = false,
    isOpen = false,
    isDisabled = false,
    onOpen = () => undefined,
    onClose = () => undefined,
    isHidden = false,
    isStacked = false
}: CollapsibleProps): ReactElement => {
    const collapsibleRef = useRef<HTMLDivElement>(null);
    const [isOpenState, setIsOpenState] = useState(isDefaultOpen);
    const isActuallyOpenRef = useRef(false);

    // Initially set the isActuallyOpenRef value
    useEffect(() => {
        isActuallyOpenRef.current = (isParentControlled && isOpen) || isOpenState;
    }, [isParentControlled, isOpen, isOpenState]);

    // Set the height of the content area if open or if child content changes
    useEffect(() => {
        const collapsible = collapsibleRef.current;
        if (collapsible) {
            if (isActuallyOpenRef.current) {
                collapsible.style.maxHeight = `${collapsible.scrollHeight}px`;
            } else {
                collapsible.style.maxHeight = '';
            }
        }
    }, [isParentControlled, isOpen, isOpenState, children]);

    // Set the internal open state to false if parent is now controlling
    useEffect(() => {
        if (isParentControlled && isOpenState) {
            // Close the collapsible internally if being controlled
            setIsOpenState(false);
        }
    }, [isParentControlled, isOpenState]);

    const handleClick = (): void => {
        if (!isParentControlled) {
            setIsOpenState((prevState) => !prevState);
            if (isOpenState) {
                onClose();
            } else {
                onOpen();
            }
        }
    };

    const getToggleClass = (): string => {
        let cssClass = `${styles['collapsible-toggle']} `;

        if (isStacked) {
            cssClass += `${styles['collapsible-stacked']} `;
        }

        if (isOpenState) {
            cssClass += styles['collapsible-toggle-open'];
        }

        if (isParentControlled) {
            cssClass += styles['collapsible-toggle-parent-controlled'];
        }

        if (isDisabled) {
            cssClass += styles['collapsible-toggle-disabled'];
        }

        return cssClass;
    };

    const isActuallyDisabled = (): boolean => isParentControlled || isDisabled;

    const getIcon = (): ReactElement|null => {
        if (isParentControlled) {
            return null;
        }
        return (
            <FontAwesomeIcon icon={isOpenState ? faMinus : faPlus} />
        );
    };

    return (
        <>
            <button
                type="button"
                className={getToggleClass()}
                onClick={handleClick}
                title={title}
                disabled={isActuallyDisabled()}
                aria-controls={collapsibleId}
                aria-expanded={isActuallyOpenRef.current}
                tabIndex={isHidden ? -1 : undefined}
            >
                {toggleLabel}
                {getIcon()}
            </button>
            <div
                className={styles.collapsible}
                id={collapsibleId}
                aria-hidden={!isActuallyOpenRef.current || undefined}
                ref={collapsibleRef}
            >
                {children}
            </div>
        </>
    );
};

export default memo(Collapsible);

import { ReactElement } from 'react';
import ContentHeader from '../../common-components/ContentHeader';

// TODO: checkbox styles like vivo - just filled in solid

// TODO: look into useDispatch

// TODO: (Select.module.css) disabled background? readonly?

// TODO: (Select.tsx) possibly change this to use React.Children.map and such? maybe this will
//  improve efficiency?

// TODO: for component infos, make sure to make hidden elements not tabbable

// TODO: make sure everywhere that useCallback is needed is correct.
const FutureFeatures = (): ReactElement => (
    <>
        <ContentHeader headerText="Future Features" />
        <div className="content-clamp">
            <section>
                <h2>Dark Mode</h2>
                <p>
                    I would really like to have a dark mode created. Mostly this would involve
                    adding a secondary color palette, but some components may need individual
                    tweaks as well.
                </p>
            </section>

            <hr />

            <section>
                <h2>More Components</h2>
                <p>
                    The components included in the design system so far are a small selection that
                    I needed to actually create the design system. I would like to add more to
                    create a most substantial list of components needed for creating a feature-rich
                    website.
                </p>
            </section>

            <hr />

            <section>
                <h2>Fully Responsive</h2>
                <p>
                    The design system as it is now has some minor responsive design functionality
                    to it. I would like to expand this to be fully responsive based on screen size.
                </p>
            </section>

            <hr />

            <section>
                <h2>Expand Foundations</h2>
                <p>
                    I would like to add more information to the Foundations section including (but
                    not limited to):
                </p>
                <ul>
                    <li>Typography</li>
                    <li>Iconography</li>
                    <li>Custom Grid Layout (potentially)</li>
                </ul>
            </section>

            <hr />

            <section>
                <h2>Deploy NPM Library</h2>
                <p>
                    To get the full benefits of having these reusable modules, I want to get the
                    design system deployed as an NPM library that anyone can use.
                </p>
            </section>

            <hr />

            <section>
                <h2>Changelog</h2>
                <p>
                    Implement a changelog section once I start publishing different versions.
                </p>
            </section>

            <hr />

            <section>
                <h2>Performance Improvements</h2>
                <p>
                    As I learn more about React, there will be ways that I can improve performance
                    of the design system and the individual components.
                </p>
            </section>

            <hr />

            <section>
                <h2>Component Playgrounds</h2>
                <p>
                    I would like to implement a playground/sandbox for each component where users
                    can play with props and see in real time how they affect the component.
                </p>
            </section>
        </div>
    </>
);

export default FutureFeatures;

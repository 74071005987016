import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faGitlab } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ContentHeader from '../../common-components/ContentHeader';
import styles from './Introduction.module.css';

const Introduction = (): ReactElement => (
    <>
        <ContentHeader headerText="Introduction" />
        <div className="content-clamp">

            <section>
                <p className={styles['top-paragraph']}>
                    <strong>Welcome to my design system!</strong> My name is Ryan Anderson - the
                    mastermind behind this project! I&apos;m a Senior Software Engineer with 9+
                    years of industry web application development experience.
                </p>

                <div className={styles['contact-group']}>
                    <div className={styles['image-container']}>
                        <img
                            src="/Me.jpg"
                            alt="Me"
                            className={styles['self-photo']}
                        />
                    </div>
                    <div className={styles['details-container']}>
                        <ul className={`${styles['contact-list']} padding-left-0`}>
                            <li>
                                <FontAwesomeIcon icon={faEnvelope} />&nbsp;
                                <a href="mailto:randerson8907@gmail.com">
                                    randerson8907@gmail.com
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faGitlab} />&nbsp;
                                <a
                                    href="https://gitlab.com/randerson8907"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    GitLab
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faGithub} />&nbsp;
                                <a
                                    href="https://github.com/randerson8907"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    GitHub
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faLinkedin} />&nbsp;
                                <a
                                    href="https://www.linkedin.com/in/randerson8907/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    LinkedIn
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <p>
                    With my blood, sweat, and tears, I developed this project in an effort to
                    showcase my HTML, CSS, and TypeScript abilities, but also as an outlet to learn
                    React, web accessibility with dynamic elements, and responsive web pages.
                    Further, the components and themes derived here will remain useful for any
                    future apps I create.
                </p>

                <p>
                    Speaking of the future, if you think this site is complete, think again! This
                    site will remain in construction for the foreseeable future. You may want to
                    stop on by from time to time to find something new.
                </p>
            </section>

            <hr />

            <section>
                <h2>What is &quot;Mythril&quot;?</h2>
                <p>
                    Inspiration for the design system&apos;s name
                    comes from the mystical material found in a lot of fantasy media. This material,
                    typically an ore in its rawest form, can be refined into powerful relics or
                    equipment.
                </p>

                <p>
                    The components, foundations, common code, etc. in this deign system are
                    the &quot;ore&quot; to be refined and used to make something far greater!
                </p>

                <hr />

                <h2>Retrospective</h2>
                <p>
                    One of the <strong>most challenging</strong> aspects of this design system has
                    been the creation of very dynamic elements and keeping them accessible. For
                    example, it took me about a month (in my free time) of researching, prototyping,
                    coding, and refactoring to create
                    the <Link to="/components/select">Select</Link> component. This was fairly early
                    on in the life of the design system. That being said, I need to re-work this
                    component a little bit using some React principles that I have learned since
                    initial implementation.
                </p>

                <p>
                    How about <strong>my favorite</strong> part of this journey? This is a harder
                    question to answer. I could say something broad like all I&apos;ve learned about
                    React, HTML, CSS, and Typescript along the way. However, if I was to pinpoint
                    one section, I would say
                    the <Link to="/foundations/colors">Colors</Link> section. I&apos;m very
                    proud of that page. Dipping my toes into the world of color theory and deciding
                    what colors to include was challenging, but really fun!
                </p>
            </section>

            <hr />

            <section>
                <h2>Help from a Friend</h2>
                <p>
                    To give credit where it&apos;s due, I consulted with a friend of mine, an
                    experienced designer and writer, to improve the site&apos;s design and UX. She
                    also edited the writing, helped a ton with color theory/selection, and created
                    the logo! Her name is Michelle McLean.
                </p>

                <div className={styles['contact-group']}>
                    <div className={styles['image-container']}>
                        <img
                            src="/Taini.png"
                            alt="Chelle's cat"
                            className={styles['self-photo']}
                        />
                    </div>
                    <div className={styles['details-container']}>
                        <ul className={`${styles['contact-list']} padding-left-0`}>
                            <li>
                                <FontAwesomeIcon icon={faEnvelope} />&nbsp;
                                <a href="mailto:michelle.mcleana@gmail.com">
                                    michelle.mcleana@gmail.com
                                </a>
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faGithub} />&nbsp;
                                <a
                                    href="https://github.com/lennasuzaka1988"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    GitHub
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>

        </div>
    </>
);

export default Introduction;

import {
    memo, ReactElement, useCallback, useRef, useState
} from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import {
    menuOptionDetails, sideMenuHeaderDescription, sideMenuPropDetails,
    menuOptionGroupDetails, exampleCode, exampleMenuOptionsCode
} from './sideMenuDetails';
import Code from '../../../common-components/Code';
import Button from '../../../common-components/Button';
import SideMenu, { SideMenuOption } from '../../../common-components/SideMenu';
import ContentHeader from '../../../common-components/ContentHeader';
import Tabs, { Tab } from '../../../common-components/Tabs';
import ComponentPropsTable from '../ComponentPropsTable';

const SideMenuUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const [isOpen, setIsOpen] = useState(false);
    const menuButtonRef = useRef<HTMLButtonElement>(null);

    const handleClose = useCallback(() => setIsOpen(false), []);

    const menuOptions: SideMenuOption[] = [
        {
            type: 'option',
            label: 'Home',
            id: 'home-option',
            linkTo: '/home'
        },
        {
            type: 'group',
            label: 'Contact',
            id: 'contact-group',
            subOptions: [
                {
                    type: 'option',
                    label: 'Email',
                    id: 'email-option',
                    linkTo: '/contact/email'
                },
                {
                    type: 'option',
                    label: 'Phone',
                    id: 'phone-option',
                    linkTo: '/contact/email'
                },
                {
                    type: 'option',
                    label: 'Chat',
                    id: 'chat-option',
                    linkTo: '/contact/chat'
                }
            ]
        },
        {
            type: 'option',
            label: 'Blog',
            id: 'blog-option',
            linkTo: '/blog'
        },
        {
            type: 'option',
            label: 'About',
            id: 'about-option',
            linkTo: '/about'
        }
    ];

    const getExampleSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('example');
        return builder.getComponentSection([
            builder.getComponentSectionDescription((
                <p>
                    Example of how the side menu works. Notice how the &quot;Contact&quot; option
                    has sub-options. To close the menu, click the top-left arrow or click outside
                    of the menu. Note that none of the links in this example actually go anywhere.
                </p>
            )),
            builder.getExampleSection([
                builder.getExampleTool([
                    <Button
                        onClick={() => setIsOpen(true)}
                        buttonRef={menuButtonRef}
                    >
                        Open Menu
                    </Button>
                ]),
                <SideMenu
                    menuOptions={menuOptions}
                    isOpen={isOpen}
                    menuButtonRef={menuButtonRef}
                    onClose={handleClose}
                />
            ]),
            builder.getCodeSection(exampleCode),
            builder.getCollapsibleCodeSection(
                exampleMenuOptionsCode,
                'menuOptions Definition',
                <span><Code>menuOptions</Code> Definition</span>
            )
        ], true);
    };

    return (
        <>
            <ContentHeader
                headerText="Side Menu"
                breadcrumb="Components"
                description={sideMenuHeaderDescription}
            />
            <div className="content-clamp">
                <Tabs>
                    <Tab name="Examples">
                        {getExampleSection()}
                    </Tab>
                    <Tab name="Props">
                        <ComponentPropsTable componentProps={sideMenuPropDetails} />
                    </Tab>
                    <Tab name="SideMenuOption">
                        <p>
                            Details about the <Code>SideMenuOption</Code> interface which can be
                            either
                            type <Code>MenuOptionGroup</Code>
                            &nbsp;or <Code>MenuOption</Code>.
                        </p>

                        <section>
                            <h2>MenuOptionGroup</h2>
                            <ComponentPropsTable componentProps={menuOptionGroupDetails} />
                        </section>

                        <section>
                            <h2>MenuOption</h2>
                            <ComponentPropsTable componentProps={menuOptionDetails} />
                        </section>
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};
export default memo(SideMenuUsageExamples);

import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const tabsHeaderDescription = 'Provides a tab interface for separating out page content.';

export const tabsPropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactElement[]'],
        isOptional: false,
        description: (
            <>
                List of <Code>Tab</Code> elements.
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'selected',
        types: ['string'],
        isOptional: true,
        description: `Sets the selected tab from the parent. Updates every time this prop changes.
        The user is still able to select tabs themselves.`,
        defaultValue: 'N/A'
    },
    {
        name: 'onChange',
        types: ['(newTab: string) => void'],
        isOptional: true,
        description: 'Emits an event when the user clicks and changes the selected tab.',
        defaultValue: '() => undefined'
    }
];

export const tabPropDetails: ComponentPropDetail[] = [
    {
        name: 'name',
        types: ['string'],
        isOptional: false,
        description: `Name displayed for the tab. Also used as the unique identifier when selecting 
        tabs.`,
        defaultValue: 'N/A'
    },
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the tab.',
        defaultValue: 'N/A'
    },
    {
        name: 'isDisabled',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether this tab is disabled.',
        defaultValue: 'false'
    },
    {
        name: 'isActive',
        types: ['boolean'],
        isOptional: true,
        description: (
            <>
                <strong>Only used internally. Should not be set by the implementer</strong>.
            </>
        ),
        defaultValue: 'N/A'
    }
];

export const darkShadesCode = `<Tabs defaultSelected="Second">
    <Tab name="First">
        FIRST CONTENT
    </Tab>
    <Tab name="Second">
        SECOND CONTENT
    </Tab>
    <Tab name="Third">
        THIRD CONTENT
    </Tab>
</Tabs>
`;

import {
    memo, ReactElement, useCallback, useRef, useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import {
    delayedPortalCssCode,
    delayedPortalHeaderDescription,
    delayedPortalTsxCode,
    delayedPortalPropDetails,
    delayedPortalEventsTableDef
} from './delayedPortalDetails';
import Code from '../../../common-components/Code';
import DelayedPortal from '../../../common-components/DelayedPortal';
import styles from './DelayedPortalUsageExample.module.css';
import Switch from '../../../common-components/Switch';
import ContentHeader from '../../../common-components/ContentHeader';
import Tabs, { Tab } from '../../../common-components/Tabs';
import ComponentPropsTable from '../ComponentPropsTable';
import Table from '../../../common-components/Table';

const DelayedPortalUsageExamples = (): ReactElement => {
    const [isOnDom, setIsOnDom] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const targetRef = useRef<HTMLDivElement>(null);

    const handleIsOnDom = useCallback(() => (setIsOnDom(true)), []);
    const handleNotIsOnDom = useCallback(() => (setIsOnDom(false)), []);
    const handleIsVisible = useCallback(() => (setIsVisible(true)), []);
    const handleNotIsVisible = useCallback(() => (setIsVisible(false)), []);

    const getPortalClass = (): string => (
        `${styles['portal-content']} ${isVisible ? styles['portal-content-visible'] : ''}`
    );

    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getExampleSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('example');
        return builder.getComponentSection([
            builder.getComponentSectionDescription((
                <div>
                    <p>
                        This shows an exaggerated example of how the DelayedPortal works with a 5
                        second (5000ms) open and close delay. For simple load-in animation,
                        the <Code>openDelay</Code> should be left default (10ms).
                        Then, <Code>closeDelay</Code> should be set to match the css transition
                        duration.
                    </p>
                    <p>
                        Here&apos;s a view of the state returned by <Code>onStateChange</Code>. See
                        the &quot;Events&quot; tab for more information about these states.
                    </p>
                    <div><Code>isOnDom</Code>: {`${isOnDom}`}</div>
                    <div><Code>isVisible</Code>: {`${isVisible}`}</div>
                </div>
            )),
            builder.getExampleSection([
                builder.getExampleTool([
                    <Switch
                        switchTitle="Is Active"
                        ariaLabeledBy="is-active-label"
                        onToggledOn={() => setIsActive(true)}
                        onToggledOff={() => setIsActive(false)}
                    />,
                    <Code id="is-active-label">isActive</Code>
                ]),
                <DelayedPortal
                    isActive={isActive}
                    containerRef={targetRef}
                    onIsOnDom={handleIsOnDom}
                    onNotIsOnDom={handleNotIsOnDom}
                    onIsVisible={handleIsVisible}
                    onNotIsVisible={handleNotIsVisible}
                    openDelay={5000}
                    closeDelay={5000}
                >
                    <div className={getPortalClass()}>
                        <FontAwesomeIcon icon={faCircleInfo} className={styles['portal-icon']} />
                        This is the content of the Delayed Portal.
                    </div>
                </DelayedPortal>
            ]),
            builder.getCodeSection(delayedPortalTsxCode, 'tsx'),
            builder.getCollapsibleCodeSection(
                delayedPortalCssCode,
                'Code (css)',
                'Code (css)',
                'css'
            )
        ], true);
    };

    return (
        <>
            <ContentHeader
                headerText="Delayed Portal"
                breadcrumb="Components"
                description={delayedPortalHeaderDescription}
            />
            <div className="content-clamp">
                <Tabs>
                    <Tab name="Example">
                        <div id="example-target-div" key="tar" ref={targetRef} />
                        {getExampleSection()}
                    </Tab>
                    <Tab name="Props">
                        <ComponentPropsTable componentProps={delayedPortalPropDetails} />
                    </Tab>
                    <Tab name="Events">
                        <p>
                            This component emits four events that inform the parent of the
                            DelayedPortal&apos;s state:
                        </p>
                        <Table tableDef={delayedPortalEventsTableDef} />
                    </Tab>
                </Tabs>
            </div>
        </>
    );
};
export default memo(DelayedPortalUsageExamples);

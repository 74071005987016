import { Fragment, ReactElement } from 'react';
import { TableDef } from '../Table';
import styles from '../Table.module.css';

interface TableSmallProps {
    tableDef: TableDef;
}

const TableSmall = ({ tableDef }: TableSmallProps): ReactElement => (
    <div className={styles['table-container']}>
        {tableDef.rows.map((row, rIndex) => {
            const tableKey = `table-${rIndex}-key`;
            return (
                <table className={styles['table-sm']} key={tableKey}>
                    <tbody>
                        {row.cells.map((cell, cIndex) => {
                            const header = tableDef.headers[cIndex];
                            const headerId = `${header.id}-row-${rIndex}`;
                            const dataHeaderId = `${cell.headerId}-row-${rIndex}`;
                            const rowKey = `row-${cIndex}-key`;
                            return (
                                <Fragment key={rowKey}>
                                    <tr>
                                        <th
                                            id={headerId}
                                            scope="col"
                                            className={styles['header-sm']}
                                        >
                                            {header.name}
                                        </th>
                                    </tr>
                                    <tr>
                                        <td
                                            headers={dataHeaderId}
                                            className={styles['cell-sm']}
                                        >
                                            {cell.content}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                    </tbody>
                </table>
            );
        })}
    </div>
);

export default TableSmall;

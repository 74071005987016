import { ComponentPropDetail } from '../ComponentPropsTable';

export const contentHeaderHeaderDescription = `Creates a stylized header for content pages. Has
options to show a breadcrumb and description text. The header takes up full width of the parent
container.`;

export const contentHeaderPropDetails: ComponentPropDetail[] = [
    {
        name: 'headerText',
        types: ['string'],
        isOptional: false,
        description: 'Text of the header.',
        defaultValue: 'N/A'
    },
    {
        name: 'breadcrumb',
        types: ['string'],
        isOptional: true,
        description: 'Breadcrumb text which will appear above the header text.',
        defaultValue: 'N/A'
    },
    {
        name: 'description',
        types: ['ReactNode'],
        isOptional: true,
        description: 'Description which will appear below the header text.',
        defaultValue: 'N/A'
    }
];

export const basicHeaderCode = '<ContentHeader headerText="Basic Header" />';

export const detailedHeaderCode = `<ContentHeader
    headerText="Detailed Header"
    description={(
        <>
            This header has more details along with a breadcrumb and description.
            Notice that description is a <Code>ReactNode</Code>, which means that
            it can be a <Code>string</Code> or JSX
            node <FontAwesomeIcon icon={faCheck} />.
        </>
    )}
    breadcrumb="Header Types"
/>`;

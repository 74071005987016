import { ComponentPropDetail } from '../ComponentPropsTable';

export const switchHeaderDescription = `Switch component which can be used in place of a checkbox.
The switch itself is a button element with special styling to look like a switch.`;

export const switchPropDetails: ComponentPropDetail[] = [
    {
        name: 'switchTitle',
        types: ['string'],
        isOptional: false,
        description: 'Title of the switch toggle.',
        defaultValue: 'N/A'
    },
    {
        name: 'ariaLabeledBy',
        types: ['string'],
        isOptional: true,
        description: 'ID of the element which labels this switch.',
        defaultValue: 'undefined'
    },
    {
        name: 'id',
        types: ['string'],
        isOptional: true,
        description: 'ID of the switch.',
        defaultValue: 'undefined'
    },
    {
        name: 'inputId',
        types: ['string'],
        isOptional: true,
        description: `If provided, a hidden input element will be added to the page with this ID.
        Provide this when the Switch is part of a form.`,
        defaultValue: 'undefined'
    },
    {
        name: 'inputName',
        types: ['string'],
        isOptional: true,
        description: `Required if inputId is provided. This is the "name" attribute on the hidden
        input.`,
        defaultValue: 'undefined'
    },
    {
        name: 'isDefaultToggled',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the switch is toggled on by default.',
        defaultValue: 'false'
    },
    {
        name: 'isDisabled',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the switch is disabled.',
        defaultValue: 'false'
    },
    {
        name: 'onToggledOn',
        types: ['() => void'],
        isOptional: true,
        description: 'Emits when the toggle is switched on.',
        defaultValue: '() => undefined'
    },
    {
        name: 'onToggledOff',
        types: ['() => void'],
        isOptional: true,
        description: 'Emits when the toggle is switched off.',
        defaultValue: '() => undefined'
    },
    {
        name: 'ariaControls',
        types: ['string'],
        isOptional: true,
        description: 'ID of an element that this switch component controls, if applicable.',
        defaultValue: 'undefined'
    },
    {
        name: 'isHidden',
        types: ['boolean'],
        isOptional: true,
        description: 'Whether the component is currently hidden.',
        defaultValue: 'false'
    },
    {
        name: 'toggleRef',
        types: ['RefObject<HTMLButtonElement>'],
        isOptional: true,
        description: 'Reference for the Switch toggle.',
        defaultValue: 'undefined'
    }
];

export const disabledCode = `<Switch switchTitle="Disabled Example" isDisabled />
<Switch switchTitle="Disabled Example" isDefaultToggled isDisabled />
`;

import {
    CSSProperties,
    ReactElement,
    useEffect, useRef, useState
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSquare, faStar as faStarSolid
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import Select, { SelectOption } from '../../../common-components/Select';
import { OptionData } from '../../../common-components/internal/Option';
import { COLOR_DEFS, ColorDef } from './ColorDef';
import styles from './ColorContrastChecker.module.css';
import calculateContrastRatio from './calculateContrastRatio';
import Collapsible from '../../../common-components/Collapsible';
import Badge from '../../../common-components/Badge';

const ColorContrastChecker = (): ReactElement => {
    interface ColorContrastCheckerState {
        bgColorDef: ColorDef|undefined,
        txtColorDef: ColorDef|undefined,
        contrastRatio: number
    }
    const [state, setState] = useState<ColorContrastCheckerState>({
        bgColorDef: undefined,
        txtColorDef: undefined,
        contrastRatio: 0
    });

    const colorOptionsRef = useRef<SelectOption[]>([]);
    // Initialize the color options grouped by section
    if (colorOptionsRef.current.length === 0) {
        const sectionIds = Object.keys(COLOR_DEFS);
        // Convert sections to groups
        colorOptionsRef.current = sectionIds.map((sectionId): SelectOption => (
            {
                type: 'group',
                label: sectionId,
                groupId: `${sectionId}-option-group`,
                // Build the children of the group
                childOptions: COLOR_DEFS[sectionId].map((colorDef): OptionData => ({
                    type: 'option',
                    preIcon: (
                        <FontAwesomeIcon
                            style={{ color: `var(--${colorDef.cssVar}` }}
                            icon={faSquare}
                        />
                    ),
                    label: colorDef.name,
                    value: colorDef.id
                }))
            }
        ));
    }

    const findColorDef = (id: string): ColorDef|undefined => {
        // Color IDs have the format: GroupID.ColorID
        const groupId = id.split('.')[0];
        // Find the color definition
        return COLOR_DEFS[groupId].find((def) => def.id === id);
    };

    const handleBgColorChange = (newValue: string): void => {
        if (newValue) {
            setState((prevState) => ({ ...prevState, bgColorDef: findColorDef(newValue) }));
        }
    };

    const handleTxtColorChange = (newValue: string): void => {
        if (newValue) {
            setState((prevState) => ({ ...prevState, txtColorDef: findColorDef(newValue) }));
        }
    };

    useEffect(() => {
        if (state.bgColorDef && state.txtColorDef) {
            const contrastRatio = calculateContrastRatio(
                state.bgColorDef.hex,
                state.txtColorDef.hex
            );
            setState((prevState) => ({ ...prevState, contrastRatio }));
        }
    }, [state.bgColorDef, state.txtColorDef]);

    const getPreviewStyles = (): CSSProperties => ({
        backgroundColor: state.bgColorDef?.hex,
        color: state.txtColorDef?.hex
    });

    const getBadgeColor = (): 'red'|'yellow'|'blue'|'green' => {
        if (state.contrastRatio < 3) {
            return 'red';
        }
        if (state.contrastRatio >= 3 && state.contrastRatio < 4.5) {
            return 'yellow';
        }
        if (state.contrastRatio >= 4.5 && state.contrastRatio < 7) {
            return 'blue';
        }
        return 'green';
    };

    const getContrastIcons = (contrastRatio: number, keyPrefix: string): ReactElement[] => {
        let color = 'dark-green';
        if (contrastRatio < 3) {
            color = 'vivid-burgundy';
        }
        if (contrastRatio >= 3 && contrastRatio < 4.5) {
            color = 'honey-yellow';
        }
        if (contrastRatio >= 4.5 && contrastRatio < 7) {
            color = 'absolute-zero';
        }
        return [
            <FontAwesomeIcon
                className={styles['contrast-icon']}
                style={{ color: `var(--${color})` }}
                icon={contrastRatio < 3 ? faStarRegular : faStarSolid}
                title={contrastRatio < 3 ? 'Empty Star' : 'Filled Star'}
                key={`${keyPrefix}-first-star`}
            />,
            <FontAwesomeIcon
                className={styles['contrast-icon']}
                style={{ color: `var(--${color})` }}
                icon={contrastRatio < 4.5 ? faStarRegular : faStarSolid}
                title={contrastRatio < 4.5 ? 'Empty Star' : 'Filled Star'}
                key={`${keyPrefix}-second-star`}
            />,
            <FontAwesomeIcon
                className={styles['contrast-icon']}
                style={{ color: `var(--${color})` }}
                icon={contrastRatio < 7 ? faStarRegular : faStarSolid}
                title={contrastRatio < 7 ? 'Empty Star' : 'Filled Star'}
                key={`${keyPrefix}-third-star`}
            />
        ];
    };

    return (
        <>
            <label
                id="bg-color-label"
                htmlFor="bg-color-select"
                className="margin-top-0"
            >
                Background Color
            </label>
            <Select
                id="bg-color-select"
                title="Background Color Select"
                ariaLabeledBy="bg-color-label"
                isHidden={false}
                onChange={handleBgColorChange}
                defaultValue="Primary.indigo"
                selectOptions={colorOptionsRef.current}
            />
            <label id="txt-color-label" htmlFor="txt-color-select">
                Text Color
            </label>
            <Select
                id="txt-color-select"
                title="Text Color Select"
                ariaLabeledBy="txt-color-label"
                isHidden={false}
                onChange={handleTxtColorChange}
                defaultValue="Primary.cultured"
                selectOptions={colorOptionsRef.current}
            />

            <div className={styles.labelish}>Contrast Ratio</div>
            <div className={styles['badge-area']}>
                <Badge color={getBadgeColor()}>{state.contrastRatio.toFixed(2)}</Badge>
                {getContrastIcons(state.contrastRatio, 'badge')}
            </div>

            <Collapsible
                title="Thresholds"
                toggleLabel="Thresholds"
                collapsibleId="contrast-ratio-thresholds"
            >
                <div className={styles['first-threshold']}>
                    {getContrastIcons(1, 'first-threshold')}
                </div>
                <ul className={styles['threshold-detail-list']}>
                    <li>Less than 3:1 ratio.</li>
                    <li>Fails all WCAG Ratings.</li>
                </ul>

                {getContrastIcons(3, 'second-threshold')}
                <ul className={styles['threshold-detail-list']}>
                    <li>At least 3:1 ratio.</li>
                    <li>WCAG AA Rating - Passes for Large Font.</li>
                    <li>WCAG AAA Rating - Fails for all font sizes.</li>
                </ul>

                {getContrastIcons(4.5, 'third-threshold')}
                <ul className={styles['threshold-detail-list']}>
                    <li>At least 4.5:1  ratio.</li>
                    <li><strong>Lowest acceptable ratio.</strong></li>
                    <li>WCAG AA Rating - Passes for all font sizes.</li>
                    <li>WCAG AAA Rating - Passes for Large Font.</li>
                </ul>

                {getContrastIcons(7, 'fourth-threshold')}
                <ul className={styles['threshold-detail-list']}>
                    <li>More than 7:1 ratio.</li>
                    <li><strong>Perfect score!</strong></li>
                    <li>Passes all WCAG Ratings.</li>
                </ul>
            </Collapsible>
            <Collapsible
                title="Definitions"
                toggleLabel="Definitions"
                collapsibleId="contrast-ratio-definitions"
                isStacked
            >
                <div className={styles.labelish}>Regular Font</div>
                <ul>
                    <li>
                        Any font size that is 17pt or below for regular font-weight, or 13pt and
                        below for bold font-weight.
                    </li>
                </ul>

                <div className={styles.labelish}>Large Font</div>
                <ul>
                    <li>
                        Any font size that is 18pt or above for regular font-weight, or 14pt and
                        above for bold font-weight.
                    </li>
                </ul>

                <div className={styles.labelish}>More Info</div>
                <ul>
                    <li>
                        <a
                            href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            WCAG AA - Contrast (Minimum)
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast7.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            WCAG AAA - Contrast (Enhanced)
                        </a>
                    </li>
                </ul>
            </Collapsible>

            <hr />

            <div className={styles.labelish}>Samples</div>
            <div className={styles['regular-normal-preview']} style={getPreviewStyles()}>
                <p>Regular Font</p>
                <p>Font-size: 12pt / Font-weight: normal</p>
                <p className="margin-bottom-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                </p>
            </div>
            <div className={styles['regular-bold-preview']} style={getPreviewStyles()}>
                <p>Regular Font</p>
                <p>Font-size: 9pt / Font-weight: bold</p>
                <p className="margin-bottom-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                </p>
            </div>
            <div className={styles['large-normal-preview']} style={getPreviewStyles()}>
                <p>Large Font</p>
                <p>Font-size: 18pt / Font-weight: normal</p>
                <p className="margin-bottom-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                </p>
            </div>
            <div className={styles['large-bold-preview']} style={getPreviewStyles()}>
                <p>Large Font</p>
                <p>Font-size: 14pt / Font-weight: bold</p>
                <p className="margin-bottom-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua.
                </p>
            </div>
        </>
    );
};
export default ColorContrastChecker;

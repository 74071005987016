import { memo, ReactElement, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAppleWhole, faLeaf, faCarrot, faCartShopping, faBucket
} from '@fortawesome/free-solid-svg-icons';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import {
    groupingCode,
    groupingOptionsCode, popoverContainerCode, popoverContainerOptionsCode,
    standardCode,
    standardOptionsCode,
    sizesCode,
    selectHeaderDescription, selectPropDetails,
    optionGroupDetails, optionGroupDataDetails
} from './selectDetails';
import Code from '../../../common-components/Code';
import Select, { SelectOption } from '../../../common-components/Select';
import styles from './SelectUsageExamples.module.css';
import ContentHeader from '../../../common-components/ContentHeader';
import ComponentPropsTable from '../ComponentPropsTable';
import Tabs, { Tab } from '../../../common-components/Tabs';

const SelectUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const standardOptions: SelectOption[] = [
        {
            type: 'option',
            label: 'Banana',
            value: 'banana'
        },
        {
            type: 'option',
            label: 'Apple',
            value: 'apple',
            preIcon: <FontAwesomeIcon icon={faAppleWhole} />
        },
        {
            type: 'option',
            label: 'Blueberry',
            value: 'blueberry'
        },
        {
            type: 'option',
            label: 'Cherry',
            value: 'cherry'
        }
    ];

    const getStandardSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('standard');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Standard'),
            builder.getComponentSectionDescription((
                <p>
                    Standard dropdown with no special grouping.
                </p>
            )),
            builder.getExampleSection([
                <Select
                    title="Standard Select"
                    id="standard-select"
                    placeholderText="Choose a fruit"
                    selectOptions={standardOptions}
                />
            ]),
            builder.getCodeSection(standardCode),
            builder.getCollapsibleCodeSection(
                standardOptionsCode,
                'standardOptions Definition',
                <span><Code>standardOptions</Code> Definition</span>
            )
        ], true);
    };

    const groupOptions: SelectOption[] = [
        {
            type: 'group',
            label: 'Fruits',
            groupId: 'fruits-group',
            childOptions: [
                {
                    type: 'option',
                    label: 'Banana',
                    value: 'banana'
                },
                {
                    type: 'option',
                    label: 'Apple',
                    value: 'apple',
                    preIcon: <FontAwesomeIcon icon={faAppleWhole} />
                },
                {
                    type: 'option',
                    label: 'Blueberry',
                    value: 'blueberry'
                },
                {
                    type: 'option',
                    label: 'Cherry',
                    value: 'cherry'
                }
            ]
        },
        {
            type: 'group',
            label: 'Vegetables',
            groupId: 'vegetables-group',
            preIcon: <FontAwesomeIcon icon={faLeaf} />,
            postIcon: <FontAwesomeIcon icon={faCartShopping} />,
            childOptions: [
                {
                    type: 'option',
                    label: 'Cabbage',
                    value: 'cabbage'
                },
                {
                    type: 'option',
                    label: 'Carrot',
                    value: 'carrot',
                    preIcon: <FontAwesomeIcon icon={faCarrot} />
                }
            ]
        },
        {
            type: 'group',
            label: 'Bulk',
            groupId: 'bulk-group',
            preIcon: <FontAwesomeIcon icon={faBucket} />,
            childOptions: [
                {
                    type: 'option',
                    label: 'Oatmeal',
                    value: 'oatmeal'
                }
            ]
        },
        {
            type: 'option',
            label: 'Mustard',
            value: 'mustard'
        }
    ];

    const getGroupingSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('grouping');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Grouping'),
            builder.getComponentSectionDescription((
                <p>
                    Options can be grouped together or mixed-and-matched with options that are not
                    grouped. However, it is up to the implementer to organize these options in a
                    meaningful way.
                </p>
            )),
            builder.getExampleSection([
                <Select
                    title="Grouped Select"
                    id="grouped-select"
                    selectOptions={groupOptions}
                />
            ]),
            builder.getCodeSection(groupingCode),
            builder.getCollapsibleCodeSection(
                groupingOptionsCode,
                'groupOptions Definition',
                <span><Code>groupOptions</Code> Definition</span>
            )
        ]);
    };

    const popoverContainerOptions: SelectOption[] = [
        {
            type: 'option',
            label: 'Apple',
            value: 'apple'
        },
        {
            type: 'option',
            label: 'Pear',
            value: 'pear'
        },
        {
            type: 'option',
            label: 'Orange',
            value: 'orange'
        },
        {
            type: 'option',
            label: 'Grapefruit',
            value: 'grapefruit'
        },
        {
            type: 'option',
            label: 'Mandarin',
            value: 'mandarin'
        },
        {
            type: 'option',
            label: 'Lime',
            value: 'lime'
        },
        {
            type: 'option',
            label: 'Lemon',
            value: 'lemon'
        },
        {
            type: 'option',
            label: 'Nectarines',
            value: 'nectarines'
        },
        {
            type: 'option',
            label: 'Apricots',
            value: 'apricots'
        },
        {
            type: 'option',
            label: 'Peaches',
            value: 'peaches'
        },
        {
            type: 'option',
            label: 'Plums',
            value: 'plums'
        },
        {
            type: 'option',
            label: 'Banana',
            value: 'banana'
        },
        {
            type: 'option',
            label: 'Mango',
            value: 'mango'
        },
        {
            type: 'option',
            label: 'Pineapple',
            value: 'pineapple'
        },
        {
            type: 'option',
            label: 'Strawberry',
            value: 'strawberry'
        },
        {
            type: 'option',
            label: 'Raspberry',
            value: 'raspberry'
        },
        {
            type: 'option',
            label: 'Blueberry',
            value: 'blueberry'
        },
        {
            type: 'option',
            label: 'Kiwi',
            value: 'kiwi'
        },
        {
            type: 'option',
            label: 'Passionfruit',
            value: 'passionfruit'
        },
        {
            type: 'option',
            label: 'Watermelon',
            value: 'watermelon'
        },
        {
            type: 'option',
            label: 'Honeydew',
            value: 'honeydew'
        },
        {
            type: 'option',
            label: 'Cantaloupe',
            value: 'cantaloupe'
        },
        {
            type: 'option',
            label: 'Tomato',
            value: 'tomato'
        }
    ];

    const pageRef = useRef<HTMLDivElement>(null);

    const getPopoverContainerSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('popover-container');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Popover Container'),
            builder.getComponentSectionDescription((
                <p>
                    In some cases, the parent container for the Select may be too small and
                    have <Code lang="css">overflow: hidden</Code> causing the dropdown options to
                    be cut off. To alleviate this, a container for the dropdown options popover may
                    be provided: <Code>optionsContainerRef</Code>. Be sure to
                    set <Code lang="css">position: relative</Code> on the provided container.
                </p>
            )),
            builder.getExampleSection([
                <div className={styles['small-container']}>
                    <div className={styles['container-description']}>
                        <Code>optionsContainerRef</Code> not provided.
                    </div>
                    <Select
                        title="Small Container Select"
                        id="small-container-select-no-container"
                        selectOptions={popoverContainerOptions}
                        defaultValue="banana"
                    />
                </div>,
                <div className={styles['small-container']}>
                    <div className={styles['container-description']}>
                        <Code>optionsContainerRef</Code> is provided.
                    </div>
                    <Select
                        title="Small Container Select"
                        id="small-container-select-with-container"
                        selectOptions={popoverContainerOptions}
                        defaultValue="banana"
                        optionsContainerRef={pageRef}
                    />
                </div>
            ]),
            builder.getCodeSection(popoverContainerCode),
            builder.getCollapsibleCodeSection(
                popoverContainerOptionsCode,
                'optionsContainerRef Definition',
                <span><Code>optionsContainerRef</Code> Definition</span>
            )
        ]);
    };

    const getSizesSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('sizes');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Sizes'),
            builder.getComponentSectionDescription((
                <p>
                    There are a few size widths available for the Select. The default
                    is <Code>&apos;regular&apos;</Code>.
                </p>
            )),
            builder.getExampleSection([
                <Select
                    title="Regular Example"
                    id="regular-example"
                    placeholderText="Regular Example"
                    selectOptions={[]}
                />,
                <Select
                    title="Large Example"
                    id="large-example"
                    placeholderText="Large Example"
                    selectOptions={[]}
                    size="large"
                />,
                <Select
                    title="Full Example"
                    id="full-example"
                    placeholderText="Full Example"
                    selectOptions={[]}
                    size="full"
                />
            ]),
            builder.getCodeSection(sizesCode)
        ]);
    };

    return (
        <div ref={pageRef} style={{ position: 'relative' }}>
            <ContentHeader
                headerText="Select"
                breadcrumb="Components"
                description={selectHeaderDescription}
            />
            <div className="content-clamp">
                <Tabs>
                    <Tab name="Examples">
                        {getStandardSection()}
                        {getGroupingSection()}
                        {getPopoverContainerSection()}
                        {getSizesSection()}
                    </Tab>
                    <Tab name="Props">
                        <ComponentPropsTable componentProps={selectPropDetails} />
                    </Tab>
                    <Tab name="SelectOption">
                        <p>
                            Details about the <Code>SelectOption</Code> interface which can be
                            either
                            type <Code>OptionGroupData</Code>
                            &nbsp;or <Code>OptionData</Code>.
                        </p>

                        <section>
                            <h2>OptionGroupData</h2>
                            <ComponentPropsTable componentProps={optionGroupDataDetails} />
                        </section>

                        <section>
                            <h2>OptionGroup</h2>
                            <ComponentPropsTable componentProps={optionGroupDetails} />
                        </section>
                    </Tab>
                </Tabs>
            </div>
        </div>
    );
};
export default memo(SelectUsageExamples);

import {
    memo, ReactElement, useRef, useState
} from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import { focusableCode, plainTextCode } from './drawerDetails';
import Button from '../../../common-components/Button';
import Drawer from '../../../common-components/Drawer';
import Switch from '../../../common-components/Switch';
import styles from './DrawerUsageExamples.module.css';

const DrawerUsageExamples = (): ReactElement => {
    const [plainTextIsOpen, setPlainTextIsOpen] = useState(false);
    const painTextButtonRef = useRef<HTMLButtonElement>(null);

    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getPlainTextSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('plain-text');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Plain Text'),
            builder.getComponentSectionDescription((
                <p>
                    Example of the Drawer with only text and no focusable content. The open state is
                    stored in a parent component and passed down to the Drawer.
                </p>
            )),
            builder.getExampleSection([
                builder.getExampleTool([
                    <Button
                        onClick={() => setPlainTextIsOpen(true)}
                        buttonRef={painTextButtonRef}
                    >
                        Open Drawer
                    </Button>
                ]),
                <Drawer
                    isOpen={plainTextIsOpen}
                    closeButtonLabel="Close Example Drawer"
                    onClose={() => setPlainTextIsOpen(false)}
                    returnFocusableRef={painTextButtonRef}
                >
                    Example of the Drawer with only text and no focusable content. The open state is
                    stored in a parent component and passed down to the Drawer.
                </Drawer>
            ]),
            builder.getCodeSection(plainTextCode)
        ], true);
    };

    const [focusableIsOpen, setFocusableIsOpen] = useState(false);
    const focusableButtonRef = useRef<HTMLButtonElement>(null);
    const finalFocusableRef = useRef<HTMLButtonElement>(null);

    const getFocusableElementsSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('focusable-elements');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Focusable Elements'),
            builder.getComponentSectionDescription((
                <p>
                    Example of the Drawer which contains focusable elements. User&apos;s focus will
                    be trapped within the Drawer and its focusable elements until closed.
                </p>
            )),
            builder.getExampleSection([
                builder.getExampleTool([
                    <Button
                        onClick={() => setFocusableIsOpen(true)}
                        buttonRef={focusableButtonRef}
                    >
                        Open Drawer
                    </Button>
                ]),
                <Drawer
                    isOpen={focusableIsOpen}
                    closeButtonLabel="Close Example Drawer"
                    onClose={() => setFocusableIsOpen(false)}
                    finalFocusableRef={finalFocusableRef}
                    returnFocusableRef={focusableButtonRef}
                >
                    Example of the Drawer which contains focusable elements. User&apos;s focus will
                    be trapped within the Drawer and its focusable elements until closed.
                    <Button>Some Button</Button>
                    <div className={styles['example-switch-div']}>
                        <label
                            htmlFor="test-switch"
                            className={styles['example-switch-label']}
                        >
                            Some Switch
                        </label>
                        <Switch
                            switchTitle="Some Switch"
                            id="test-switch"
                        />
                    </div>
                    <Button buttonRef={finalFocusableRef}>Last Button</Button>
                </Drawer>
            ]),
            builder.getCodeSection(focusableCode)
        ]);
    };

    return (
        <>
            {getPlainTextSection()}
            {getFocusableElementsSection()}
        </>
    );
};
export default memo(DrawerUsageExamples);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, RefObject } from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import styles from './SiteBanner.module.css';
import packageJson from '../../package.json';

interface SiteBannerProps {
    onNavDrawerToggle: () => void;
    isNavDrawerOpen: boolean;
    menuButtonRef: RefObject<HTMLButtonElement>;
}

const SiteBanner = ({
    onNavDrawerToggle,
    isNavDrawerOpen,
    menuButtonRef
}: SiteBannerProps): ReactElement => (
    <div className={styles['site-banner']}>
        <button
            type="button"
            className={styles['nav-drawer-toggle']}
            onClick={onNavDrawerToggle}
            title="Open Side Menu"
            aria-label="Open Side Menu"
            tabIndex={isNavDrawerOpen ? -1 : undefined}
            ref={menuButtonRef}
        >
            <FontAwesomeIcon icon={faBars} />
            <div className={styles['nav-drawer-toggle-menu-text']}>Menu</div>
        </button>

        <Link to="/" className={styles.logo}>
            <img src="/mythril-logo975.png" alt="Mythril Logo" className={styles['logo-img']} />
            <div className={styles['logo-text']}>
                Mythril
                <span className={styles['logo-trim']}> Design System</span>
            </div>
        </Link>

        <a
            className={styles['releases-link']}
            href="https://gitlab.com/randerson8907-public/design-system-frontend/-/releases"
            target="_blank"
            rel="noreferrer"
            title="Go to Releases"
        >
            v{packageJson.version}
        </a>
    </div>
);

export default SiteBanner;

import { memo, ReactElement, ReactNode } from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import ContentHeader from '../../../common-components/ContentHeader';
import {
    commonHooksHeaderDescription, useClickOutsideCode,
    useCompareCode,
    useDeepCompareCode, useFirstRenderCode,
    useKeyDownCode,
    useMountEffectCode,
    usePreviousCode, useResizeCode
} from './commonHooksDetails';
import Code from '../../../common-components/Code';
import styles from '../ComponentInfoSectionBuilder.module.css';

const CommonHooksUsageExamples = (): ReactElement => {
    interface ParamDescription {
        name: string;
        type: string;
        description: ReactNode;
        isOptional?: boolean
    }

    const getParamsSection = (id: string, paramDescriptions: ParamDescription[]): ReactElement => (
        <div key={`${id}-param-area`}>
            <div className={styles['example-label']}>
                <strong>Parameters</strong>
            </div>
            <ul>
                {(paramDescriptions.length === 0) && (
                    <li>None</li>
                )}
                {(paramDescriptions.length > 0) && (
                    paramDescriptions.map((paramDescription) => (
                        <li key={`${id}-param-${paramDescription.name}`}>
                            <Code>
                                {`
                                    ${paramDescription.name}
                                    ${paramDescription.isOptional ? '?' : ''}
                                    : ${paramDescription.type}
                                `}
                            </Code>
                            &nbsp;-&nbsp;
                            {paramDescription.description}
                        </li>
                    ))
                )}
            </ul>
        </div>
    );

    interface ReturnDescription {
        type: string;
        description?: ReactNode;
    }

    const getReturnSection = (id: string, returnDescription: ReturnDescription): ReactElement => (
        <div key={`${id}-return-area`}>
            <div className={styles['example-label']}>
                <strong>Returns</strong>
            </div>
            <ul>
                <li>
                    <Code>{returnDescription.type}</Code>
                    {(returnDescription.type !== 'void') && (
                        <>
                            &nbsp;-&nbsp;
                            {returnDescription.description}
                        </>
                    )}
                </li>
            </ul>
        </div>
    );

    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getUseMountEffectSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-mount-effect');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useMountEffect', true),
            builder.getComponentSectionDescription((
                <p>
                    Shorthand for&nbsp;
                    <Code>
                        {'useEffect(() => { effectCallback() }, [])'}
                    </Code>.
                    This effect function is called only once on mount. Just like the original
                    effect, the <Code>effectCallback</Code> can return a cleanup function which will
                    be called on unmount of the component.
                </p>
            )),
            getParamsSection('use-mount-effect', [
                {
                    name: 'effectCallback',
                    type: 'EffectCallback',
                    description: 'Callback which runs on mount.'
                }
            ]),
            getReturnSection('use-mound-effect', {
                type: 'void'
            }),
            builder.getCodeSection(useMountEffectCode)
        ], true);
    };

    const getUseFirstRenderSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-first-render');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useFirstRender'),
            builder.getComponentSectionDescription((
                <p>
                    Determines whether this is the first render.
                </p>
            )),
            getParamsSection('use-first-render', []),
            getReturnSection('use-first-render', {
                type: 'boolean',
                description: <><Code>true</Code> if this is the first render.</>
            }),
            builder.getCodeSection(useFirstRenderCode)
        ]);
    };

    const getUsePreviousSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-previous');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('usePrevious'),
            builder.getComponentSectionDescription((
                <p>
                    Stores a ref of the value as it was in the previous render, updates that value
                    on change, and returns a ref of the previous value.
                </p>
            )),
            getParamsSection('use-previous', [
                {
                    name: 'value',
                    type: 'T',
                    description: 'Value to be stored (generic-typed).'
                }
            ]),
            getReturnSection('use-previous', {
                type: 'MutableRefObject<T | undefined>',
                description: 'Ref of the previous value.'
            }),
            builder.getCodeSection(usePreviousCode)
        ]);
    };

    const getUseCompareSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-compare');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useCompare'),
            builder.getComponentSectionDescription((
                <p>
                    Shallowly compares the value as it is in the current render to the value as it
                    was in the previous render. Utilizes the <Code>useFirstRender</Code> hook for
                    preventing the compare on the initial render and <Code>usePrevious</Code> hook
                    for actual comparing of values. Typically used for comparing primitives.
                </p>
            )),
            getParamsSection('use-compare', [
                {
                    name: 'value',
                    type: 'T',
                    description: 'The current value.'
                }
            ]),
            getReturnSection('use-compare', {
                type: 'boolean',
                description: (
                    <>
                        <Code>true</Code> if the current and previous values are
                        not equal and this is not the first render.
                    </>
                )
            }),
            builder.getCodeSection(useCompareCode)
        ]);
    };

    const getUseDeepCompareSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-deep-compare');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useDeepCompare'),
            builder.getComponentSectionDescription((
                <p>
                    Deeply compares the value as it is in the current render to the value as it was
                    in the previous render. Utilizes the <Code>useFirstRender</Code> hook for
                    preventing the compare on the initial render and <Code>usePrevious</Code> hook
                    for actual comparing of values. Typically used for comparing Objects.
                </p>
            )),
            getParamsSection('use-deep-compare', [
                {
                    name: 'value',
                    type: 'T',
                    description: 'The current value.'
                }
            ]),
            getReturnSection('use-deep-compare', {
                type: 'boolean',
                description: (
                    <>
                        <Code>true</Code> if the current and previous values are
                        equal.
                    </>
                )
            }),
            builder.getCodeSection(useDeepCompareCode)
        ]);
    };

    const getUseKeyDownSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-key-down');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useKeyDown'),
            builder.getComponentSectionDescription((
                <p>
                    Adds a keydown event listener.
                </p>
            )),
            getParamsSection('use-key-down', [
                {
                    name: 'callback',
                    type: '() => void',
                    description: 'Called when the event fires.'
                },
                {
                    name: 'keyCodes',
                    type: 'string[]',
                    description: 'List of key codes (e.g. "Escape") which will be listened for.'
                },
                {
                    name: 'isActive',
                    type: 'boolean',
                    description: (
                        <>
                            Whether or not the listener is active.
                            If <Code>false</Code>, the event listener will be removed.
                        </>
                    )
                },
                {
                    name: 'targetRef',
                    type: 'RefObject<HTMLElement>',
                    description: (
                        <>
                            <i>Optional</i>. Target which the event listener is attached to. If this
                            is not provided, then the event listener will be attached
                            to <Code>window</Code>.
                        </>
                    ),
                    isOptional: true
                }
            ]),
            getReturnSection('use-key-down', {
                type: 'void'
            }),
            builder.getCodeSection(useKeyDownCode)
        ]);
    };

    const getUseClickOutsideSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-click-outside');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useClickOutside'),
            builder.getComponentSectionDescription((
                <p>
                    Determines if a user clicks outside a given list of targets, then calls the
                    callback if so.
                </p>
            )),
            getParamsSection('use-click-outside', [
                {
                    name: 'callback',
                    type: '() => void',
                    description: 'Called when the user clicks outside.'
                },
                {
                    name: 'insideRefs',
                    type: 'RefObject<HTMLElement>[]',
                    description: `List of refs which are considered "inside" and will not trigger the
                        callback`
                },
                {
                    name: 'isActive',
                    type: 'boolean',
                    description: (
                        <>
                            Whether or not the listener is active.
                            If <Code>false</Code>, the event listener will be removed.
                        </>
                    )
                }
            ]),
            getReturnSection('use-click-outside', {
                type: 'void'
            }),
            builder.getCodeSection(useClickOutsideCode)
        ]);
    };

    const getUseResizeSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('use-resize');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('useResize'),
            builder.getComponentSectionDescription((
                <p>
                    Adds a listener to handle window resize.
                </p>
            )),
            getParamsSection('use-resize', [
                {
                    name: 'callback',
                    type: '() => void',
                    description: 'Called when the window is resized.'
                },
                {
                    name: 'isActive',
                    type: 'boolean',
                    description: (
                        <>
                            Whether or not the listener is active.
                            If <Code>false</Code>, the event listener will be removed.
                        </>
                    )
                }
            ]),
            getReturnSection('use-click-outside', {
                type: 'void'
            }),
            builder.getCodeSection(useResizeCode)
        ]);
    };

    return (
        <>
            <ContentHeader
                headerText="Common Hooks"
                breadcrumb="Components"
                description={commonHooksHeaderDescription}
            />
            <div className="content-clamp">
                {getUseMountEffectSection()}
                {getUseFirstRenderSection()}
                {getUsePreviousSection()}
                {getUseCompareSection()}
                {getUseDeepCompareSection()}
                {getUseKeyDownSection()}
                {getUseClickOutsideSection()}
                {getUseResizeSection()}
            </div>
        </>
    );
};
export default memo(CommonHooksUsageExamples);

import { memo, ReactElement } from 'react';
import styles from './OptionGroup.module.css';
import Option, { OptionData } from './Option';

export interface OptionGroupData {
    type: 'group';
    label: string;
    groupId: string;
    childOptions: OptionData[];
    preIcon?: ReactElement;
    postIcon?: ReactElement;
}

interface OptionGroupProps {
    optionGroup: OptionGroupData;
    groupIndex: number;
    focusedIndex: number;
    selectedOption: OptionData;
    isVisible: boolean;
    id: string;
    onClick: (option: OptionData) => void;
}

const OptionGroup = ({
    optionGroup,
    groupIndex,
    focusedIndex,
    selectedOption,
    isVisible,
    id,
    onClick
}: OptionGroupProps): ReactElement => {
    const getListItems = (): ReactElement[] => (
        optionGroup.childOptions.map((childOption, index) => (
            <Option
                option={childOption}
                isFocused={focusedIndex === (groupIndex + index)}
                isSelected={selectedOption.value === childOption.value}
                isVisible={isVisible}
                onClick={onClick}
                isInGroup
                key={`${id}-option-${childOption.value}`}
            />
        ))
    );

    return (
        <div className={styles['option-group']}>
            <div className={styles['option-group-label']}>
                {optionGroup.preIcon && (
                    <span className={styles['option-group-label-content']}>
                        {optionGroup.preIcon}
                    </span>
                )}
                <span
                    className={styles['option-group-label-content']}
                    id={optionGroup.groupId}
                >
                    {optionGroup.label}
                </span>
                {optionGroup.postIcon && (
                    <span className={styles['option-group-label-content']}>
                        {optionGroup.postIcon}
                    </span>
                )}
            </div>
            <div role="group" aria-labelledby={optionGroup.groupId}>
                {getListItems()}
            </div>
        </div>
    );
};

export default memo(OptionGroup);

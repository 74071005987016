import { Route, Routes } from 'react-router-dom';
import { ReactElement, useRef } from 'react';
import Introduction from './introduction/Introduction';
import FutureFeatures from './future-features/FutureFeatures';
import NoPage from '../NoPage';
import Colors from './foundations/colors/Colors';
import './Content.module.css';
import COMPONENT_ROUTES from './component-routes';

const Content = (): ReactElement => {
    const mainRef = useRef<HTMLElement>(null);

    return (
        <main ref={mainRef}>
            <Routes>
                <Route
                    path="/"
                    element={<Introduction />}
                />

                <Route
                    path="/foundations/colors"
                    element={<Colors />}
                />

                {COMPONENT_ROUTES}

                <Route
                    path="/future-features"
                    element={<FutureFeatures />}
                />

                <Route
                    path="*"
                    element={<NoPage />}
                />
            </Routes>
        </main>
    );
};

export default Content;

import { ReactElement, RefObject } from 'react';
import SIDE_MENU_OPTIONS from './sideMenuOptions';
import SideMenu from '../common-components/SideMenu';

interface NavDrawerProps {
    onDrawerToggle: () => void;
    isOpen: boolean;
    containerRef: RefObject<HTMLDivElement>;
    menuButtonRef: RefObject<HTMLButtonElement>;
}

const NavDrawer = ({
    onDrawerToggle,
    isOpen,
    containerRef,
    menuButtonRef
}: NavDrawerProps): ReactElement => (
    <SideMenu
        isOpen={isOpen}
        containerRef={containerRef}
        menuButtonRef={menuButtonRef}
        onClose={onDrawerToggle}
        menuOptions={SIDE_MENU_OPTIONS}
    />
);

export default NavDrawer;

import {
    ReactNode, useEffect, memo, RefObject, useCallback, useRef, ReactElement
} from 'react';

interface FocusGuardProps {
    children: ReactNode;
    isActive: boolean;
    returnFocusableRef: RefObject<HTMLElement>;
    firstFocusableRef: RefObject<HTMLElement>;
    finalFocusableRef: RefObject<HTMLElement>;
}

const FocusGuard = ({
    children,
    isActive,
    returnFocusableRef,
    firstFocusableRef,
    finalFocusableRef
}: FocusGuardProps): ReactElement => {
    const handleTopTab = useCallback((event: KeyboardEvent) => {
        // Tab to the last element
        if (finalFocusableRef.current
            && event.shiftKey
            && (event.key === 'Tab')) {
            finalFocusableRef.current.focus();
            event.preventDefault();
        }
    }, [finalFocusableRef]);

    // Handle first focusable changes
    useEffect(() => {
        const firstFocusable = firstFocusableRef.current;
        if (isActive) {
            firstFocusable?.addEventListener('keydown', handleTopTab);
        }

        // Cleanup so there aren't unnecessary listeners out there
        return () => { firstFocusable?.removeEventListener('keydown', handleTopTab); };
    }, [isActive, firstFocusableRef, handleTopTab]);

    const handleBottomTab = useCallback((event: KeyboardEvent) => {
        // Tab to the first element
        if (firstFocusableRef.current
            && !event.shiftKey
            && (event.key === 'Tab')) {
            firstFocusableRef.current.focus();
            event.preventDefault();
        }
    }, [firstFocusableRef]);

    // Handle final focusable changes
    useEffect(() => {
        const finalFocusable = finalFocusableRef.current;
        if (isActive) {
            finalFocusable?.addEventListener('keydown', handleBottomTab);
        }

        // Cleanup so there aren't unnecessary listeners out there
        return () => { finalFocusable?.removeEventListener('keydown', handleBottomTab); };
    }, [isActive, finalFocusableRef, handleBottomTab]);

    // Handle focus on activation/inactivation of the guard
    const prevIsActive = useRef<boolean|null>(null);
    useEffect(() => {
        if (isActive) {
            firstFocusableRef.current?.focus();
        }

        // Only focus the "return ref" when the guard was active and now it isn't
        if (prevIsActive.current && !isActive) {
            returnFocusableRef.current?.focus();
        }
        prevIsActive.current = isActive;
    }, [isActive, firstFocusableRef, returnFocusableRef]);

    return (<>{children} </>);
};

export default memo(FocusGuard);

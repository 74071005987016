import { memo, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import {
    darkVariantsCode, disabledCode, loadingCode, buttonWithIconCode, lightVariantsCode
} from './buttonDetails';
import Code from '../../../common-components/Code';
import Button from '../../../common-components/Button';

const ButtonUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const getDarkVariantsSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('dark-variants');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Dark Variants'),
            builder.getComponentSectionDescription((
                <p>
                    Color variants available for the <Code>&apos;dark&apos;</Code>
                    &nbsp;<Code>shade</Code>. <Code>&apos;secondary&apos;</Code>
                    &nbsp;and <Code>&apos;minimal&apos;</Code> variants do not have
                    different shades.
                </p>
            )),
            builder.getExampleSection([
                <Button>Primary</Button>,
                <Button variant="success">Success</Button>,
                <Button variant="info">Info</Button>,
                <Button variant="warning">Warning</Button>,
                <Button variant="danger">Danger</Button>,
                <Button variant="secondary">Secondary</Button>,
                <Button variant="minimal">Minimal</Button>
            ]),
            builder.getCodeSection(darkVariantsCode)
        ], true);
    };

    const getLightVariantsSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('light-variants');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Light Variants'),
            builder.getComponentSectionDescription((
                <p>
                    Color variants available for the <Code>&apos;light&apos;</Code>
                    &nbsp;<Code>shade</Code>. Same as before, <Code>&apos;secondary&apos;</Code>
                    &nbsp;and <Code>&apos;minimal&apos;</Code> variants do not have
                    different shades.
                </p>
            )),
            builder.getExampleSection([
                <Button shade="light">Primary</Button>,
                <Button variant="success" shade="light">Success</Button>,
                <Button variant="info" shade="light">Info</Button>,
                <Button variant="warning" shade="light">Warning</Button>,
                <Button variant="danger" shade="light">Danger</Button>,
                <Button variant="secondary" shade="light">Secondary</Button>,
                <Button variant="minimal" shade="light">Minimal</Button>
            ]),
            builder.getCodeSection(lightVariantsCode)
        ]);
    };

    const getDisabledSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('disabled');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Disabled'),
            builder.getComponentSectionDescription((
                <p>
                    Disabled Button which looks slightly different based on <Code>variant</Code>.
                </p>
            )),
            builder.getExampleSection([
                <Button isDisabled>Primary</Button>,
                <Button variant="success" shade="light" isDisabled>Success</Button>,
                <Button variant="info" isDisabled>Info</Button>,
                <Button variant="warning" shade="light" isDisabled>Warning</Button>,
                <Button variant="danger" isDisabled>Danger</Button>,
                <Button variant="secondary" shade="light" isDisabled>Secondary</Button>,
                <Button variant="minimal" isDisabled>Minimal</Button>
            ]),
            builder.getCodeSection(disabledCode)
        ]);
    };

    const getLoadingSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('loading');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('Loading'),
            builder.getComponentSectionDescription((
                <p>
                    Loading state of the Button which disables the Button and shows a loading
                    spinner over the text. The Button will remain in this state as long
                    as <Code>isLoading</Code> is <Code>true</Code>.
                </p>
            )),
            builder.getExampleSection([
                <Button isLoading>Primary</Button>,
                <Button variant="success" shade="light" isLoading>Success</Button>,
                <Button variant="warning" isLoading>Warning</Button>,
                <Button variant="danger" shade="light" isLoading>Danger</Button>,
                <Button variant="secondary" isLoading>Secondary</Button>,
                <Button variant="minimal" shade="light" isLoading>Minimal</Button>
            ]),
            builder.getCodeSection(loadingCode)
        ]);
    };

    const getButtonWithIconSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('jsx-contents');
        return builder.getComponentSection([
            builder.getComponentSectionHeader('JSX Contents'),
            builder.getComponentSectionDescription((
                <p>
                    Since the content of a Button is a <Code>ReactNode</Code>, it can
                    be a string or a JSX element that may contain icons.
                </p>
            )),
            builder.getExampleSection([
                <Button>
                    <FontAwesomeIcon icon={faComment} />
                    &nbsp;Comment
                </Button>,
                <Button variant="success" shade="light">
                    <FontAwesomeIcon icon={faThumbsUp} />
                </Button>,
                <Button variant="danger" shade="light">
                    <FontAwesomeIcon icon={faThumbsDown} />
                </Button>
            ]),
            builder.getCodeSection(buttonWithIconCode)
        ]);
    };

    return (
        <>
            {getDarkVariantsSection()}
            {getLightVariantsSection()}
            {getDisabledSection()}
            {getLoadingSection()}
            {getButtonWithIconSection()}
        </>
    );
};
export default memo(ButtonUsageExamples);

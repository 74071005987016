import { ReactElement, ReactNode } from 'react';
import ContentHeader from '../../common-components/ContentHeader';
import Tabs, { Tab } from '../../common-components/Tabs';
import ComponentPropsTable, { ComponentPropDetail } from './ComponentPropsTable';

interface ComponentInfoProps {
    headerText: string;
    headerDescription: ReactNode;
    usageExamples: ReactElement;
    propDetails: ComponentPropDetail[];
}

const ComponentInfo = ({
    headerText,
    headerDescription,
    usageExamples,
    propDetails
}: ComponentInfoProps): ReactElement => (
    <>
        <ContentHeader
            headerText={headerText}
            breadcrumb="Components"
            description={headerDescription}
        />
        <div className="content-clamp">
            <Tabs>
                <Tab name="Examples">
                    {usageExamples}
                </Tab>
                <Tab name="Props">
                    <ComponentPropsTable componentProps={propDetails} />
                </Tab>
            </Tabs>
        </div>
    </>
);
export default ComponentInfo;

import {
    ReactNode, useEffect, useRef, useState, memo, RefObject, useCallback, ReactElement
} from 'react';
import { Portal } from './Portal';
import { useCompare, useMountEffect } from './commonHooks';

interface DelayedPortalProps {
    children: ReactNode;
    isActive: boolean;
    containerRef?: RefObject<HTMLElement>;
    openDelay?: number;
    closeDelay?: number;
    onIsOnDom?: () => void;
    onNotIsOnDom?: () => void;
    onIsVisible?: () => void;
    onNotIsVisible?: () => void;
}

const DelayedPortal = ({
    children,
    isActive,
    containerRef,
    openDelay = 10,
    closeDelay = 10,
    onIsOnDom = () => undefined,
    onNotIsOnDom = () => undefined,
    onIsVisible = () => undefined,
    onNotIsVisible = () => undefined
}: DelayedPortalProps): ReactElement|null => {
    const [isOnDom, setIsOnDom] = useState(false);
    const isActiveChanged = useCompare(isActive);
    const timeoutRef = useRef<number|null>(null);

    const cleanupTimeout = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
    }, []);

    useEffect(() => {
        if (isActiveChanged) {
            cleanupTimeout();
            const timeoutDelay = isActive ? openDelay : closeDelay;

            // Start the transition by being on the dom, but not visible.
            // Then, after the delay, state will change to either being removed from the dom
            // or made visible depending on isActive status.
            setIsOnDom(true);
            onIsOnDom();
            onNotIsVisible();

            timeoutRef.current = window.setTimeout(() => {
                setIsOnDom(isActive);
                if (isActive) {
                    onIsOnDom();
                    onIsVisible();
                } else {
                    onNotIsOnDom();
                    onNotIsVisible();
                }
            }, timeoutDelay);
        }
    }, [
        cleanupTimeout,
        closeDelay,
        isActive,
        isActiveChanged,
        onIsOnDom,
        onNotIsOnDom,
        onIsVisible,
        onNotIsVisible,
        openDelay
    ]);

    // Cleanup the timeout on unmount
    useMountEffect(() => () => { cleanupTimeout(); });

    return isOnDom ? <Portal containerRef={containerRef}>{children}</Portal> : null;
};

export default memo(DelayedPortal);

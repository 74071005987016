import { Link } from 'react-router-dom';
import { ComponentPropDetail } from '../ComponentPropsTable';
import Code from '../../../common-components/Code';

export const drawerHeaderDescription = (
    <>
        Sliding left-side content area (or drawer). The Drawer appears
        at <Code lang="css">z-index</Code> 1000. It closes via the close button, escape key,
        or by clicking outside of the Drawer. Utilizes
        the <Link to="/components/focus-guard">Focus Guard</Link> component for holding user&apos;s
        focus within the Drawer when open, then returning user&apos;s focus to a specified element
        once closed.
    </>
);

export const drawerPropDetails: ComponentPropDetail[] = [
    {
        name: 'children',
        types: ['ReactNode'],
        isOptional: false,
        description: 'Content of the drawer.',
        defaultValue: 'N/A'
    },
    {
        name: 'isOpen',
        types: ['boolean'],
        isOptional: false,
        description: 'Whether the drawer is open.',
        defaultValue: 'N/A'
    },
    {
        name: 'closeButtonLabel',
        types: ['string'],
        isOptional: false,
        description: 'Label of the arrow close button.',
        defaultValue: 'N/A'
    },
    {
        name: 'returnFocusableRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: false,
        description: `When the drawer is closed, the element at his ref will be focused. For
        example, this would be set as the menu open button ref.`,
        defaultValue: 'N/A'
    },
    {
        name: 'finalFocusableRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: true,
        description: `Ref of the final item in the natural focusable order of items in the drawer's
        content. If not provided, the close drawer button will be used as both the first and final
        focusable item.`,
        defaultValue: 'N/A'
    },
    {
        name: 'containerRef',
        types: ['RefObject<HTMLElement>'],
        isOptional: true,
        description: (
            <>
                The container which holds the drawer. Since this component is within
                a <Link to="/components/delayed-portal">DelayedPortal</Link>, if this is not
                provided the content will be placed at the end
                of <Code lang="html">&lt;body&gt;</Code> (but it will still appear fixed on the
                page).
            </>
        ),
        defaultValue: 'N/A'
    },
    {
        name: 'onClose',
        types: ['() => void'],
        isOptional: true,
        description: 'Event called when the drawer closes.',
        defaultValue: '() => undefined'
    },
    {
        name: 'onIsOnDom',
        types: ['() => undefined'],
        isOptional: true,
        description: (
            <>
                Emits when content is placed on the DOM. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects. For more
                information on this event, see the &quot;Events&quot; details for
                the <Link to="/components/delayed-portal">DelayedPortal</Link>.
            </>
        ),
        defaultValue: '() => undefined'
    },
    {
        name: 'onNotIsOnDom',
        types: ['() => void'],
        isOptional: true,
        description: (
            <>
                Emits when content is taken off the DOM. This should be created
                using <Code>useCallback()</Code> to prevent unnecessary side effects. For more
                information on this event, see the &quot;Events&quot; details for
                the <Link to="/components/delayed-portal">DelayedPortal</Link>.
            </>
        ),
        defaultValue: '() => undefined'
    }
];

export const plainTextCode = `const [plainTextIsOpen, setPlainTextIsOpen] = useState(false);
const painTextButtonRef = useRef<HTMLButtonElement>(null);

<Button
    onClick={() => setPlainTextIsOpen(true)}
    buttonRef={painTextButtonRef}
>
    Open Drawer
</Button>

<Drawer
    isOpen={plainTextIsOpen}
    closeButtonLabel="Close Example Drawer"
    onClose={() => setPlainTextIsOpen(false)}
    returnFocusableRef={painTextButtonRef}
>
    Example of the Drawer with only text and no focusable content. The open state is
    stored in a parent component and passed down to the Drawer.
</Drawer>
`;

export const focusableCode = `const [focusableIsOpen, setFocusableIsOpen] = useState(false);
const focusableButtonRef = useRef<HTMLButtonElement>(null);
const finalFocusableRef = useRef<HTMLButtonElement>(null);

<Button
    onClick={() => setFocusableIsOpen(true)}
    buttonRef={focusableButtonRef}
>
    Open Drawer
</Button>

<Drawer
    isOpen={focusableIsOpen}
    closeButtonLabel="Close Example Drawer"
    onClose={() => setFocusableIsOpen(false)}
    finalFocusableRef={finalFocusableRef}
    returnFocusableRef={focusableButtonRef}
>
    Example of the Drawer which contains focusable elements. User&apos;s focus will
    be trapped within the Drawer and its focusable elements until closed.
    <Button>Some Button</Button>
    <div className={styles['example-switch-div']}>
        <label
            htmlFor="test-switch"
            className={styles['example-switch-label']}
        >
            Some Switch
        </label>
        <Switch
            switchTitle="Some Switch"
            id="test-switch"
        />
    </div>
    <Button buttonRef={finalFocusableRef}>Last Button</Button>
</Drawer>
`;

import {
    memo, ReactElement, ReactNode, RefObject
} from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    children: ReactNode;
    containerRef?: RefObject<HTMLElement>;
}

/**
 * Enhanced version of React's Portal which will place the content (children) at the desired
 * container ref. If no container ref is provided, then the content defaults being placed at the
 * end of <body>.
 *
 * @param props
 * <ul>
 *     <li><strong>containerRef</strong> - <i>Optional</i> Portal's target container.</li>
 *     <li><strong>children</strong> - Content of the portal (sent in as a JSX child).</li>
 * </ul>
 * @constructor
 */
export const Portal = ({ children, containerRef }: PortalProps): ReactElement => {
    if (containerRef?.current) {
        return createPortal(children, containerRef.current);
    }
    return createPortal(
        <div>{children}</div>,
        document.getElementsByTagName('body')[0]
    );
};

export default memo(Portal);

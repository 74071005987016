import {
    memo, ReactElement, useRef, useState
} from 'react';
import ComponentInfoSectionBuilder from '../ComponentInfoSectionBuilder';
import { exampleCode } from './focusGuardDetails';
import Button from '../../../common-components/Button';
import Switch from '../../../common-components/Switch';
import Collapsible from '../../../common-components/Collapsible';
import styles from './FocusGuardUsageExample.module.css';
import FocusGuard from '../../../common-components/FocusGuard';
import Code from '../../../common-components/Code';

const FocusGuardUsageExamples = (): ReactElement => {
    // --------------------------------------------------
    //    [BUILD EXAMPLE SECTIONS]
    // --------------------------------------------------

    const [isActive, setIsActive] = useState(false);
    const returnFocusableRef = useRef<HTMLButtonElement>(null);
    const firstFocusableRef = useRef<HTMLButtonElement>(null);
    const finalFocusableRef = useRef<HTMLButtonElement>(null);

    const getExampleSection = (): ReactElement => {
        const builder = ComponentInfoSectionBuilder('example');
        return builder.getComponentSection([
            builder.getComponentSectionDescription((
                <p>
                    Rough example of how the FocusGuard works. Activating the guard will trap
                    keyboard focus within the area when entered. When navigating via keyboard, focus
                    is shifted automatically to the <Code>firstFocusableRef</Code> on activation,
                    then back to the <Code>returnFocusableRef</Code> when deactivated.
                </p>
            )),
            builder.getExampleSection([
                builder.getExampleTool([
                    <Button
                        buttonRef={returnFocusableRef}
                        onClick={() => setIsActive(true)}
                    >
                        Activate Guard
                    </Button>
                ]),
                <FocusGuard
                    isActive={isActive}
                    returnFocusableRef={returnFocusableRef}
                    firstFocusableRef={firstFocusableRef}
                    finalFocusableRef={finalFocusableRef}
                >
                    <div className={styles['example-guard-area']}>
                        <h4 className={styles['guarded-area-header']}>Guarded Area</h4>
                        <div>Guard Active: {`${isActive}`}</div>
                        <Button variant="info" buttonRef={firstFocusableRef}>First Button</Button>
                        <Button variant="minimal">Second Button</Button>
                        <Collapsible
                            collapsibleId="some-collapsible"
                            title="Some Collapsible"
                            toggleLabel="Some Collapsible"
                        >
                            Content of a collapsible within a focus guard.
                        </Collapsible>
                        <Button
                            variant="danger"
                            onClick={() => setIsActive(false)}
                        >
                            Deactivate Guard
                        </Button>
                        <div className={styles['guarded-switch-area']}>
                            <label
                                htmlFor="a-switch"
                                className={styles['guarded-area-label']}
                            >
                                Final Switch
                            </label>
                            <Switch
                                switchTitle="A Switch"
                                id="a-switch"
                                toggleRef={finalFocusableRef}
                            />
                        </div>
                    </div>
                </FocusGuard>
            ]),
            builder.getCodeSection(exampleCode)
        ], true);
    };

    return (
        <>
            {getExampleSection()}
        </>
    );
};
export default memo(FocusGuardUsageExamples);

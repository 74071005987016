const convertHexToRgb = (hexValue: string): { r: number, g: number, b: number } => {
    // Remove the # so we can get at the actual digits
    const hexDigits = hexValue.substring(1);
    const r = parseInt(hexDigits.substring(0, 2), 16);
    const g = parseInt(hexDigits.substring(2, 4), 16);
    const b = parseInt(hexDigits.substring(4, 6), 16);
    return { r, g, b };
};

const convertToLinearColorChannel = (originalColorChannel: number): number => {
    // Convert 8-bit integer values for color channel to decimal 0.0 - 1.0
    const colorChannel = originalColorChannel / 255;
    // Convert gamma-encoded RGB value to a linear one
    if (colorChannel <= 0.04045) {
        return colorChannel / 12.92;
    }
    return ((colorChannel + 0.055) / 1.055) ** 2.4;
};

const calculateLuminance = (rgb: { r: number, g: number, b: number }): number => (
    0.2126 * convertToLinearColorChannel(rgb.r)
    + 0.7152 * convertToLinearColorChannel(rgb.g)
    + 0.0722 * convertToLinearColorChannel(rgb.b)
);

const calculateContrastRatio = (hex1: string, hex2: string): number => {
    const lum1 = calculateLuminance(convertHexToRgb(hex1));
    const lum2 = calculateLuminance(convertHexToRgb(hex2));
    const brightest = Math.max(lum1, lum2);
    const lightest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (lightest + 0.05);
};
export default calculateContrastRatio;

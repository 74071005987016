import { BrowserRouter } from 'react-router-dom';
import { ReactElement, useRef } from 'react';
import Header from './header/Header';
import Content from './content/Content';

const App = (): ReactElement => {
    const portalContainerRef = useRef<HTMLDivElement>(null);

    return (
        <BrowserRouter>
            <div id="portal-container" ref={portalContainerRef} />
            <Header navDrawerContainerRef={portalContainerRef} />
            <Content />
        </BrowserRouter>
    );
};

export default App;

import { ReactElement } from 'react';
import styles from './ColorList.module.css';
import { COLOR_DEFS, ColorDef } from './ColorDef';
import ColorCard from './ColorCard';

const ColorsList = (): ReactElement => {
    const getColorCard = (colorDef: ColorDef): ReactElement => (
        <ColorCard
            colorDef={colorDef}
            key={`${colorDef.id}-card`}
        />
    );

    const getColorSection = (section: string): ReactElement => {
        const colorDefs = COLOR_DEFS[section];
        return (
            <div className={styles['color-section']}>
                {colorDefs.map((colorDef: ColorDef) => (
                    getColorCard(colorDef)
                ))}
            </div>
        );
    };

    return (
        <>
            <section>
                <h2 className="margin-top-0">The Process</h2>
                <p>
                    When choosing a color palette, I first picked the primary brand color
                    of <strong>Indigo</strong>. This gave me a starting point for figuring out the
                    rest of the palette. Second, I picked good dark and light primary
                    colors <strong>Raisin Black</strong> and <strong>Cultured</strong> respectively.
                </p>
                <p>
                    I used the following set of tools to determine complementing colors, semantic
                    colors, shades/tints, and accessibility ratings:
                </p>
                <ul>
                    <li>
                        <a
                            href="https://color.adobe.com/create/color-wheel"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Adobe Color Wheel
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://learnui.design/tools/accessible-color-generator.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Accessible Color Generator
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://coolors.co/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Coolors
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://colors.eva.design/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Eva Design System
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.w3schools.com/colors/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            W3 Schools Color Tools
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://contrastchecker.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            WCAG Contrast Checker
                        </a>
                    </li>
                </ul>
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Primary Colors</h2>
                <p>
                    <strong>Indigo</strong> - used as accent for elements like the site
                    banner, buttons, etc.
                </p>
                <p>
                    <strong>Raisin Black</strong> - used for text color on lighter
                    backgrounds.
                </p>
                <p>
                    <strong>Cultured</strong> - used for page background and text color
                    on darker backgrounds.
                </p>

                {getColorSection('Primary')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Indigo</h2>
                <p>
                    These shades are used to show depth or shading when used along with
                    base Indigo.
                </p>

                {getColorSection('Indigo')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Green</h2>
                <p>
                    Green indicate success or a good outcome to the user.
                </p>

                {getColorSection('Green')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Blue</h2>
                <p>
                    Blue indicate important information or progress to the user.
                </p>

                {getColorSection('Blue')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Yellow</h2>
                <p>
                    Yellow indicate a warning to the user.
                </p>

                {getColorSection('Yellow')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Red</h2>
                <p>
                    Red indicate an error or danger to the user.
                </p>

                {getColorSection('Red')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Neutral</h2>
                <p>
                    Various neutral colors to be used for contrast, disabled states,
                    etc. Notes on a few of the colors:
                </p>
                <ul>
                    <li>
                        <strong>White</strong> - primarily used to show contrast for
                        content areas set on top of
                        the <strong>Cultured</strong>-colored page background.
                    </li>
                    <li>
                        <strong>Platinum</strong> - primarily used for background on
                        disabled elements.
                    </li>
                    <li>
                        <strong>Light Gray</strong> - primarily used for neutral hover
                        states.
                    </li>
                    <li>
                        <strong>Silver</strong> - primarily used for neutral borders.
                    </li>
                    <li>
                        <strong>Silver Chalice</strong> - primarily used for text color
                        of disabled elements.
                    </li>
                </ul>

                {getColorSection('Neutral')}
            </section>

            <hr />

            <section>
                <h2 className="margin-top-0">Gradients</h2>
                <p>
                    Gradients are allowed but color contrast must still be considered. Make sure to
                    compare the text color and every color stop of the gradient to ensure ensure at
                    least a AA rating.
                </p>
            </section>
        </>
    );
};
export default ColorsList;

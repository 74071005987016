import { memo, ReactElement } from 'react';
import ContentHeader from '../../../common-components/ContentHeader';
import Tabs, { Tab } from '../../../common-components/Tabs';
import ColorsList from './ColorsList';
import ColorContrastChecker from './ColorContrastChecker';

const Colors = (): ReactElement => {
    const headerDescription = (
        <>
            It can take some time to create a color palette for design. With the
            help of a designer friend of mine, I have painstakingly examined and selected the best
            colors for the <strong>Mythril Design System</strong>, for reference.
        </>
    );

    // TODO: aria-hidden and tab-index = -1 on items not shown in tabs
    return (
        <>
            <ContentHeader
                headerText="Colors"
                breadcrumb="Foundations"
                description={headerDescription}
            />

            <div className="content-clamp">
                <Tabs>
                    <Tab name="Colors" key="colors-tab">
                        <ColorsList />
                    </Tab>
                    <Tab name="Contrast Tool" key="contrast-tool-tab">
                        <ColorContrastChecker />
                    </Tab>
                </Tabs>

            </div>
        </>
    );
};

export default memo(Colors);

import { memo, ReactElement } from 'react';
import styles from './Overlay.module.css';

interface OverlayProps {
    isVisible: boolean;
}

const Overlay = ({ isVisible }: OverlayProps): ReactElement => (
    <div
        className={`${styles.overlay} ${isVisible ? styles['overlay-visible'] : ''}`}
    />
);

export default memo(Overlay);

import {
    memo, ReactElement, ReactNode, RefObject
} from 'react';
import styles from './Button.module.css';

interface ButtonProps {
    children: ReactNode;
    type?: 'submit'|'reset'|'button';
    variant?: 'primary'|'success'|'info'|'warning'|'danger'|'secondary'|'minimal';
    shade?: 'dark'|'light';
    isLoading?: boolean;
    isDisabled?: boolean;
    isHidden?: boolean;
    onClick?: () => void;
    buttonRef?: RefObject<HTMLButtonElement>
}

const Button = ({
    children,
    type = 'button',
    variant = 'primary',
    shade = 'dark',
    isLoading = false,
    onClick = () => undefined,
    isDisabled = false,
    isHidden = false,
    buttonRef = undefined
}: ButtonProps): ReactElement => {
    const getButtonClass = (): string => {
        let cssClass = styles['button-component'];

        let shadeCss = '';
        if (!(variant === 'secondary' || variant === 'minimal')) {
            shadeCss = shade === 'light' ? '-light' : '';
        }
        const variantClass = `${variant}-variant${shadeCss}`;
        cssClass += ` ${styles[variantClass]}`;

        if (isLoading) {
            cssClass += ` ${styles.loading}`;
        }

        return cssClass;
    };

    const getLoader = (): ReactElement|null => {
        if (isLoading) {
            return (
                <div className={styles['loader-container']}>
                    <div className={styles.loader} />
                </div>
            );
        }
        return null;
    };

    return (
        <button
            type={type}
            className={getButtonClass()}
            onClick={onClick}
            disabled={isDisabled || isLoading}
            title={isLoading ? 'Loading...' : undefined}
            tabIndex={isHidden ? -1 : undefined}
            ref={buttonRef}
        >
            <span className={isLoading ? styles['hidden-text'] : ''}>
                <strong>{children}</strong>
            </span>
            {getLoader()}
        </button>
    );
};
export default memo(Button);
